import axios, { AxiosResponse } from "axios";
import { FilterResponse } from "../models/filter";
import { JWT } from "../store/authorization/auth.types";

const API_URI = import.meta.env.VITE_APP_API_URI;

export const getFilters = async (jwt: JWT): Promise<FilterResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<FilterResponse> = await axios.post(`${API_URI}/filters/facets`, null, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${jwt.token}`,
      "Selected-Role": `${jwt.role?.at(0) ?? ""}`,
    },
  });

  return response.data;
};
