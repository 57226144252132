import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined } from "@mui/icons-material";
import { useSocket, usePostFeedback, ReadyState } from "../../../api/useSocket";
import { useFilterContext } from "../../../contexts/FilterProvider";
import emitSnackbar from "../../../emitSnackbar";
import { SearchFilters } from "../../../models/search";
import {
  ExecSummaryDisableButtonAtom,
  ExecSummaryEnableTooltipAtom,
  ExecSummaryHasContentAtom,
  ExecSummaryLoadingAtom,
  TriggerExecSummaryAtom,
} from "./state/ExecutiveSummaryState";
import { useVerbatims } from "../../../contexts/VerbatimsProvider";

interface SocketRequest {
  search_request: SearchFilters;
}

interface SocketResponseInitial {
  composite_user_id: string;
  action_id: string;
  search_object: SearchFilters;
}

interface SocketResponseAnswer {
  answer: string;
}

type SocketResponse = SocketResponseInitial | SocketResponseAnswer;
type Summary = Partial<SocketResponseInitial & SocketResponseAnswer & { feedback: 1 | -1 }>;
type Feedback = Summary;

const emptySummary: Partial<Summary> = {};

export default function ExecutiveSummary() {
  const [allowFeedback, setAllowFeedback] = useState(false);
  const [triggerExecSummary, setTriggerExecSummary] = useAtom(TriggerExecSummaryAtom);
  const [execSummaryLoading, setExecSummaryLoading] = useAtom(ExecSummaryLoadingAtom);
  const [execSummaryEnableTooltip, setExecSummaryEnableTooltip] = useAtom(ExecSummaryEnableTooltipAtom);
  const [execSummaryDisableButton, setExecSummaryDisableButton] = useAtom(ExecSummaryDisableButtonAtom);
  const [execSummaryHasContent, setExecSummaryHasContent] = useAtom(ExecSummaryHasContentAtom);

  const { numRecords } = useVerbatims();

  const { lastJsonMessage, sendJsonMessage, readyState } = useSocket<SocketRequest, SocketResponse>(
    "executive_summary",
  );
  const { postFeedback } = usePostFeedback<Feedback>();
  const { filterState } = useFilterContext();
  const [summary, setSummary] = useState(emptySummary);

  const requestExecutiveSummary = () => {
    if (!filterState) return;

    setExecSummaryLoading(true);
    sendJsonMessage({ search_request: filterState });
  };

  useEffect(() => {
    if (triggerExecSummary > 0) {
      requestExecutiveSummary();
      setTriggerExecSummary(0);
    }
  }, [triggerExecSummary]);

  useEffect(() => {
    if (["chat.answer", "chat.initial"].includes(lastJsonMessage?.type)) {
      setSummary({
        ...summary,
        ...lastJsonMessage?.data,
      });
    } else if (lastJsonMessage?.type === "chat.complete") {
      setExecSummaryLoading(false);
      setAllowFeedback(true);
    } else if ((lastJsonMessage as unknown as { message: string })?.message === "Internal server error") {
      emitSnackbar("Something went wrong, please try again in a moment", "warning");
      setExecSummaryLoading(false);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (Boolean(summary.answer) && !execSummaryHasContent) {
      setExecSummaryHasContent(true);
    }
  }, [summary]);

  useEffect(() => {
    setSummary(emptySummary);
    setExecSummaryHasContent(false);
    setAllowFeedback(false);
  }, [filterState]);

  useEffect(() => {
    const currExecSummaryEnableTooltip =
      !numRecords || numRecords < 1 || numRecords > 500 || ReadyState[readyState] !== "OPEN";
    if (execSummaryEnableTooltip != currExecSummaryEnableTooltip) {
      setExecSummaryEnableTooltip(currExecSummaryEnableTooltip);
    }

    const currExecSummaryDisableButton = execSummaryEnableTooltip || execSummaryLoading;
    if (execSummaryDisableButton != currExecSummaryDisableButton) {
      setExecSummaryDisableButton(currExecSummaryDisableButton);
    }
  }, [readyState, numRecords, execSummaryEnableTooltip, execSummaryLoading]);

  return (
    <>
      {summary.answer && (
        <Box display="flex" paddingX="2rem" paddingY="1rem" gap="1rem" flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize="20px" color="#13294B">
              Summary
            </Typography>
            {allowFeedback && (
              <Box display="flex" gap="0.5rem">
                <IconButton
                  onClick={() => {
                    const summaryWithFeedback: Summary = { ...summary, feedback: 1 };
                    postFeedback(summaryWithFeedback);
                    setSummary(summaryWithFeedback);
                  }}
                  size="small"
                >
                  {summary.feedback === 1 ? <ThumbUp fontSize="inherit" /> : <ThumbUpOutlined fontSize="inherit" />}
                </IconButton>
                <IconButton
                  onClick={() => {
                    const summaryWithFeedback: Summary = { ...summary, feedback: -1 };
                    postFeedback(summaryWithFeedback);
                    setSummary(summaryWithFeedback);
                  }}
                  size="small"
                >
                  {summary.feedback === -1 ? (
                    <ThumbDown fontSize="inherit" />
                  ) : (
                    <ThumbDownOutlined fontSize="inherit" />
                  )}
                </IconButton>
              </Box>
            )}
          </Box>
          <Box color="#13294B">{formatResponseText(summary.answer)}</Box>
        </Box>
      )}
    </>
  );
}

// This is a copy of the same function that is being used for Deepchat so that
// solution specific formatting needs don't get entangled between the two implementations.
// If we find that both have the same formatting needs we can extract these back out to a single function again.
export const formatResponseText = (text: string) => {
  const response = (text || "").split("\n").flatMap((segment, i) => {
    const isListItem = segment.startsWith("- ");
    const isBlankLine = !segment;

    if (isListItem) {
      // Clean off the "- " and make element a <li>
      return <li key={i}>{segment.replace(/^(- )/, "")}</li>;
    }
    return (
      <Typography py={isBlankLine ? "0.25em" : 0} key={i}>
        {segment}
      </Typography>
    );
  });

  return response;
};
