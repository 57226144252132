import { Button } from "@mui/material";
import { useFilterContext } from "../../contexts/FilterProvider";

type ClearFiltersButtonProps = {
  height: number;
};

const ClearFiltersButton = ({ height }: ClearFiltersButtonProps): JSX.Element => {
  const { resetFilters } = useFilterContext();

  return (
    <Button variant="text" fullWidth sx={{ height: height }} onClick={resetFilters}>
      Reset Filters
    </Button>
  );
};

export default ClearFiltersButton;
