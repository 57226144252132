import { useState } from "react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../theme";
import { TopBar } from "../components";
import AppContextWrapper from "../contexts/AppContextWrapper";
import { Outlet, useOutletContext } from "react-router-dom";

export const TOP_BAR_HEIGHT = 50;
type ContextType = {
  hideFilterDrawer: boolean | undefined;
  setHideFilterDrawer: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

export default function AppLayout() {
  // `hideFilterDrawer === undefined` communicates that the app is in a state
  // where the filter drawer is not hideable
  const [hideFilterDrawer, setHideFilterDrawer] = useState<boolean | undefined>(undefined);

  return (
    <>
      <CssBaseline />
      <AppContextWrapper>
        <ThemeProvider theme={theme}>
          <Box display="flex">
            <CssBaseline />
            <TopBar hideFilterDrawer={hideFilterDrawer} setHideFilterDrawer={setHideFilterDrawer} />
            <Outlet context={{ hideFilterDrawer, setHideFilterDrawer } satisfies ContextType} />
          </Box>
        </ThemeProvider>
      </AppContextWrapper>
    </>
  );
}

// TODO: I think we can push this down into an Atom and not have to expose it here in the AppLayout.tsx - Eric
export function useHideFilterDrawer() {
  return useOutletContext<ContextType>();
}
