import { useRef, useState } from "react";
import { Paper, PaperProps } from "@mui/material";
import { ChartMenu, FullScreen, DownloadButton } from "../../components/common";
import { downloadAsPng } from "../../utils/element-export";

import ChartCard from "../../components/ChartCard";
import SentimentOverTime from "../../components/charts/SentimentOverTime/SentimentOverTime";

const TooltipContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    Sentiment Over Time represents the change in volume of each sentiment (Positive, Negative, Neutral) over the date
    range selected based on your search and filter parameters.
  </Paper>
);

export default function SentimentOverTimeCard(props: PaperProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "sentiment-over-time");
    }
  };

  return (
    <ChartCard
      {...props}
      title={`Sentiment Over Time`}
      tooltipContent={<TooltipContent />}
      sx={{ height: "100%", display: "flex", flexDirection: "column", ...props.sx }}
      actions={
        <>
          <ChartMenu onDownload={triggerDownload} onFullScreen={() => setExpanded(true)} />
        </>
      }
    >
      <FullScreen
        title="Sentiment Over Time"
        expanded={expanded}
        handleClose={() => setExpanded(false)}
        p={"1em"}
        actions={<DownloadButton action={triggerDownload} />}
        sx={{ height: "100%" }}
      >
        <SentimentOverTime ref={ref} isFullScreen={expanded} />
      </FullScreen>
    </ChartCard>
  );
}
