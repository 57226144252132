import { isTokenExpired } from "../../utils/auth/isTokenExpired";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setJwt } from "../../store/authorization/auth.action";
import { selectAuth } from "../../store/authorization/auth.selector";
import { JWT } from "../../store/authorization/auth.types";
import Roles from "./Roles";

type AuthenticationProps = {
  children: React.ReactNode;
};

const Authentication = ({ children }: AuthenticationProps) => {
  const loc = useLocation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);

  const pingUrl = import.meta.env.VITE_APP_PING_URL;

  const routeToTargetHomepage = (user: JWT) => {
    const isUSLT = user.role?.includes(Roles.USLT_RETRO);
    if (isUSLT) {
      nav("/dashboards/retros");
    } else {
      nav("/deepchat");
    }
  };

  if (!pingUrl) {
    throw new Error("Auth ping URL env variable has not been set.");
  }

  useEffect(() => {
    if (isTokenExpired(user.token)) {
      dispatch(
        setJwt({
          token: null,
          role: null,
          app: null,
          isLoading: true,
        }),
      );

      if (!user.isLoading && !loc.state) {
        window.location.replace(pingUrl);
      }
    } else if (loc.pathname.includes("/auth")) {
      routeToTargetHomepage(user);
    }
  }, [dispatch, pingUrl, loc.pathname, loc.state, nav, user.isLoading, user.token]);

  // catch the case where user is not authorized to view the application
  if (loc.pathname.includes("auth") && loc.search.includes("error")) {
    throw new Error("You are not authorized to view this application.");
  }

  if (
    (!user.isLoading && !isTokenExpired(user.token)) ||
    ["auth", "error", "logout"].some((path) => loc.pathname.includes(path))
  ) {
    // Route the user to their target homepage based on their role if they hit the app root "/"
    if (loc.pathname === "/") {
      routeToTargetHomepage(user);
    }

    return children;
  } else if (isTokenExpired(user.token)) {
    window.location.replace(pingUrl);
    return <></>;
  }

  return children;
};

export default Authentication;

export const logout = () => {
  const endSessionUrl = import.meta.env.VITE_APP_PING_END_SESSION_URL;

  if (!endSessionUrl) {
    throw new Error("End session ping url env variable has not been set.");
  }

  window.location.replace(endSessionUrl);
};

window.addEventListener("storage", ({ key, newValue }) => {
  if (key === "persist:root") {
    const updatedState = JSON.parse(newValue ?? "");
    const auth = JSON.parse(updatedState.auth);
    const { token } = auth.jwt;

    if (!token) {
      logout();
    }
  }
});
