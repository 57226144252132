// component to render a tooltip if text overflows a given container.
// tooltip is not rendered if the text is not overflown
import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";

type OverflownTextTooltipProps = {
  text: string;
};

const OverflownTextTooltip = ({ text }: OverflownTextTooltipProps): JSX.Element => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    // we only want to check this once -- if we don't, the button will disappear when the
    // panel is expanded
    if (textRef.current) {
      setIsOverflown(textRef.current.clientWidth !== textRef.current.scrollWidth);
    }
  }, [textRef]);

  return (
    <Tooltip title={isOverflown ? text : ""} enterTouchDelay={250}>
      <div ref={textRef} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", lineHeight: 2 }}>
        {text}
      </div>
    </Tooltip>
  );
};

export default OverflownTextTooltip;
