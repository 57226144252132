import { useAtom } from "jotai";
import { DefaultQuestionEnhancerState, QuestionEnhancerAtom } from "../../components/Deepchat/state/DeepchatState";
import useDeepchat from "../../contexts/useDeepchat";

export const useQuestionEnhancer = () => {
  const [QE, setQE] = useAtom(QuestionEnhancerAtom);
  const { getEnhanceQuestion } = useDeepchat();

  const enhanceQuestion = async (question: string) => {
    openEnhancerPanel();

    if (QE.starting_query != question) {
      setQE((_) => ({ ..._, loading: true }));
      const enhanced = await getEnhanceQuestion(question);
      setQE((_) => ({ ..._, loading: false }));
      setEnhancerOptions(question, enhanced.enhanced_queries ?? []);
    }
  };

  const resetEnhancerState = () => {
    setQE(DefaultQuestionEnhancerState);
  };

  const openEnhancerPanel = () => {
    setQE((_qe) => ({ ..._qe, open: true }));
  };

  const closeEnhancerPanel = () => {
    setQE((_qe) => ({ ..._qe, open: false }));
  };

  const setEnhancerOptions = (starting_query: string, options: string[]) => {
    setQE((_qe) => ({ ..._qe, starting_query, options: options }));
  };

  const setSelectedOption = (option_index: number) => {
    // This isn't meant to be air tight as much as just being able to determine if a user selected an enhanced option before submitting their question
    setQE((_qe) => ({ ..._qe, selected_option: option_index }));
  };

  const isEnhancedQuestionSelected = () => {
    return QE.starting_query !== undefined;
  };

  return {
    QE,
    enhanceQuestion,
    resetEnhancerState,
    openEnhancerPanel,
    closeEnhancerPanel,
    setSelectedOption,
    isEnhancedQuestionSelected,
  };
};
