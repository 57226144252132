import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: "bold",
      border: "1px solid #dadde9",
      padding: "6px 8px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        backgroundColor: "white",
        border: "1px solid #dadde9",
      },
    },
  };
});

export default StyledToolTip;
