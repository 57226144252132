import { useRef, useState } from "react";
import {
  Paper,
  PaperProps,
  Typography as T,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  styled,
  Box,
} from "@mui/material";
import { downloadAsPng } from "../../utils/element-export";
import { ChartCard } from "../../components";
import { EmergingTopicsChart } from "../../components/charts/EmergingTopicsChart";
import { EmergingTopicsChartMode } from "../../components/charts/EmergingTopicsChart/EmergingTopicsChart";
import { ChartMenu, FullScreen, DownloadButton } from "../../components/common";
import StyledButton from "../../components/common/StyledButton";

const rows = [
  { label: "3 years or greater", value: "Yearly" },
  { label: "2 Years", value: "Six-Month" },
  { label: "16 Months", value: "Quarterly" },
  { label: "1 Year", value: "Two-Month" },
  { label: "3 Months", value: "Monthly" },
  { label: "2 Months", value: "Two-Week" },
  { label: "1 Month", value: "Weekly" },
  { label: "3 Weeks", value: "Two-Day" },
  { label: "Less than 3 Weeks", value: "Daily" },
];

const StyledBorderBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
}));

const CELL_WIDTH = 250;
const HEADER_STYLE = {
  fontWeight: 700,
  color: "rgba(0, 0, 0, 0.4)",
};

const StyledBorderLeftCell = styled(TableCell)(({ theme }) => ({
  width: CELL_WIDTH,
  borderRight: `1px solid ${theme.palette.grey[300]}`,
}));

const StyledBorderRightCell = styled(TableCell)(() => ({
  width: CELL_WIDTH,
}));

const EmergingTopicsContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    <T fontWeight={900}>Emerging Topics</T>
    The emerging topics widget shows which topics have most increase or decrease in document count over the
    currently-selected date range. The topics with the fastest change are identified by the widget and shown with their
    corresponding rate of change (as percent growth).
    <br />
    <br />
    <TableContainer sx={{ borderRadius: `10px` }} component={StyledBorderBox}>
      <Table size="small" sx={{ fontSize: 16, borderRadius: "10px, 0 0 10px" }}>
        <TableHead>
          <TableRow>
            <StyledBorderLeftCell sx={{ ...HEADER_STYLE }}>Selected Date Interval</StyledBorderLeftCell>
            <StyledBorderRightCell sx={{ ...HEADER_STYLE }}>X-Axis Interval</StyledBorderRightCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ label, value }, i) => {
            return (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td": {
                    borderBottom: 0,
                  },
                }}
              >
                <StyledBorderLeftCell>{label}</StyledBorderLeftCell>
                <StyledBorderRightCell>{value}</StyledBorderRightCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

const DownloadToolTipContent = () => (
  <Paper sx={{ p: 2 }}>Note: View the chart in full screen mode to include the legend with the export.</Paper>
);

const EmergingTopicsCard = (props: PaperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [mode, setMode] = useState<EmergingTopicsChartMode>("chips");

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "emerging_topics");
    }
  };

  return (
    <ChartCard
      {...props}
      title={`Emerging Topics`}
      tooltipContent={<EmergingTopicsContent />}
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      actions={
        <>
          <StyledButton
            onClick={() => {
              setMode((_mode) => (_mode == "graph" ? "chips" : "graph"));
            }}
          >
            Show {mode == "graph" ? "Insights" : "Graph"}
          </StyledButton>
          <ChartMenu
            onDownload={triggerDownload}
            onFullScreen={() => setExpanded(true)}
            downloadToolTip={mode == "graph" ? <DownloadToolTipContent /> : undefined}
          />
        </>
      }
    >
      <FullScreen
        title="Emerging Topics"
        expanded={expanded}
        handleClose={() => setExpanded(false)}
        actions={<DownloadButton action={triggerDownload} />}
        sx={{ padding: "1em", height: "100%" }}
      >
        <EmergingTopicsChart mode={mode} isFullScreen={expanded} ref={ref} />
      </FullScreen>
    </ChartCard>
  );
};

export default EmergingTopicsCard;
