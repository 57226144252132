import axios from "axios";
import { FeedbackSubmission, VerbatimFeedback } from "../components/cards/VerbatimsCard/FeedbackState";
import { JWT } from "../store/authorization/auth.types";

const API_URI = import.meta.env.VITE_APP_API_URI;

const buildHeaders = (jwt: JWT) => {
  return {
    "Content-Type": "application/json",
    Authorization: `${jwt.token}`,
    "Selected-Role": `${jwt.role?.at(0) ?? ""}`,
  };
};

export const getAllUserFeedback = async (jwt: JWT): Promise<VerbatimFeedback[]> => {
  const response = await axios.get<VerbatimFeedback[]>(`${API_URI}/feedback`, {
    headers: buildHeaders(jwt),
  });

  return response.data;
};

export const getUserFeedback = async (documentIds: string[], jwt: JWT): Promise<VerbatimFeedback[]> => {
  const response = await axios.post<VerbatimFeedback[]>(`${API_URI}/feedback/find`, documentIds, {
    headers: buildHeaders(jwt),
  });

  return response.data;
};

export const addUserFeedback = async (payload: FeedbackSubmission<unknown>, jwt: JWT): Promise<VerbatimFeedback> => {
  const response = await axios.post<VerbatimFeedback>(`${API_URI}/feedback`, payload, {
    headers: buildHeaders(jwt),
  });

  return response.data;
};
