import React, { forwardRef, useState, useEffect, useRef, MouseEvent } from "react";
import { AutoSizer } from "react-virtualized";
import { Line, Datum, SliceTooltipProps, Point, PointMouseHandler } from "@nivo/line";
import { Box, Portal, Paper, Typography as T } from "@mui/material";
import { LegendProps } from "@nivo/legends";
import { ExpandableTab } from "./Common";
import ChartLegend from "./common/ChartLegend";

interface LineSpec {
  id: string;
  color: string;
  data: Datum[];
}

interface LineChartProps {
  data: DataProp[];
  isFullScreen: boolean;
  selectedTopic?: string;
  onSelectTopic?: (topic: string) => void;
}

interface DataProp {
  epoch: number;
  [key: string]: number;
}

type Slice = SliceTooltipProps["slice"];

export const DownloadToolTipContent = () => (
  <Paper sx={{ p: 2 }}>Note: View the chart in full screen mode to include the legend with the export.</Paper>
);

export const formatIds = (data: DataProp[], labelTable: { [key: string]: string } & { epoch?: never }) =>
  data.map((item: DataProp) =>
    Object.fromEntries(Object.entries(item).map(([k, v]) => [labelTable[k] ?? k, v])),
  ) as DataProp[];

const GRAY = "#EFF0F0";
const Y_SCALE_UPPER_PADDING = 19 / 16;
const Y_SCALE_UPPER_PADDING_NO_LEGEND = 17 / 16;
const CHART_MARGIN_X = 48;
const CHART_MARGIN_BOTTOM = 42;
const CHART_MARGIN_TOP = 20;
const TIME_PADDING_LEFT = 8;

const colors: string[] = [
  "hsl(219, 100%, 26%)",
  "hsl(227, 96%, 78%)",
  "hsl(196, 100%, 44%)",
  "hsl(27, 85%, 57%)",
  "hsl(356, 50%, 50%)",
  "hsl(201, 66%, 73%)",
  "hsl(228, 100%, 65%)",
  "hsl(96, 36%, 49%)",
  "hsl(350, 49%, 85%)",
  "hsl(75, 42%, 70%)",
];

const trimData = (data: Datum[]) => {
  let start = 0,
    end = data.length - 1;

  while (start < end && data[start].y === 0) {
    start += 1;
  }
  while (end > start && data[end].y === 0) {
    end -= 1;
  }

  return data.slice(start, end + 1);
};

const LineChart = forwardRef(
  ({ data: rawData, isFullScreen, selectedTopic, onSelectTopic: handleSelectTopic }: LineChartProps, ref) => {
    const [tooltipSlice, setTooltipSlice] = useState<{ event: MouseEvent; point: Point }>();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const keySet = new Set(rawData.map((item) => Object.keys(item)).flat());
    keySet.delete("epoch");
    const handleMouseMove: PointMouseHandler = (point, event) => setTooltipSlice({ point, event });
    const data = rawData
      .reduce(
        (a, c) => {
          a.forEach(({ data, id }: LineSpec) => c[id] && data.push({ x: c.epoch, y: c[id] }));
          return a;
        },
        [...keySet].map((id, i) => ({
          id,
          data: [],
          color: colors[i % colors.length],
        })),
      )
      .map((item) => ({ ...item, data: trimData(item.data) }))
      // Nivo gets funny with fewer than two points
      .filter((item) => item.data.length > 1);

    const lineData = selectedTopic ? data.filter((item) => item.id === selectedTopic) : data;

    const minTime = new Date(Math.min(...rawData.map(({ epoch }) => epoch)) * 1000);
    const maxTime = new Date(Math.max(...rawData.map(({ epoch }) => epoch)) * 1000);
    const maxVal = Math.max(...lineData.map(({ data }) => data.map(({ y }) => y as number)).flat());
    const timeRange = maxTime.getTime() - minTime.getTime();

    const everyJuly: Date[] = [];

    for (let year = minTime.getFullYear(); year <= maxTime.getFullYear(); year++) {
      const julyStart = new Date(year, 6, 1);
      const julyEnd = new Date(year, 6, 31);

      if (!(maxTime < julyStart || minTime > julyEnd)) {
        everyJuly.push(new Date(year, 6));
      }
    }

    const numYears = maxTime.getFullYear() - minTime.getFullYear();
    const numMonths = numYears * 12 - minTime.getMonth() + maxTime.getMonth() + 1;

    const isBigLegend = lineData.length > 4;

    let legendX = 22;
    const legends: LegendProps[] = [];

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (ctx && !isBigLegend) {
      ctx.font = "normal 11px sans-serif";
      [...lineData].reverse().forEach((item) => {
        legendX -= ctx.measureText(item.id).width + 40;
        legends.push({
          translateX: legendX,
          translateY: 4,
          data: [{ ...item, label: item.id }],
          anchor: "top-right",
          direction: "row",
          itemWidth: 0,
          itemHeight: 24,
          symbolShape: "circle",
          symbolSize: 8,
        });
      });
    }

    useEffect(() => {
      if (selectedTopic) {
        setDrawerOpen(false);
      }
    }, [selectedTopic]);

    return (
      <>
        <Box
          sx={{
            marginLeft: "auto",
            position: "relative",
            width: "100%",
            zIndex: 999,
          }}
        >
          {!isFullScreen && (
            <ExpandableTab tabExpanded={drawerOpen} hasSelection={!!selectedTopic} toggleExpanded={setDrawerOpen}>
              <ChartLegend
                selectedId={selectedTopic}
                data={data}
                handleChipClick={handleSelectTopic}
                isFullScreen={false}
              />
            </ExpandableTab>
          )}
        </Box>
        <Box ref={ref} height="100%">
          <Box
            sx={{
              ...(isFullScreen
                ? { aspectRatio: 3.5 }
                : {
                    height: "100%",
                  }),
            }}
          >
            <AutoSizer>
              {({ width, height }) => {
                if (width === 0) {
                  return;
                }
                const millisecondsPerPixel = timeRange / (width - CHART_MARGIN_X * 2);
                return lineData.length > 0 ? (
                  <Line
                    animate={false}
                    width={width}
                    height={height}
                    margin={{
                      left: CHART_MARGIN_X,
                      bottom: CHART_MARGIN_BOTTOM,
                      right: CHART_MARGIN_X,
                      top: CHART_MARGIN_TOP,
                    }}
                    data={lineData}
                    xFormat="time:%s"
                    xScale={{
                      type: "time",
                      format: "%s",
                      min: new Date(minTime.getTime() - TIME_PADDING_LEFT * millisecondsPerPixel),
                      useUTC: false,
                    }}
                    yScale={{
                      type: "linear",
                      max: maxVal * (isBigLegend ? Y_SCALE_UPPER_PADDING_NO_LEGEND : Y_SCALE_UPPER_PADDING),
                    }}
                    axisTop={{
                      tickSize: 0,
                      tickPadding: 0 - height + 24,
                      format: "%Y",
                      tickValues: everyJuly.length > 0 ? everyJuly : 0,
                    }}
                    axisBottom={{
                      format: `%b${everyJuly.length > 0 ? "" : " ’%y"}`,
                      tickValues: Math.min(numMonths, 9),
                    }}
                    pointSize={4.5}
                    lineWidth={1.25}
                    enableSlices="x"
                    colors={{ datum: "color" }}
                    enableGridX={false}
                    enableGridY={false}
                    theme={{
                      axis: {
                        domain: { line: { strokeWidth: 1, stroke: GRAY } },
                        ticks: { line: { stroke: GRAY } },
                      },
                    }}
                    onMouseEnter={() => setTooltipVisible(true)}
                    onMouseLeave={() => setTooltipVisible(false)}
                    onMouseMove={handleMouseMove}
                    sliceTooltip={() => null}
                  />
                ) : (
                  <T
                    sx={{
                      height,
                      width,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "2rem",
                      opacity: "0.4",
                    }}
                  >
                    No records to show.
                  </T>
                );
              }}
            </AutoSizer>
          </Box>
          {isFullScreen && (
            <ChartLegend
              selectedId={selectedTopic}
              data={data}
              handleChipClick={handleSelectTopic}
              isFullScreen={true}
            />
          )}
        </Box>
        <SliceTooltip
          point={tooltipSlice?.point as unknown as Slice}
          event={tooltipSlice?.event}
          visible={tooltipVisible}
        />
      </>
    );
  },
);

export default LineChart;

const SliceTooltip = ({ point, visible, event }: { point?: Slice; visible: boolean; event?: MouseEvent }) => {
  const [width, setWidth] = useState(0);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (divRef.current?.clientWidth) {
      setWidth(divRef.current?.clientWidth);
    }
  }, [divRef.current?.clientWidth]);
  if (!(visible && event && point)) {
    return;
  }
  const { clientX, clientY } = event;

  return (
    <Portal>
      <Paper
        ref={divRef}
        sx={{
          zIndex: 99999,
          paddingX: "1.25rem",
          paddingY: "0.75rem",
          position: "fixed",
          left: Math.max(Math.min(clientX - (width ?? 0) / 2, window.innerWidth - (width ?? 0) - 32), 64),
          top: clientY + 32,
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <T alignSelf="center" fontWeight={600}>
          {(point.points[0].data.x as Date).toLocaleString(undefined, {
            month: "long",
            year: "numeric",
            timeZone: "UTC",
          })}
        </T>
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, auto)"
          rowGap="0.25rem"
          columnGap="0.75rem"
          alignItems="center"
        >
          {[...point.points].reverse().map((item) => (
            <React.Fragment key={item.serieId}>
              <Box flexShrink="0" borderRadius="9999px" height="0.75rem" width="0.75rem" bgcolor={item.serieColor} />
              <T>{item.serieId}</T>
              <T justifySelf="end">{item.data.yFormatted}</T>
            </React.Fragment>
          ))}
        </Box>
      </Paper>
    </Portal>
  );
};
