// Making this to match the new style modals with common Cancel/Submit buttons on it
import { Close } from "@mui/icons-material";
import { Box, BoxProps, Button, Dialog, IconButton, Typography as T, styled } from "@mui/material";

const BUTTON_WIDTH = "115px";

const CancelButton = styled(Button)({
  color: "rgba(0, 0, 0, 0.8)",
  justifyContent: "center",
  width: BUTTON_WIDTH,
});

const SubmitButton = styled(Button)({
  color: "rgba(11, 65, 205, 0.9)",
  fontWeight: 700,
  justifyContent: "center",
  width: BUTTON_WIDTH,
});

type ModalHeaderProps = {
  handleClose: () => void;
  children: React.ReactNode;
};

const ModalHeader = ({ handleClose, children, ...props }: ModalHeaderProps & BoxProps): JSX.Element => {
  return (
    <Box sx={{ padding: "1.5em", ...props.sx }}>
      <T fontWeight={600} fontSize={20}>
        {children}
      </T>
      <div style={{ position: "absolute", right: "1em", top: "1em" }}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </div>
    </Box>
  );
};

type StandardModalProps = {
  open: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  headerBox?: BoxProps;
  onSubmit: () => void;
  onCancel: () => void;
  disableSubmit?: boolean;
  hideFormControls?: boolean;
};

const StandardModal = ({
  open,
  title,
  children,
  headerBox,
  onSubmit,
  onCancel,
  disableSubmit,
  hideFormControls,
}: StandardModalProps): JSX.Element => {
  return (
    <Dialog open={open} onClose={onCancel} sx={{ borderRadius: "16px" }}>
      <ModalHeader handleClose={onCancel} {...headerBox}>
        {title}
      </ModalHeader>
      <Box sx={{ minWidth: "600px", padding: "0 1.5em" }}>
        <Box sx={{ overflow: "auto", padding: "0", maxHeight: 600 }}>{children}</Box>
        <Box
          sx={{
            paddingTop: "1em",
            paddingBottom: "1.5em",
            display: hideFormControls ? "none" : "flex",
            gap: "1em",
            justifyContent: "flex-end",
          }}
        >
          <CancelButton variant="outlined" onClick={onCancel}>
            Cancel
          </CancelButton>
          <SubmitButton variant="outlined" onClick={onSubmit} disabled={disableSubmit}>
            Submit
          </SubmitButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default StandardModal;
