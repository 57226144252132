import { useRef, useState } from "react";
import { Paper, PaperProps, Typography as T } from "@mui/material";
import { ChartMenu, FullScreen, DownloadButton } from "../../components/common";
import { downloadAsPng } from "../../utils/element-export";

import ChartCard from "../../components/ChartCard";
import EcosystemMap from "../../components/charts/EcosystemMap/EcosystemMap";
import StyledButton from "../../components/common/StyledButton";

const CardTooltipContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    <T fontWeight={900}>Ecosystem Map</T>
    The Ecosystem Map displays a heat map of the current record distribution in each Genentech ecosystem that fit your
    filter and search parameters. Please note that not all data sources include the ecosystem field. For those data
    sets, the Ecosystem map appears gray.
    <br />
    <br />
    <T fontWeight={900}>Region Map</T>
    Regions are comprised of Ecosystems. The Region displays a heat map of the current record distribution within each
    Genentech region that fit your filter and search parameters. As with ecosystems, not every data source includes
    region. In those cases, the Region map appears gray.
  </Paper>
);

export default function EcosystemMapCard(props: PaperProps) {
  const [mode, setMode] = useState<"ecosystem_name" | "region">("ecosystem_name");
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "ecosystem-map");
    }
  };

  const title = `${mode == "ecosystem_name" ? "Ecosystem" : "Region"} Map`;
  return (
    <ChartCard
      {...props}
      title={title}
      tooltipContent={<CardTooltipContent />}
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      actions={
        <>
          <StyledButton
            onClick={() => {
              setMode((_mode) => (_mode == "ecosystem_name" ? "region" : "ecosystem_name"));
            }}
          >
            Show {mode == "ecosystem_name" ? "Regions" : "Ecosystems"}
          </StyledButton>
          <ChartMenu onDownload={triggerDownload} onFullScreen={() => setExpanded(true)} />
        </>
      }
    >
      <FullScreen
        title={title}
        expanded={expanded}
        handleClose={() => setExpanded(false)}
        p={"1em"}
        actions={<DownloadButton action={triggerDownload} />}
      >
        <EcosystemMap ref={ref} isFullscreen={expanded} mapMode={mode} />
      </FullScreen>
    </ChartCard>
  );
}
