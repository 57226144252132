import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { CustomTab } from "../models/customTabs";
import { selectAuth } from "../store/authorization/auth.selector";
import { JWT } from "../store/authorization/auth.types";

const API_URI = import.meta.env.VITE_APP_API_URI;

const buildHeaders = (jwt: JWT) => ({
  "Content-Type": "application/json",
  Authorization: `${jwt.token}`,
  "Selected-Role": `${jwt.role?.at(0) ?? ""}`,
});

const queryKey = ["customTabs"];

export const useCustomTabs = () => {
  const queryClient = useQueryClient();
  const jwt = useSelector(selectAuth);

  const fetchCustomTabs = async (): Promise<CustomTab[]> => {
    const response = await axios.get<{ tabs: CustomTab[] }>(`${API_URI}/custom-tabs`, {
      headers: buildHeaders(jwt),
    });
    return response?.data?.tabs ?? [];
  };

  const updateCustomTabs = async (tabs: CustomTab[]) => {
    const payload = {
      tabs: tabs,
    };

    return await axios.post(`${API_URI}/custom-tabs`, payload, {
      headers: buildHeaders(jwt),
    });
  };

  const { data, isLoading } = useQuery({ queryKey, queryFn: fetchCustomTabs });

  const { mutate } = useMutation({
    mutationFn: updateCustomTabs,
    onMutate: async (newState: CustomTab[]) => {
      await queryClient.cancelQueries({ queryKey });
      const previousTabs = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newState);
      return { previousTabs };
    },
    onError: (_err, _newTodo, context) => {
      if (context?.previousTabs) {
        queryClient.setQueryData(queryKey, context.previousTabs);
      }
    },
  });

  return { customTabs: data ?? [], setCustomTabs: mutate, isLoading };
};
