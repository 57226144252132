import { useInfiniteQuery } from "@tanstack/react-query";
import { BaseResult, Sort } from "../models/search";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authorization/auth.selector";
import { useFilterContext } from "./FilterProvider";
import { getVerbatims } from "../api/newSearch";
import { defaultFilterState } from "../constants/defaultFilterState";

export interface VerbatimsInfinitePagingProps {
  sort: Sort | null;
}

export interface VerbatimsInfinite {
  records: BaseResult[];
  nextPageOffset: number | undefined;
}

const PAGE_SIZE = 30;
export default function useVerbatimsInfinitePaging({ sort }: VerbatimsInfinitePagingProps) {
  const jwt = useSelector(selectAuth);
  const { filterState } = useFilterContext();

  const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } =
    useInfiniteQuery<VerbatimsInfinite>({
      queryKey: ["verbatims-infinite", JSON.stringify(filterState), sort],
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageOffset;
      },
      queryFn: async ({ pageParam = 0 }): Promise<VerbatimsInfinite> => {
        const response = await getVerbatims(filterState || defaultFilterState, sort, pageParam, PAGE_SIZE, jwt);
        const { records, total_records } = response;

        // hasNextPage is true if getNextPageParam returns a value other than undefined
        // ref: https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        let nextPageOffset: number | undefined = undefined;
        if (total_records - pageParam > PAGE_SIZE) {
          nextPageOffset = pageParam + PAGE_SIZE;
        }

        return {
          nextPageOffset,
          records,
        };
      },
      enabled: !!filterState,
    });

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  };
}
