import { useEffect, useState } from "react";
import {
  Box,
  CssBaseline,
  Stack,
  ThemeProvider,
  Typography as T,
  LinearProgress,
  styled,
  linearProgressClasses,
} from "@mui/material";
import { TopBar } from "../components";
import { theme } from "../theme";

const LOADING_MESSAGE_INITIAL_TEXT = "Loading Deepsense...";
const LOADING_MESSAGE_LONG_WAIT_TEXT = "Still loading, thank you for your patience...";
const LOADING_MESSAGE_WAIT_TIME_MS = 5000;

const ThickLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
    animationDuration: "2.5s", // slowing it down as smidge
  },
}));

const LoadingPage = (): JSX.Element => {
  const [loadingMessage, setLoadingMessage] = useState(LOADING_MESSAGE_INITIAL_TEXT);

  useEffect(() => {
    setTimeout(() => setLoadingMessage(LOADING_MESSAGE_LONG_WAIT_TEXT), LOADING_MESSAGE_WAIT_TIME_MS);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TopBar hideSearchBar hideLogoutButton />
        <Box component="main" sx={{ height: "100vh", width: "100vw", padding: "128px 20px" }}>
          <Stack spacing={6} paddingTop="72px" alignItems="center">
            <img src="/logos/deepsense_logo.svg" alt="deepsense-logo" height={200} />
            <div style={{ width: "640px" }}>
              <ThickLinearProgress />
            </div>
            <T fontSize={18}>{loadingMessage}</T>
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default LoadingPage;
