import { IconButton, SxProps, Theme } from "@mui/material";

type StyledIconButtonProps = {
  children: JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: SxProps<Theme>;
};

const StyledIconButton = ({ children, onClick, sx }: StyledIconButtonProps): JSX.Element => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        bgcolor: "grey.200",
        borderRadius: "8px",
        "&:hover": { background: "rgba(11, 65, 205, 0.1)", border: "rgba(11, 65, 205, 0.05)" },
        ...sx,
      }}
    >
      {children}
    </IconButton>
  );
};

export default StyledIconButton;
