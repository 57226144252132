import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setJwt } from "../../store/authorization/auth.action";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "../../store/authorization/auth.selector";
import { getAuthorization } from "../../api/auth";
import { ErrorPage, RoleSelectorPage, LoadingPage } from "../../pages";

import Roles from "./Roles";

export const JwtRoles = {
  COMM_ROLE: "deepsense_commercial_nlp_users",
  MED_ROLE: "deepsense_medical_nlp_users",
  MED_INFO_ROLE: "deepsense_med_info",
  USLT_RETRO_ROLE: "deepsense_uslt_retro",
  // TODO: Verify if these are still in use
  C360_ROLE: "ssfcmgoasis_c360_users",
  C360_ROLE_MGMT: "ssfrolemgmt_ecosys_c360",
};

const hasMultipleDomains = (roles: string[]) =>
  roles.filter((r) => [Roles.MEDICAL, Roles.COMMERCIAL, Roles.USLT_RETRO].includes(r)).length > 1;

const Authorization = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [userJWT, setUserJWT] = useState<string>("");

  const { isLoading } = useSelector(selectAuth);

  const [authError, setAuthError] = useState<string | undefined>();

  const authUrl = import.meta.env.VITE_APP_AUTH_URL;
  if (!authUrl) {
    throw new Error("Auth url env variable has not been set.");
  }

  const redirectUrl = import.meta.env.VITE_APP_REDIRECT_URL;
  if (!redirectUrl) {
    throw new Error("Redirect url env variable has not been set.");
  }

  const accessCode = searchParams.get("code");

  useEffect(() => {
    if (isLoading && accessCode) {
      // Reset JWT state
      dispatch(setJwt({ token: null, role: null, app: "", isLoading: true }));
      getAuthorization(authUrl, redirectUrl, accessCode)
        .then((response) => {
          const { jwt, role, message } = response;
          if (!jwt) {
            throw message || undefined;
          }
          // Convert roles to lowercase and strip the environment specific portion of the string from the role
          const lowerRoles = (role ?? []).map((r) => r.toLowerCase().replace(/(_dev_|_qa_|_prod_)/i, "_"));

          // users should have medical OR comm role; throw error if role not assigned
          if (!lowerRoles || Object.values(JwtRoles).every((r) => !lowerRoles.includes(r))) {
            throw "You are not assigned a role.";
          }

          // assign frontend roles based on authorization response
          const roles: string[] = [];

          if (lowerRoles.includes(JwtRoles.MED_ROLE)) {
            roles.push(Roles.MEDICAL);
          }
          if (lowerRoles.includes(JwtRoles.MED_INFO_ROLE)) {
            roles.push(Roles.MED_INFO);
          }
          if (lowerRoles.includes(JwtRoles.COMM_ROLE)) {
            roles.push(Roles.COMMERCIAL);
          }
          if (lowerRoles.includes(JwtRoles.USLT_RETRO_ROLE)) {
            roles.push(Roles.USLT_RETRO);
          }
          if (lowerRoles.includes(JwtRoles.C360_ROLE) || lowerRoles.includes(JwtRoles.C360_ROLE_MGMT)) {
            roles.push(Roles.C360);
          }

          setUserRoles(roles);
          setUserJWT(jwt);
        })
        .catch((err: string) => {
          setAuthError(err);
        });
    }
  }, [accessCode, authUrl, dispatch, isLoading, nav, redirectUrl]);

  useEffect(() => {
    const userHasMultipleDomains = hasMultipleDomains(userRoles);

    if (!userHasMultipleDomains && userJWT) {
      dispatch(
        setJwt({
          token: userJWT,
          role: userRoles,
          app: null,
          isLoading: false,
        }),
      );
    }
  }, [userRoles, userJWT]);

  // if we want react-router-dom to handle errors for us, we have to put this outside
  // of the try-catch.
  if (authError) {
    return (
      <>
        <ErrorPage authErrorMessage={authError} />
      </>
    );
  }

  if (hasMultipleDomains(userRoles)) {
    return <RoleSelectorPage setUserRoles={setUserRoles} userRoles={userRoles} />;
  }

  return <LoadingPage />;
};

export default Authorization;
