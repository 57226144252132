import { MoreVert } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import StyledIconButton from "./StyledIconButton";
import IconExpand from "../../icons/IconExpand";
import IconExport2 from "../../icons/IconExport2";
import { CardToolTip } from "../../components/cards";

export type ChartMenuProps = {
  onFullScreen: () => void;
  onDownload: () => void;
  downloadToolTip?: React.ReactNode;
};

export default function ChartMenu({ onFullScreen, onDownload, downloadToolTip }: ChartMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFullScreen = () => {
    handleClose();
    onFullScreen();
  };

  const handleDownload = () => {
    handleClose();
    onDownload();
  };

  return (
    <div>
      <StyledIconButton
        sx={{
          bgcolor: "white",
          borderRadius: "4px",
          height: "40px",
          width: "40px",
        }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </StyledIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleDownload}>
          <ListItemIcon>
            <IconExport2 />
          </ListItemIcon>
          <ListItemText>Export as PNG</ListItemText>
          {downloadToolTip && <CardToolTip tooltipContent={downloadToolTip} sx={{ marginLeft: "0.5em" }} />}
        </MenuItem>

        <MenuItem onClick={handleFullScreen}>
          <ListItemIcon>
            <IconExpand />
          </ListItemIcon>
          <ListItemText>Full Screen</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
