import { useAtom } from "jotai";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OptionsObject } from "notistack";
import emitSnackbar from "../emitSnackbar";

import { selectAuth } from "../store/authorization/auth.selector";
import * as DeepchatApi from "../api/deepchat";
import { ChatRequest, ChatResponse, ThreadFeedback } from "../components/Deepchat/models";
import { SessionThreadAtom, QuestionIsLoadingAtom } from "../components/Deepchat/state/DeepchatState";
import { SearchFilters } from "../models/search";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";

const snackbarErrorOptions: OptionsObject = {
  anchorOrigin: { horizontal: "center", vertical: "top" },
  autoHideDuration: 15000,
  preventDuplicate: true,
};

export default function useDeepchat() {
  const jwt = useSelector(selectAuth);

  const [session] = useAtom(SessionThreadAtom);
  const [, setLoading] = useAtom(QuestionIsLoadingAtom);

  const navigate = useNavigate();

  const askQuestionStreaming = (sendJsonMessage: SendJsonMessage, filters: SearchFilters, question: string) => {
    setLoading(true);
    navigate(`/deepchat/threads/`);

    const sessionKeys = getKeyFromSession(session);

    // This returns nothing because the consumer is expected to be using { sendJsonMessage, lastJsonMessage } from GetDeepchatSocket()
    sendJsonMessage<WebSocketChatRequest<ChatRequest>>({
      action: "streamChat",
      service_requested: "deepchat",
      user_request: {
        user_chat_session: sessionKeys || null,
        search_request: filters,
        query: question,
      },
    });
  };

  const submitSessionFeedback = (feedback: ThreadFeedback) => {
    return DeepchatApi.postSessionFeedback(feedback, jwt).catch((error: AxiosError) => {
      console.error("submitSessionFeedback", error);

      // @ts-expect-error Digging error message out response object
      const message = error.response?.data?.detail?.message || "An error ocurred while submitting your feedback.";

      emitSnackbar(message, "error", snackbarErrorOptions);
    });
  };

  const getEnhanceQuestion = (question: string) => {
    return DeepchatApi.getEnhanceQuestion(question, jwt);
  };

  const getKeyFromSession = (session: ChatResponse[]) => {
    return session.at(0)?.user_chat_session;
  };

  const getHistory = () => {
    return DeepchatApi.getHistory(jwt);
  };

  const getSession = (sessionTimeStamp: string | number) => {
    return DeepchatApi.getSession(String(sessionTimeStamp), jwt);
  };

  return {
    askQuestionStreaming,
    submitSessionFeedback,
    getEnhanceQuestion,
    getKeyFromSession,
    getHistory,
    getSession,
  };
}

export interface WebSocketChatResponse<T> {
  type: string;
  data: T;
}

export interface WebSocketChatRequest<T> {
  action: string; // "streamChat"
  service_requested: "deepchat";
  user_request: T;
}
