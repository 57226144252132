import { SvgIcon } from "@mui/material";

export default function IconExpand() {
  return (
    <SvgIcon width={"18px"} sx={{ "& path": { fill: "#757575" } }} viewBox="0 0 14 14" fontSize="inherit">
      <g>
        <path
          d="M11.8125 9.5625V12.625H8.75M11.5336 12.3453L8.3125 9.125M2.1875 6.0625V3H5.25M2.46641 3.27973L5.6875 6.5M8.75 3H11.8125V6.0625M11.5328 3.27891L8.3125 6.5M5.25 12.625H2.1875V9.5625M2.46723 12.3461L5.6875 9.125"
          stroke="#13294B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8125 9.5625V12.625H8.75M11.5336 12.3453L8.3125 9.125M2.1875 6.0625V3H5.25M2.46641 3.27973L5.6875 6.5M8.75 3H11.8125V6.0625M11.5328 3.27891L8.3125 6.5M5.25 12.625H2.1875V9.5625M2.46723 12.3461L5.6875 9.125"
          stroke="black"
          strokeOpacity="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8125 9.5625V12.625H8.75M11.5336 12.3453L8.3125 9.125M2.1875 6.0625V3H5.25M2.46641 3.27973L5.6875 6.5M8.75 3H11.8125V6.0625M11.5328 3.27891L8.3125 6.5M5.25 12.625H2.1875V9.5625M2.46723 12.3461L5.6875 9.125"
          stroke="black"
          strokeOpacity="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
