import { Box, Grid } from "@mui/material";
import { FilterDrawer } from "../components";
import { RelevantTextRecordsCard } from "../components/cards";
import DocumentUsageCard from "../components/cards/DocumentUsageCard";
import InquiryVolumeBySource from "../components/cards/InquiryVolumeBySourceCard";
import InquiryVolumeOverTime from "../components/cards/InquiryVolumeOverTimeCard";
import SentimentOverTimeCard from "../components/cards/SentimentOverTimeCard";
import TopicDistributionCard from "../components/cards/TopicDistributionCard";
import TopicsNoInfoInquiriesCard from "../components/cards/TopicsNoInfoInquiriesCard";
import { VerbatimsProvider } from "../contexts/VerbatimsProvider";
import { TOP_BAR_HEIGHT } from "./AppLayout";
import { useSearchStore } from "../store/useSearchStore";

const HEIGHT = 377;

export default function MedInfoDashboardPage() {
  const { applyDefaultSearchState } = useSearchStore();
  applyDefaultSearchState();

  return (
    <>
      <FilterDrawer topPadding={50} />
      <Box component="main" sx={{ p: 0, flexGrow: 1, paddingTop: `${TOP_BAR_HEIGHT}px` }}>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ height: HEIGHT, display: "flex" }}>
              <TopicDistributionCard sx={{ flex: 12 }} />
            </Grid>
            <Grid item xs={12}>
              <DocumentUsageCard sx={{ flex: 12 }} />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", gap: "16px", height: HEIGHT }}>
              <InquiryVolumeOverTime sx={{ flex: 6 }} />
              <InquiryVolumeBySource sx={{ flex: 6 }} />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", height: HEIGHT }}>
              <TopicsNoInfoInquiriesCard sx={{ flex: 12 }} />
            </Grid>
            <Grid item xs={12}>
              <SentimentOverTimeCard sx={{ flex: 12 }} />
            </Grid>
            <Grid item xs={12}>
              <VerbatimsProvider>
                <RelevantTextRecordsCard />
              </VerbatimsProvider>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
}
