import { createContext, useContext, useEffect, useState } from "react";
import { SearchFilters } from "../models/search";
// import { defaultFilterState } from "../constants/defaultFilterState";
import { areFiltersSame, getSearchBase } from "../components/filters/FilterFunctions";
import { useSearchId } from "./SearchIdProvider";
import { useAtom } from "jotai";
import { FilterResetAtom } from "./FilterResetAtom";
import { useSearchStore } from "../store/useSearchStore";

export type FilterContextType = {
  filterState?: SearchFilters;

  applyFilters: (newFilterState: SearchFilters, searchId?: string) => void;
  resetFilters: () => void;
  hasActiveFilters: boolean;
  getDefaultSearchFilters: () => SearchFilters | undefined;
};

export const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const useFilterContext = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilters must be used within FilterProvider.");
  }
  return context;
};

export const FilterProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { defaultSearch } = useSearchStore();
  const { setSearchId } = useSearchId();
  const [filterState, setFilterState] = useState<SearchFilters>();
  const [, setFilterReset] = useAtom(FilterResetAtom);

  const applyFilters = (newFilterState: SearchFilters, searchId?: string) => {
    if (!filterState) return;

    setSearchId(searchId || null);

    setFilterState((_filterState) => ({
      ..._filterState,
      ...getSearchBase(newFilterState),
    }));
  };

  const getDefaultSearchFilters = (): SearchFilters | undefined =>
    defaultSearch
      ? {
          ...defaultSearch,
          /*
           * The search query and semantic checkbox value should
           * be preserved when resetting the left hand side filters.
           * Default value should fall back to the default filter state.
           */
          query: filterState?.query ?? defaultSearch.query,
          semantic: filterState?.semantic ?? defaultSearch.semantic,
        }
      : undefined;

  const resetFilters = () => {
    const defaultSearchFilters = getDefaultSearchFilters();
    setFilterReset((number: number) => {
      return number + 1;
    });
    setFilterState(defaultSearchFilters);
    setSearchId(null);
  };

  const hasActiveFilters =
    filterState && defaultSearch
      ? // If filterState and defaultSearch ARE the same then there ARE NOT "active" filters
        !areFiltersSame(filterState, defaultSearch)
      : false;

  // Global filter state sync, once `defaultSearch` is set we can trigger building the `defaultSearchFilters`
  useEffect(() => {
    if (!filterState && defaultSearch) {
      resetFilters();
    }
  }, [defaultSearch]);

  const value: FilterContextType = {
    filterState,

    applyFilters,
    resetFilters,
    hasActiveFilters,

    getDefaultSearchFilters,
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};
