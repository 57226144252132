export default function CheckboxIcon({ size }: { size?: "medium" | "small" }) {
  const scale = size === "small" ? 7 / 8 : 9 / 8;
  // Identical to SVG and much more performant
  return (
    <div
      style={{
        height: `${scale}rem`,
        width: `${scale}rem`,
        border: "2px solid",
        margin: "0.1875rem",
        borderRadius: "0.125rem",
        boxSizing: "border-box",
      }}
    />
  );
}
