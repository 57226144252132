import { atom, useAtom } from "jotai";
import { SearchFilters } from "../models/search";
import { useEffect } from "react";
import { defaultFilterState } from "../constants/defaultFilterState";

const DefaultSearchModelAtom = atom<SearchFilters | undefined>(undefined);

export const useSearchStore = () => {
  const [defaultSearch, setDefaultSearch] = useAtom(DefaultSearchModelAtom);

  const applyDefaultSearchState = () => {
    useEffect(() => {
      setDefaultSearch(defaultFilterState);
    }, []);
  };

  return {
    defaultSearch,
    setDefaultSearch,
    applyDefaultSearchState,
  };
};
