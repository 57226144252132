import dayjs from "dayjs";
import { useAtom } from "jotai";
import { FormGroup, FormControlLabel, Checkbox, FormControl, TextField, Typography as T, Box } from "@mui/material";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { EditVerbatimFeedback, FeedbackItems, FeedbackSubmission } from "./VerbatimsCard/FeedbackState";
import StandardModal from "../../components/common/StandardModal";
import useUserFeedback from "../../contexts/useUserFeedback";

export default function VerbatimFeedbackModal() {
  const { saveUserFeedback } = useUserFeedback();

  const [, setFeedbackItems] = useAtom(FeedbackItems);
  const [verbatim, setVerbatim] = useAtom(EditVerbatimFeedback);

  const isOpen = verbatim != undefined;
  const isReadOnly = !!verbatim?.feedback?.date_created;
  const isPositiveFeedback = verbatim?.feedback?.sentiment === 1;

  const handleSubmit = () => {
    if (verbatim) {
      saveUserFeedback({ payload: verbatim }).then((savedFeedbackItem) => {
        setVerbatim(undefined);
        setFeedbackItems((_items) => [..._items, savedFeedbackItem]);
      });
    }
  };
  const handleCancel = () => {
    setVerbatim(undefined);
  };

  const handleChangeAspect = (checked: boolean, aspect: string) => {
    if (checked) {
      setVerbatim((_verbatim): FeedbackSubmission<unknown> => {
        const response = { ..._verbatim } as FeedbackSubmission<unknown>;
        response.feedback.aspects = [...(_verbatim?.feedback?.aspects || []), aspect].sort();

        return response;
      });
    } else {
      setVerbatim((_verbatim): FeedbackSubmission<unknown> => {
        const response = { ..._verbatim } as FeedbackSubmission<unknown>;
        response.feedback.aspects = (_verbatim?.feedback?.aspects || []).filter((_aspect) => _aspect != aspect);

        return response;
      });
    }
  };

  const handleChangeComment = (updatedCommentText: string) => {
    setVerbatim((_verbatim): FeedbackSubmission<unknown> => {
      const response = { ..._verbatim } as FeedbackSubmission<unknown>;

      response.feedback.comment = updatedCommentText;

      return response;
    });
  };

  const isSentimentChecked = (sentiment: string) => {
    return !!sentiment && !!verbatim && verbatim.feedback?.aspects?.includes(sentiment);
  };

  return (
    <StandardModal
      title={
        <>
          {isPositiveFeedback ? (
            <>
              <ThumbUp sx={{ verticalAlign: "sub", marginRight: "0.5em" }} /> Glad you found this information helpful
            </>
          ) : (
            <>
              <ThumbDown sx={{ verticalAlign: "sub", marginRight: "0.5em" }} /> Let us know what could be better
            </>
          )}
        </>
      }
      open={isOpen}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      hideFormControls={isReadOnly}
    >
      {isPositiveFeedback ? (
        <T>Please tell us more about what was helpful on this card:</T>
      ) : (
        <T>Please tell us more about what was not helpful on this card:</T>
      )}

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSentimentChecked("topic")}
              disabled={isReadOnly}
              onChange={(elm, checked) => handleChangeAspect(checked, "topic")}
              value="topic"
            />
          }
          label="Topic"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isSentimentChecked("sentiment")}
              disabled={isReadOnly}
              onChange={(elm, checked) => handleChangeAspect(checked, "sentiment")}
              value="sentiment"
            />
          }
          label="Sentiment"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isSentimentChecked("summary")}
              disabled={isReadOnly}
              onChange={(elm, checked) => handleChangeAspect(checked, "summary")}
              value="summary"
            />
          }
          label="Summary"
        />
      </FormGroup>

      <FormControl fullWidth sx={{ paddingTop: "1em" }}>
        <T>Anything else you would like for us to know?</T>
        <TextField
          multiline
          rows={4}
          fullWidth
          disabled={isReadOnly}
          value={verbatim?.feedback?.comment || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeComment(event.target.value)}
        />
      </FormControl>
      {isReadOnly && (
        <Box pt="1em" pb="1.5em" textAlign="right">
          Submitted: {dayjs(verbatim.feedback.date_created).format("YYYY-MM-DD")}
        </Box>
      )}
    </StandardModal>
  );
}
