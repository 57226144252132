import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosError } from "axios";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import "./App.css";
import AuthenticationWrapper from "./AuthenticationWrapper";
import { Authorization } from "./components/Authorization";
import { AskQuestion } from "./components/Deepchat/AskQuestion";
import { SessionThread } from "./components/Deepchat/SessionThread";
import emitSnackbar from "./emitSnackbar";
import { ErrorPage, HomePage } from "./pages";
import AppLayout from "./pages/AppLayout";
import { CustomDashboard } from "./pages/CustomDashboard";
import DeepchatPage from "./pages/DeepchatPage";
import MedInfoDashboardPage from "./pages/MedInfoDashboardPage";
import RetrosPage from "./pages/RetrosPage";
import { store } from "./store/store";

interface ErrorResponse {
  detail: {
    message: string; // "Queries with nested parentheses are not allowed."
    code: number; // 400
  };
}

// setting up QueryClient context
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<ErrorResponse>;
        const detail = axiosError.response?.data?.detail;
        if (detail) {
          emitSnackbar(detail.message, "error", {
            anchorOrigin: { horizontal: "center", vertical: "top" },
            autoHideDuration: null,
            preventDuplicate: true,
          });
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 1000000,
      refetchOnMount: false,
      retry: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthenticationWrapper />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/auth",
        element: <Authorization />,
      },
      {
        path: "/",
        element: <AppLayout />,
        children: [
          {
            element: <Navigate replace to="/deepchat" />,
          },
          {
            path: "/explorer",
            element: <HomePage />,
          },
          {
            path: "/deepchat",
            element: <DeepchatPage />,
            children: [
              {
                path: "/deepchat",
                element: <AskQuestion />,
              },
              {
                path: "/deepchat/threads/:id?",
                element: <SessionThread />,
              },
            ],
          },
          {
            path: "/dashboards/medinfo",
            element: <MedInfoDashboardPage />,
          },
          {
            path: "/dashboards/retros",
            element: <RetrosPage />,
          },
          {
            // Could make sense as a child to dashboards but I think
            // medinfo is going away
            path: "/dashboards/custom/:id",
            element: <CustomDashboard />,
            children: [
              {
                path: "edit",
                element: <CustomDashboard />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default function App() {
  console.log("env:", import.meta.env.VITE_APP_APP_ENV);
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} />
        <RouterProvider router={router} />
        <ReactQueryDevtools />
      </Provider>
    </QueryClientProvider>
  );
}
