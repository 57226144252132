import { ThumbDownOffAlt, ThumbUpOffAlt } from "@mui/icons-material";
import { Box, Paper, PaperProps, Typography as T, Typography } from "@mui/material";
import { useRetroStore } from "../../store/retros/useRetroStore";
import ChartCard from "../ChartCard";
import { TPortfolioSummary } from "../Deepchat/models";

const formatResponseText = (text: TPortfolioSummary) => {
  console.log("text", text);

  const response = (text.summary || "").split("\n").flatMap((segment, i) => {
    const isListItem = segment.startsWith("- ");
    const isBlankLine = !segment;

    if (isListItem) {
      // Clean off the "- " and make element a <li>
      return <li key={i}>{segment.replace(/^(- )/, "")}</li>;
    }
    return (
      <Typography py={isBlankLine ? "0.25em" : 0} key={i}>
        {segment}
      </Typography>
    );
  });

  return response;
};

const RetrosPortfolioSummaryContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    <Typography fontWeight={900}>Retros Portfolio Summary</Typography>
    This widget (some example text that we want to display)
    <br />
    <br />
    <Typography fontWeight={900}>Some other title</Typography>
    Additional text to display in the info icon
  </Paper>
);

export default function PortfolioSummaryCard(props: PaperProps) {
  const { quarter, summary } = useRetroStore();

  const handleThumbsUp = () => {
    console.log("Thumbs UP");
  };
  const handleThumbsDown = () => {
    console.log("Thumbs DOWN");
  };

  return (
    <ChartCard
      {...props}
      title="Retros Portfolio Summary"
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      // tooltipContent={<RetrosPortfolioSummaryContent />}
    >
      <Box sx={{ flexDirection: "row", display: "flex", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em" }}>
        <Box sx={{ display: "flex", flex: 1 }}>
          <T display="inline" fontWeight={500} fontSize={20} color="label.main">
            Summary
          </T>
        </Box>
        <Box sx={{ display: "flex", gap: "0.75em" }}>
          <ThumbUpOffAlt onClick={handleThumbsUp} />
          <ThumbDownOffAlt onClick={handleThumbsDown} />
        </Box>
      </Box>
      <Box sx={{ padding: "2em", paddingTop: "1em" }}>{formatResponseText(summary)}</Box>
    </ChartCard>
  );
}
