import React from "react";
import { Button } from "@mui/material";

type ClearFiltersButtonProps = {
  height: number;
  hasUnappliedFilterChanges: boolean;
  applyFilters: () => void;
};

export default function ApplyFiltersButton({
  height,
  hasUnappliedFilterChanges,
  applyFilters,
}: ClearFiltersButtonProps) {
  const _style: React.CSSProperties = {
    height,
    color: "#0B41CD",
    ...(hasUnappliedFilterChanges ? { backgroundColor: "rgba(11, 65, 205, 0.25)" } : {}),
  };

  return (
    <Button variant="text" disabled={!hasUnappliedFilterChanges} fullWidth sx={_style} onClick={applyFilters}>
      Apply Filters
    </Button>
  );
}
