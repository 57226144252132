import { DataGrid, DataGridProps } from "@mui/x-data-grid";

// header height has to be overwritten in multiple places; saving as constant
// in case styling ever changes
const HEADER_HEIGHT = 36;

const StyledDataGrid = (props: DataGridProps): JSX.Element => {
  return (
    <DataGrid
      {...props}
      // placing after the general props spread to allow custom styles to be overwritten
      // properly, while preserving general component styles
      sx={{
        borderRadius: 2,
        "& .MuiDataGrid-columnHeaders": {
          minHeight: `${HEADER_HEIGHT}px !important`,
          maxHeight: `${HEADER_HEIGHT}px !important`,
        },
        "& .MuiDataGrid-columnHeader": {
          fontSize: 12,
          height: `${HEADER_HEIGHT}px !important`,
        },
        "& .MuiDataGrid-columnSeparator": {
          visibility: "hidden",
        },
        "& .MuiDataGrid-virtualScroller": {
          marginTop: `${HEADER_HEIGHT}px !important`,
        },
        "& .MuiDataGrid-row": {
          "&:hover": {
            background: "rgba(11, 65, 205, 0.1)",
          },
        },
        ...props.sx,
      }}
    />
  );
};

export default StyledDataGrid;
