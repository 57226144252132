import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ChatResponse } from "./models";
import dayjs from "dayjs";
import { History } from "@mui/icons-material";

export default function RecentThreadItem(props: ChatResponse) {
  return (
    <Box className="history-item">
      <Link to={`/deepchat/threads/${props.user_chat_session}`} style={{ textDecoration: "none", color: "inherit" }}>
        <Typography className="history-title">{props.user_query}</Typography>
        {props.timestamp && (
          <Typography className="history-timestamp">
            <History sx={{ verticalAlign: "sub", marginRight: "0.5em", fontSize: "1.25em" }} />
            {dayjs.unix(props.timestamp).from(dayjs())}
          </Typography>
        )}
      </Link>
    </Box>
  );
}
