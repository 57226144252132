import { useAtom } from "jotai";
import {
  FocusedThreadQuestion,
  QuestionCanAcceptFeedback,
  QuestionIsLoadingAtom,
  QuestionTextAtom,
  SessionThreadAtom,
} from "../../components/Deepchat/state/DeepchatState";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQuestionEnhancer } from "./useQuestionEnhancer";

export interface AskQuestionStoreProps {
  initial_query?: string;
}

export const useAskQuestionStore = () => {
  const [loading, setLoading] = useAtom(QuestionIsLoadingAtom);
  const [, setSession] = useAtom(SessionThreadAtom);
  const [, setQuestion] = useAtom(QuestionTextAtom);
  const [, setAllowFeedback] = useAtom(QuestionCanAcceptFeedback);
  const [, setFocusedQuestion] = useAtom(FocusedThreadQuestion);

  const resetState = () => {
    setAllowFeedback(false);
    setQuestion("");
    setFocusedQuestion(undefined);
    setSession([]);
  };

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }

    resetState();
  }, []);
};
