import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { ArticleOutlined, TableChartOutlined } from "@mui/icons-material";

export const VIEW_CARDS = "cards";
export const VIEW_GRID = "grid";

type ViewToggleProps = {
  selectedView: string;
  handleViewChange: (event: React.MouseEvent<HTMLElement>, newView: string) => void;
};

const ViewToggle = ({ selectedView, handleViewChange }: ViewToggleProps): JSX.Element => {
  return (
    <ToggleButtonGroup
      size="small"
      value={selectedView}
      exclusive
      onChange={handleViewChange}
      aria-label="toggle-view-buttons"
      sx={{ bgcolor: "white" }}
    >
      <Tooltip title="Card View">
        <ToggleButton value={VIEW_CARDS} aria-label="toggle-view-button-chart">
          <ArticleOutlined sx={{ color: selectedView === VIEW_CARDS ? "#13294B" : "inherit" }} />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Table View">
        <ToggleButton value={VIEW_GRID} aria-label="toggle-view-button-table">
          <TableChartOutlined sx={{ color: selectedView === VIEW_GRID ? "#13294B" : "inherit" }} />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

export default ViewToggle;
