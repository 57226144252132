import { useEffect, useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { UUID } from "crypto";

import { useAtom } from "jotai";
import { QuestionCanAcceptFeedback, QuestionIsLoadingAtom, SessionThreadAtom } from "../state/DeepchatState";

import useDeepchat from "../../../contexts/useDeepchat";
import { useFilterContext } from "../../../contexts/FilterProvider";
import QuestionInput from "../AskQuestion/QuestionInput";
import SessionResponseItem from "./SessionResponseItem";
import SessionQuestionsOutline from "./SessionQuestionsOutline";
import { useQuestionEnhancer } from "../../../store/home/useQuestionEnhancer";

export default function SessionThread() {
  const Deepchat = useDeepchat();
  const { closeEnhancerPanel, resetEnhancerState } = useQuestionEnhancer();
  const { applyFilters } = useFilterContext();

  const location = useLocation();
  const [session, setSession] = useAtom(SessionThreadAtom);
  const [, setAllowFeedback] = useAtom(QuestionCanAcceptFeedback);
  const [, setLoading] = useAtom(QuestionIsLoadingAtom);

  // Scroll the title of the most recent question up into view when the session[] updates
  useEffect(() => {
    const target = session.at(-1);
    if (target) {
      const anchorId = `${target?.user_chat_session}-${session.indexOf(target)}`;
      scrollQuestionIntoView(anchorId);
    }
  }, [session]);

  const getThreadId = (value: string) => {
    return value ? (value as UUID) : undefined;
  };

  // -- Direct Navigation Thread loading Logic
  useEffect(() => {
    closeEnhancerPanel();
    resetEnhancerState();

    const path = location.pathname.split("/");
    const threadId = getThreadId(path.at(-1) ?? "");

    const preventLoad = location.state?.prevent_load ?? false;
    // eg: /deepchat/threads/1714004561
    if (path.at(-2) === "threads" && threadId && !preventLoad) {
      setLoading(true);

      Deepchat.getSession(threadId)
        .then((data) => {
          const firstResponse = data.at(0);
          if (firstResponse?.search_object) {
            applyFilters(firstResponse.search_object);
          }

          setSession(data);
          setAllowFeedback(true);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const ThreadResponsesMemo = useMemo(() => {
    return session.map((response, i) => {
      const anchorId = `${String(response.user_chat_session)}-${i}`;
      return <SessionResponseItem key={i} anchorId={anchorId} response={response} index={i} />;
    });
  }, [session]);

  return (
    <>
      {/* Main body content */}
      <Box flex={1} minHeight="100%" maxWidth="48rem" marginX="auto">
        <Box sx={{ px: "1em" }}>{ThreadResponsesMemo}</Box>

        {/* Followup question container */}
        <Box sx={{ height: "84px" }} className="followup-input-base">
          <AutoSizer disableHeight>
            {({ width }) => (
              <Box
                sx={{
                  position: "fixed",
                  width: width,
                  bottom: 0,
                  backgroundImage: "linear-gradient(to bottom, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 25%)",
                  py: "2em",
                }}
              >
                <QuestionInput placeholder="Ask a related follow-up question…" hideEnhanceButton={true} />
              </Box>
            )}
          </AutoSizer>
        </Box>
      </Box>

      {/* Current Thread Fixed Sidebar  */}
      <>
        <Box sx={{ width: "24vw", minWidth: "360px" }}></Box>
        <Box
          sx={{
            width: "24vw",
            minWidth: "360px",
            ml: "2em",
            pl: "2em",
            borderLeft: "1px solid #eee",
            position: "fixed",
            right: 0,
          }}
        >
          <Box sx={{ display: "flex", gap: "1em", flexDirection: "column", height: "calc(100vh - 74px - 2em)" }}>
            <SessionQuestionsOutline />
          </Box>
        </Box>
      </>
    </>
  );
}

export const scrollQuestionIntoView = (questionTimestamp: string) => {
  const element = document.getElementById(questionTimestamp);
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY - (48 + 24); // Menu + 1em padding
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};
