import { Chip, ChipProps, chipClasses } from "@mui/material";
import { useInView } from "react-intersection-observer";

interface InsightChipProps extends ChipProps {
  selected: boolean;
}

const InsightChip = ({ label, selected, ...props }: InsightChipProps) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  const _style: React.CSSProperties | undefined = inView ? undefined : { visibility: "hidden" };

  return (
    <Chip
      {...props}
      ref={ref}
      style={_style}
      label={label}
      variant={"outlined"}
      sx={{
        border: selected ? "1px solid transparent" : undefined,
        background: selected ? "rgba(11, 65, 205, 0.10)" : undefined,
        margin: "0 8px 8px 0",
        fontSize: "1em",
        height: "34px",
        "&:hover": {
          background: "rgba(11, 65, 205, 0.1)",
          border: "1px solid rgba(11, 65, 205, 0.05)",
        },
        [`& .${chipClasses.label}`]: {
          lineHeight: "unset",
          fontSize: "unset",
          padding: "4px 12px",
        },
      }}
    />
  );
};

export default InsightChip;
