import React from "react";
import { Box, Divider, Paper, PaperProps, Stack, Typography as T } from "@mui/material";
import { CardToolTip } from "./cards";

type ChartCardProps = {
  title: React.ReactNode[] | React.ReactNode;
  tooltipContent?: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  backgroundColor?: string;
};

const ChartCard = ({
  title,
  tooltipContent,
  children,
  actions,
  backgroundColor,
  ...props
}: ChartCardProps & Omit<PaperProps, "title">): JSX.Element => {
  const titleItems = Array.isArray(title) ? title : [title];
  return (
    <Paper
      elevation={0}
      {...props}
      sx={{
        padding: "16px",
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "8px",
        backgroundColor: backgroundColor ?? "#FFFFFF",
        ...props.sx,
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ margin: "-16px 0 0 0", minHeight: "56px" }}>
        <Box display={"flex"}>
          {titleItems.map((item, i) => {
            if (i == 0) {
              return (
                <T key={i} display="inline" fontWeight={700} fontSize={16} color="label.main">
                  {item}
                </T>
              );
            } else {
              return (
                <T key={i} display="inline" fontSize={16} color="label.main">
                  &nbsp;· {item}
                </T>
              );
            }
          })}

          {Boolean(tooltipContent) && (
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} ml={1}>
              <CardToolTip tooltipContent={tooltipContent} />
            </Box>
          )}
        </Box>
        <Box sx={{ flex: 1 }}></Box>
        {actions || <div />}
      </Stack>
      <Divider sx={{ marginBottom: "16px" }} />
      {children}
    </Paper>
  );
};

export default ChartCard;
