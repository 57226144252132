import React, { useState } from "react";
import { Box, Button, Collapse, Grid, Stack } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

type StyledExpansionPanelProps = {
  title: React.ReactNode;
  subtitle?: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  noPadding?: boolean;
  compressed?: boolean;
};

const StyledExpansionPanel = ({
  title,
  subtitle,
  children,
  defaultOpen,
  noPadding,
  compressed,
}: StyledExpansionPanelProps): JSX.Element => {
  const [expanded, setExpanded] = useState(!!defaultOpen);

  const handleExpansionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Button
        fullWidth
        disableRipple
        variant="contained"
        sx={{
          borderRadius: 0,
          color: "text.primary",
          borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
          backgroundColor: "background.paper",
          outline: "none !important",
          padding: "0 4px 0 0",
          paddingLeft: noPadding ? "0px" : "18px",
          height: compressed ? 40 : 56,
          ":hover": {
            backgroundColor: "background.paper",
            borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
          },
        }}
        onClick={handleExpansionChange}
      >
        <Grid container alignItems="center">
          <Grid item flexGrow={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box fontSize={compressed ? 14 : 16}>{title}</Box>
              <Box fontSize={12}>{subtitle || ""}</Box>
            </Stack>
          </Grid>
          <Grid item height={24}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </Grid>
        </Grid>
      </Button>
      <Collapse in={expanded}>{expanded && children}</Collapse>
    </div>
  );
};

export default StyledExpansionPanel;
