import { Box, IconButton } from "@mui/material";
import IconExport2 from "../../icons/IconExport2";

export type DownloadButtonProps = {
  action: () => void;
};

export default function DownloadButton({ action }: DownloadButtonProps) {
  return (
    <Box px={"0.5em"}>
      <IconButton sx={{ color: "white", "& svg *": { fill: "white" } }} onClick={action}>
        <IconExport2 />
      </IconButton>
    </Box>
  );
}
