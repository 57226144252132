import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { areFiltersSame, getSearchBase } from "../../components/filters/FilterFunctions";
import { SearchFilters } from "../../models/search";
import { defaultFilterState } from "../../constants/defaultFilterState";

const TransientFilterState = atom<SearchFilters>(defaultFilterState);

/**
 * This is a deliberately localized state implementation since attempting to
 * spread a "pre-applied" filter state context across the application causes too many re-renders
 */
export default function useLocalFilterState(filterState?: SearchFilters) {
  const [transientFilterState, setTransientFilterState] = useAtom(TransientFilterState);

  // Synchronize the local state when the source state updates
  useEffect(() => {
    if (!filterState) return;

    if (!areFiltersSame(filterState, transientFilterState)) {
      setTransientFilterState((prevLocalState) => ({
        ...prevLocalState,
        ...getSearchBase(filterState),
      }));
    }
  }, [filterState]);

  const hasUnappliedFilterChanges =
    !!filterState && !!transientFilterState && !areFiltersSame(filterState, transientFilterState);

  return {
    transientFilterState,
    setTransientFilterState,
    hasUnappliedFilterChanges,
  };
}
