import { atom } from "jotai";

export interface VerbatimFeedback {
  document_id?: string;
  document_text?: string;
  sentiment: number;
  aspects?: string[];
  comment?: string;
  date_created?: string;
}

export const FeedbackBase: VerbatimFeedback = {
  sentiment: 0,
  aspects: [],
  comment: "",
};

export interface FeedbackSubmission<T> {
  feedback: VerbatimFeedback;
  document: T;
}

// const example = {
//   document_id: "a045f00000LaOCRAA3",
//   sentiment: 1,
//   aspects: ["topic", "sentiment", "summary"],
//   comment: "Free text comment",
//   date_created: "2024-02-13T17:22:11.374881",
// };

export const EditVerbatimFeedback = atom<FeedbackSubmission<unknown> | undefined>(undefined);

export const FeedbackItems = atom<VerbatimFeedback[]>([]);
