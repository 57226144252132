import { SxProps, styled } from "@mui/material/styles";
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

type TableListProps = {
  rows: {
    name: string;
    values: string[] | number[];
  }[];
  noBorder?: boolean;
  sx?: SxProps;
};

const StyledBorderBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  paddingBottom: "10px",
}));
const StyledBox = styled(Box)(() => ({
  paddingBottom: "10px",
}));

const TableList = ({ rows, noBorder = false, sx }: TableListProps) => {
  return (
    <TableContainer sx={{ borderRadius: `10px` }} component={noBorder ? StyledBox : StyledBorderBox}>
      <Table size="small" sx={{ fontSize: 16, borderRadius: "10px, 0 0 10px", ...sx }}>
        <TableBody>
          {/* Iterate over each selected filter key index "i" */}
          {rows.map((row, i) =>
            // Iterate over each selected filter key's value index "j" */}
            row.values.map((val, j) => {
              const rowDisplayName = row.name.replace("_", " ");
              return (
                <TableRow
                  key={`${row.name}-${j}`}
                  // Apply the border stylying if the value is not the first filter label but is the first filter value to display
                  sx={{ ...(j === 0 && i !== 0 && { borderTop: (theme) => `1px solid ${theme.palette.grey[300]}` }) }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    width={`50%`}
                    sx={{
                      borderBottom: "none",
                      fontSize: 16,
                      fontWeight: 600,
                      height: 50,
                      color: "text.secondary",
                      textTransform: "capitalize",
                    }}
                  >
                    {j === 0 && rowDisplayName}
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "none", fontSize: 16, pr: `10px` }}>
                    {val}
                  </TableCell>
                </TableRow>
              );
            }),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableList;
