export const TOOL_TIP_CONTENT = [
  {
    title: "Semantic Search (default):",
    tips: [
      {
        primaryText: "Discover relevant results with semantic search.",
      },
      {
        primaryText:
          "Enter natural language queries and we'll find matches based on meaning across all text fields from a data source.",
        secondaryText: "'MS'  will also return results for 'multiple sclerosis' or 'multiple sclarosis'",
      },
      {
        primaryText: "No need for complex operators, just type your query.",
      },
    ],
  },
  {
    title: "Exact Search:",
    tips: [
      {
        primaryText: "Use boolean operators (AND, OR, NOT) to refine your search.",
        secondaryText: '"covid virus AND ocrevus" will include both "covid virus" and "ocrevus"',
      },
      {
        primaryText: "Group search terms with parenthesis for advanced queries.",
        secondaryText: '"covid" AND ("ocrevus" OR "virus") will include "covid" AND either "ocrevus" or "virus"',
      },
      {
        primaryText: "Fuzzy search is enabled to search documents containing terms similar to the search term.",
      },
    ],
  },
];
