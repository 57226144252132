import { useAtom } from "jotai";
import { Box, Typography, Button, SxProps } from "@mui/material";
import { QuestionAnswerOutlined, AutoAwesomeOutlined, ThumbUp, ThumbDown } from "@mui/icons-material";
import { FocusedThreadQuestion, QuestionCanAcceptFeedback, SessionThreadAtom } from "../state/DeepchatState";
import useDeepchat from "../../../contexts/useDeepchat";
import { scrollQuestionIntoView } from "./SessionThread";
import { useEffect, useState } from "react";
import { ThreadFeedback } from "../models";
import { useNavigate } from "react-router-dom";
import { useCallout } from "../Callout";

const ThumbStyle: SxProps = {
  verticalAlign: "sub",
  fill: "rgba(66, 84, 102, 0.2)",
  cursor: "pointer",
  "&:hover": {
    fill: "rgba(66, 84, 102, 0.7)",
  },
  "&.selected": {
    fill: "rgba(19, 41, 75, 1)",
  },
};

export default function SessionQuestionsOutline() {
  const Deepchat = useDeepchat();
  const navigate = useNavigate();

  const [allowFeedback] = useAtom(QuestionCanAcceptFeedback);
  const [focusedQuestion] = useAtom(FocusedThreadQuestion);
  const [session] = useAtom(SessionThreadAtom);

  const [feedback, setFeedback] = useState<ThreadFeedback>({ action_id: undefined, feedback: 0 });
  const [Callout, closeCallout] = useCallout(
    "To start a new line of questioning or to use a different dataset, start a new question thread",
  );

  const handleThumbsUp = () => {
    const action_id = session.at(0)?.user_chat_session;
    if (action_id) {
      setFeedback({
        feedback: 1,
        action_id: action_id,
      });
    }
  };

  const handleThumbsDn = () => {
    const action_id = session.at(0)?.user_chat_session;
    if (action_id) {
      setFeedback({
        feedback: -1,
        action_id: action_id,
      });
    }
  };

  useEffect(() => {
    if (feedback.feedback !== 0 && feedback.action_id) {
      Deepchat.submitSessionFeedback(feedback);
    }
  }, [feedback]);

  useEffect(() => {
    const chat_feedback = session.at(0)?.chat_feedback ?? 0;
    setFeedback((_feedback) => ({ ..._feedback, feedback: chat_feedback }));
  }, [session]);

  // What is the infusion experience like for the Ocrevus patients?
  // What side effects have patients using Ocrevus mentioned?
  return (
    <>
      <Box>
        <QuestionAnswerOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
        <Typography variant="h6" color={"#13294B"} display="inline-block">
          Current Thread
        </Typography>
      </Box>

      <Box
        sx={{
          overflowY: "scroll",
          pr: "2em",
          flex: 1,
          minHeight: 0,
          "& .thread-question": {
            marginBottom: "1em",
            color: "blue",
            cursor: "pointer",

            "&.focused": { borderLeft: "2px solid navy", color: "navy", pl: "0.5em" },
          },
        }}
      >
        {session.map((response, i) => {
          const classes = ["thread-question"];
          if (focusedQuestion === response) {
            classes.push("focused");
          }

          // NOTE: This only works because ${i} is the same here that it is in <SessionThread />
          const anchorId = `${String(response.user_chat_session)}-${i}`;
          return (
            <Box key={i} className={classes.join(" ")} onClick={() => scrollQuestionIntoView(anchorId)}>
              {response.user_query}
            </Box>
          );
        })}
      </Box>

      {allowFeedback && (
        <Box
          sx={{ paddingRight: "2em", paddingBottom: "1em", display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Box sx={{ flex: "1 1 auto" }}>
            <Typography sx={{ display: "inline-block", color: "rgba(66, 84, 102, 0.5)", fontSize: "0.8em" }}>
              Have the answers in this thread been accurate and helpful?
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1em", flex: "0 0 auto", marginLeft: "auto" }}>
            <ThumbUp sx={ThumbStyle} className={feedback?.feedback === 1 ? "selected" : ""} onClick={handleThumbsUp} />
            <ThumbDown
              sx={ThumbStyle}
              className={feedback?.feedback === -1 ? "selected" : ""}
              onClick={handleThumbsDn}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ paddingRight: "2em", textAlign: "center" }}>
        <Callout x="right" y="bottom" axis="y" placement={{ bottom: "112.5%", right: "12.5%" }}>
          <Button
            variant="contained"
            sx={{ color: "#13294B" }}
            startIcon={<AutoAwesomeOutlined sx={{ fill: "#13294B" }} />}
            onClick={() => {
              closeCallout();
              navigate("/deepchat");
            }}
          >
            Start a New Question Thread
          </Button>
        </Callout>
      </Box>
    </>
  );
}
