import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, CssBaseline, Link, Stack, Typography as T, ThemeProvider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { TopBar } from "../components";
import { CopyTextButton, StyledIconButton } from "../components/common";
import { theme } from "../theme";

const NUM_LINES_TO_SHOW = 3;
const FEEDBACK_URL = import.meta.env.VITE_APP_FEEDBACK_GSHEET_URL;
const ACCESS_REQUEST_FORM_URL = import.meta.env.VITE_APP_REQUEST_FORM;

interface ErrorPageProps {
  authErrorMessage?: string;
}

const ErrorPage = ({ authErrorMessage }: ErrorPageProps): JSX.Element => {
  const routeError = useRouteError();
  let errorMessage = "";
  if (!authErrorMessage) {
    errorMessage = isRouteErrorResponse(routeError)
      ? routeError.data
      : routeError instanceof Error
        ? routeError.message
        : "Unknown error.";
  } else {
    errorMessage = authErrorMessage;
  }
  // text expansion
  const textRef = useRef<HTMLDivElement | null>(null);
  const [textExpanded, setTextExpanded] = useState(false);
  const [showExpandedButton, setShowExpandedButton] = useState(false);

  useEffect(() => {
    // we only want to check this once -- if we don't, the button will disappear when the
    // panel is expanded
    if (!showExpandedButton && textRef.current) {
      setShowExpandedButton(textRef.current.clientHeight !== textRef.current.scrollHeight);
    }
  }, [textRef]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TopBar hideSearchBar />
        <Box component="main" sx={{ height: "100vh", width: "100vw", padding: "88px 20px" }}>
          <Stack spacing={2} paddingTop="72px" alignItems="center">
            <img src="/icons/iconoir_chat-bubble-error.svg" alt="error" />
            <T fontSize={56}>There's been an error.</T>
            <Box
              sx={{
                width: "800px",
                padding: "16px 16px 16px 32px",
                borderRadius: "8px",
                border: "1px solid #ECC7CD",
                background: "rgba(236, 199, 205, 0.70)",
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <T
                  ref={textRef}
                  fontSize={16}
                  color="#C8102E"
                  sx={
                    !textExpanded
                      ? {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: NUM_LINES_TO_SHOW,
                          WebkitBoxOrient: "vertical",
                          display: "-webkit-box",
                        }
                      : {}
                  }
                >
                  {errorMessage.startsWith("You are not authorized to view this application.") ||
                  errorMessage.startsWith("Your permissions are invalid") ? (
                    <>
                      {errorMessage.startsWith("Your permissions are invalid") ? errorMessage + "." : errorMessage} If
                      you need access, please submit a request via the{" "}
                      <Link sx={{ cursor: "pointer" }} target="_blank" href={ACCESS_REQUEST_FORM_URL}>
                        ServiceNow Form
                      </Link>
                    </>
                  ) : (
                    errorMessage
                  )}
                </T>
                <Stack spacing={1}>
                  {showExpandedButton && (
                    <StyledIconButton
                      sx={{
                        width: "24px",
                        height: "24px",
                        backgroundColor: "rgba(255, 255, 255, 0.25)",
                        color: "#C8102E",
                      }}
                      onClick={() => setTextExpanded(!textExpanded)}
                    >
                      {textExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                    </StyledIconButton>
                  )}
                  <CopyTextButton
                    text={errorMessage}
                    tooltipText="Copy error message to clipboard"
                    buttonSx={{
                      width: "24px",
                      height: "24px",
                      backgroundColor: "rgba(255, 255, 255, 0.25)",
                      color: "#C8102E",
                      fontSize: 16,
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
            <Box sx={{ width: "100%", textAlign: "center", paddingBottom: "48px" }}>
              <T> If you feel you've reached this screen as a result of a system error,</T>
              <T>
                please report the bug via the{" "}
                <Link sx={{ cursor: "pointer" }} target="_blank" href={FEEDBACK_URL}>
                  feedback form
                </Link>
                .
              </T>
            </Box>
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ErrorPage;
