import { AutoAwesomeOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/material";
import { useRetroStore } from "../store/retros/useRetroStore";
import { useFilterContext } from "../contexts/FilterProvider";

export default function PortfolioViewSummaryButton() {
  const { loading, getSummary, summary, quarter } = useRetroStore();
  const { resetFilters } = useFilterContext();

  // If we have summary content, we want to turn the button off
  const disabled = Boolean(summary.summary);

  return (
    <Button
      fullWidth
      variant="outlined"
      startIcon={<AutoAwesomeOutlined />}
      endIcon={loading ? <CircularProgress size={20} /> : undefined}
      disabled={disabled}
      sx={{
        padding: "5px 8px",
        color: "black",
        "&:hover": {
          color: "black",
        },
        "& .MuiButton-startIcon": {
          marginRight: "12px !important",
        },
      }}
      onClick={() => {
        resetFilters();
        getSummary(quarter);
      }}
    >
      Retros Portfolio Summary
    </Button>
  );
}
