import axios, { AxiosResponse } from "axios";
import html2canvas from "html2canvas";

export const downloadImage = ({
  fileUrlData,
  fileName,
  format,
}: {
  fileUrlData: string;
  fileName: string;
  format: string;
}) => {
  const a = document.createElement("a");
  a.download = `${fileName}.${format}`;
  a.href = fileUrlData;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(fileUrlData);
};

// ref: https://stackoverflow.com/a/67533949
interface PromiseFulfilledResult<T> {
  status: "fulfilled";
  value: T;
}

// Sourced from: https://github.com/niklasvh/html2canvas/issues/1463#issuecomment-1112428054
type FontConversion = {
  base64: string;
  url: string;
};

const convertFontsToBase64 = async () => {
  const fontFaceRules = getFontFaceRules();
  const urls = getFontFaceUrls(fontFaceRules);
  let fontFaceCss = fontFaceRules;

  if (urls && urls.length > 0) {
    const conversions = await convertFontsToDataUrls(urls);

    fontFaceCss = replaceUrls(fontFaceCss, conversions);
  }

  return fontFaceCss;
};

// find every font face rule and join them into 1 big string
const getFontFaceRules = () =>
  Array.from(document.styleSheets)
    .flatMap((sheet) => Array.from(sheet.cssRules))
    .filter((rule) => rule instanceof CSSFontFaceRule)
    .map((rule) => rule.cssText)
    .join("\n");

const getFontFaceUrls = (fontFaceRules: string) =>
  // one difference here is that our regex looks for
  // quotes around the URL
  fontFaceRules.match(/"?https?:\/\/[^ )]+/g);

const convertFontsToDataUrls = async (urls: string[]) => {
  // this lookup will be used to track what URL is being replaced
  // since the originals could be in the form `"https://site.com/font.woff"`
  // or just `https://site.com/font.woff`
  const urlLookup: Record<string, string> = {};
  // here we do each font request (through Axios) + track the URL
  // for later use
  const fontFetches = urls.map((url) => {
    const strippedUrl = url.replace(/(^"|"$)/g, "");

    urlLookup[strippedUrl] = url;

    return axios.get<Blob>(strippedUrl, { responseType: "blob" });
  });

  const settledFontFetches = await Promise.allSettled(fontFetches);

  const conversions = settledFontFetches
    .filter(({ status }) => status === "fulfilled")
    .map(async (response) => {
      const { data, config } = (response as PromiseFulfilledResult<AxiosResponse<Blob, unknown>>).value;
      const base64 = await convertFont(data);
      const { url } = config;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const originalUrl = urlLookup[url!] || url!;

      return {
        base64,
        url: originalUrl,
      };
    });

  return Promise.all(conversions);
};

// convert font sets up the `FileReader`, but wraps it in a `Promise`
// so we don't have to track completed conversions
const convertFont = async (data: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(Error("Could not convert font to base64"));
    };

    reader.readAsDataURL(data);
  });

const replaceUrls = (initialCss: string, conversions: FontConversion[]) =>
  conversions.reduce((css, { base64, url }) => css.replace(url, base64), initialCss);

const applySvgStyles = async (svgs: SVGSVGElement[]) => {
  const fontFaceCss = await convertFontsToBase64();

  if (svgs.length) {
    svgs.forEach((svgElement) => {
      const fontFaceTag = document.createElement("style");

      fontFaceTag.innerHTML = fontFaceCss;
      svgElement.prepend(fontFaceTag);
    });
  }
};

// This is the final culmination of figuring how to to utilize custom fonts in html2canvas
// Basically, we have to reference a font that can be re-downloaded via XHR as a binary, convert it to Base64, and inject it
// into a <style> tag that has to be embedded into the parent <svg> element that is being exported
export const handleOnClone = async (_document: Document, element: HTMLElement) => {
  // Note: There can be multiple svg fragments but we only care about the ones with no classes on them, which are the charts generated by Nivo
  const charts = [...element.querySelectorAll("svg")].filter((svg) => !svg.getAttribute("class"));

  await applySvgStyles(charts);
};

export const downloadAsPng = (element: HTMLElement, fileName: string) => {
  html2canvas(element, {
    onclone: handleOnClone,
  }).then((canvas) => {
    downloadImage({
      fileUrlData: canvas.toDataURL("image/png", 1),
      format: "png",
      fileName,
    });
  });
};
