import { useEffect, useState } from "react";
import { FormControl, InputAdornment, MenuItem, Select, SelectChangeEvent, Skeleton } from "@mui/material";
import { CalendarToday, ExpandMore } from "@mui/icons-material";
import { useFilterContext } from "../contexts/FilterProvider";
import { FILTER_DATE_FORMAT, updateDateRangeDate } from "./filters/FilterFunctions";
import { QuarterOption, useRetroStore } from "../store/retros/useRetroStore";
import dayjs from "dayjs";

export default function PortfolioQuarterFilter() {
  const { quarterOptions, quarterOptionsLoading, setQuarter, quarter, clearSummary } = useRetroStore();

  const { filterState, applyFilters } = useFilterContext();

  // const [selectedQuarter, setSelectedQuarter] = useState<string>("");

  const handleChange = (e: SelectChangeEvent): void => {
    const quarter = quarterOptions.find((_option) => _option.label == e.target.value);

    if (!quarter) {
      setQuarter("");
      return;
    }

    // This feels like a bit of a side effect, but right now its the simplest location for this to happen
    clearSummary();

    applyQuarterFilter(quarter);
  };

  const applyQuarterFilter = (quarter: QuarterOption) => {
    if (!filterState) return;

    // setSelectedQuarter(quarter.label);
    setQuarter(quarter.label);
    applyFilters(updateDateRangeDate(filterState, quarter.start, quarter.end));
  };

  // Handle updating local state if the search context updates
  useEffect(() => {
    if (filterState && quarterOptions) {
      const appliedQuarterOption = quarterOptions.find(
        (q) =>
          dayjs(q.start).format(FILTER_DATE_FORMAT) == filterState.dates.start &&
          dayjs(q.end).format(FILTER_DATE_FORMAT) == filterState.dates.end,
      );

      if (appliedQuarterOption) {
        // setSelectedQuarter(quarter.label);
        setQuarter(appliedQuarterOption.label);
      }
    }
  }, [filterState]);

  if (quarterOptionsLoading) {
    return <Skeleton height={40} variant="rounded" />;
  }

  return (
    <>
      <FormControl size="small" fullWidth>
        <Select
          labelId="app-dropdown-label"
          id="app-dropdown"
          variant="outlined"
          label="Saved "
          notched={false}
          value={quarter}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="start">
              <CalendarToday />
            </InputAdornment>
          }
          IconComponent={ExpandMore}
          MenuProps={{
            marginThreshold: 0,
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "transparent",
                color: "#13294B",
              },
            },
          }}
          style={{
            paddingLeft: "4px",
          }}
        >
          {quarterOptions.map((quarter) => (
            <MenuItem
              key={quarter.label}
              value={quarter.label}
              sx={{ justifyContent: "flex-start", color: "#13294B" }}
              onMouseDown={() => {}}
            >
              {quarter.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
