import { useState } from "react";
import dayjs from "dayjs";
import { Button, Popover, Typography as T, Box } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import { DateRangePicker, DefinedRange, DateRange } from "date-range-picker-mui";
import { updateDateRange } from "./FilterFunctions";
import { calendarDefaults } from "../../constants/calendarDefaults";
import { useFilterContext } from "../../contexts/FilterProvider";

const LONG_DATE_FORMAT = "DD MMM YYYY";
const FILTER_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_START_DATE = dayjs("2020-01-01 00:00");

type DynamicDateRange = {
  label?: string;
} & DateRange;

const generateDateRangeFromLabel = (label: string) => {
  const foundShortcut = calendarDefaults.find(({ label: shortcutLabel }) => shortcutLabel === label);
  return foundShortcut ?? undefined;
};

const DateRangeFilter = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { filterState, applyFilters } = useFilterContext();

  if (!filterState) return <div />;

  const { dates, dynamic_date_range } = filterState;

  const { start, end } = { start: dates.start ? dayjs(dates.start) : null, end: dates.end ? dayjs(dates.end) : null };

  // using Dayjs due to its ease of date math and parsing/stringifying dates;
  // we need to convert it to the native Date object to work.
  let initialDateRange: DefinedRange | undefined =
    !dynamic_date_range && start?.isValid() && end?.isValid()
      ? {
          startDate: start.toDate(),
          endDate: end.toDate(),
          label: "",
        }
      : dynamic_date_range
        ? generateDateRangeFromLabel(dynamic_date_range)
        : undefined;

  // Display the full applied date range (1/1/2020 -> Today) when no date filter is selected/applied
  if (initialDateRange === undefined) {
    initialDateRange = {
      startDate: DEFAULT_START_DATE.toDate(),
      endDate: dayjs().endOf("day").toDate(),
      label: "",
    };
  }

  const buttonText =
    start?.isValid() && end?.isValid()
      ? `${start.format(LONG_DATE_FORMAT)}${!start.isSame(end) ? ` — ${end.format(LONG_DATE_FORMAT)}` : ""}`
      : "January 2020 - Present";

  const handleChange = (range: DynamicDateRange) => {
    // convert back to Dayjs
    applyFilters(
      updateDateRange(
        filterState,
        dayjs(range.startDate).format(FILTER_DATE_FORMAT),
        dayjs(range.endDate).format(FILTER_DATE_FORMAT),
        range.label ?? null,
      ),
    );
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<CalendarToday />}
        sx={{
          padding: "5px 8px",
          color: start?.isValid() ? "black" : "grey.500",
          "&:hover": {
            color: start?.isValid() ? "black" : "grey.500",
          },
          "& .MuiButton-startIcon": {
            marginRight: "12px !important",
          },
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {buttonText}
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ marginBottom: 2, "& .MuiPaper-root": { boxShadow: "none" } }}>
          <DateRangePicker
            open={!!anchorEl}
            toggle={() => setAnchorEl(null)}
            initialDateRange={initialDateRange}
            definedRanges={calendarDefaults}
            onChange={handleChange}
            minDate={DEFAULT_START_DATE.toDate()}
            maxDate={dayjs().endOf("day").toDate()} // must be end of day to prevent errors on selecting "today"
          />
          <T textAlign="center" fontStyle="italic">
            Date range must be between {DEFAULT_START_DATE.format(LONG_DATE_FORMAT)} and today.
          </T>
        </Box>
      </Popover>
    </div>
  );
};

export default DateRangeFilter;
