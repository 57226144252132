/* eslint-disable @typescript-eslint/ban-types */
// TODO: 2023-06-12 Clarissa Verish
// is there a way to fix the Function type?
import { AnyAction } from "redux";

type Matchable<AC extends () => AnyAction> = AC & {
  type: ReturnType<AC>["type"];
  match: (action: AnyAction) => action is ReturnType<AC>;
};

export function withMatcher<AC extends () => AnyAction & { type: string }>(actionCreator: AC): Matchable<AC>;

export function withMatcher<
  AC extends (...args: any[]) => AnyAction & { type: string } // eslint-disable-line
>(actionCreator: AC): Matchable<AC>;

export function withMatcher(actionCreator: Function) {
  const type = actionCreator().type;
  return Object.assign(actionCreator, {
    type,
    match(action: AnyAction) {
      return action.type === type;
    },
  });
}

export interface ActionWithPayload<T, P> {
  type: T;
  payload: P;
}

export interface Action<T> {
  type: T;
}

export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;

export function createAction<T extends string>(type: T, payload?: null): Action<T>;

export function createAction<T extends string, P>(type: T, payload: P) {
  return { type, payload };
}
