import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  LabelDisplayedRowsArgs,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TablePagination,
  Tooltip,
  Typography as T,
} from "@mui/material";
import ChartCard from "../../../components/ChartCard";
import ViewToggle, { VIEW_CARDS, VIEW_GRID } from "../../../components/ViewToggle";
import { compactFormatter } from "../../../utils/numberFormatter";
import { useVerbatims } from "../../../contexts/VerbatimsProvider";
import VerbatimsGrid from "../../../components/charts/VerbatimsGrid";
import { SearchResults, Sort, SortDirEnum } from "../../../models/search";
import { GetTableColumnsFromSearchResult } from "../../../constants/verbatimTableColumns";
import useUserFeedback from "../../../contexts/useUserFeedback";
import VerbatimsCards from "../VerbatimsCard/VerbatimsCards";
import RelevantTextRecordsMenu from "./RelevantTextRecordsMenu";
import ColumnSelectModal from "./ColumnSelectModal";
import { VerbatimColumns } from "./state/VerbatimColumns";
import { useAtom } from "jotai";
import VerbatimFeedbackModal from "../VerbatimFeedbackModal";
import { FeedbackItems } from "../VerbatimsCard/FeedbackState";
import { AutoAwesomeOutlined } from "@mui/icons-material";
import {
  ExecSummaryDisableButtonAtom,
  ExecSummaryEnableTooltipAtom,
  ExecSummaryHasContentAtom,
  ExecSummaryLoadingAtom,
  TriggerExecSummaryAtom,
} from "./state/ExecutiveSummaryState";
import ExecutiveSummary from "./ExecutiveSummary";

const RelevantRecordsContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    <T fontWeight={900}>Text Summarization</T>
    Abstractive text summaries are created using generative AI. If a document is sufficiently long (approximately 100
    words), a small model attempts to create a short summary (approximately 5-50 words) using only the verbatim text. If
    a document is too short, no summary is shown.
  </Paper>
);

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

type SortOptionType = {
  label: string;
  value: Sort;
};

const SORT_BY_OPTIONS: SortOptionType[] = [
  { label: "Date (Asc)", value: { leibniz_filter_date: SortDirEnum.asc } },
  { label: "Date (Desc)", value: { leibniz_filter_date: SortDirEnum.desc } },
  { label: "Source (Asc)", value: { data_source: SortDirEnum.asc } },
  { label: "Source (Desc)", value: { data_source: SortDirEnum.desc } },
  { label: "Sentiment (Asc)", value: { sentiment: SortDirEnum.asc } },
  { label: "Sentiment (Desc)", value: { sentiment: SortDirEnum.desc } },
];

const getSortValueForDisplay = (sort: string): string => {
  const sortOption = SORT_BY_OPTIONS.find(({ value }) => JSON.stringify(value) === sort);
  return sortOption?.value ? JSON.stringify(sortOption?.value) : "";
};

const RelevantTextRecordsCard = (): JSX.Element => {
  const { records, numRecords, updateSort, sort, pageSize, setPageSize, start, setStart, isLoading } = useVerbatims();
  const [view, setView] = useState<string>(VIEW_CARDS);
  const cardRef = useRef<HTMLDivElement>(null);

  const [, setTriggerExecSummary] = useAtom(TriggerExecSummaryAtom);
  const [execSummaryLoading] = useAtom(ExecSummaryLoadingAtom);
  const [execSummaryEnableTooltip] = useAtom(ExecSummaryEnableTooltipAtom);
  const [execSummaryDisableButton] = useAtom(ExecSummaryDisableButtonAtom);
  const [execSummaryHasContent] = useAtom(ExecSummaryHasContentAtom);

  const { getAllUserFeedback } = useUserFeedback();
  const [, setUserFeedback] = useAtom(FeedbackItems);

  const { data: userFeedbackData } = getAllUserFeedback();
  useEffect(() => {
    setUserFeedback(userFeedbackData || []);
  }, [userFeedbackData]);

  // With the Grid and CSV Export dependant on multiple cross cutting variables, we are now using Jotai to manage state
  const [, setColumns] = useAtom(VerbatimColumns);
  useEffect(() => {
    setColumns(GetTableColumnsFromSearchResult(records));
  }, [records]);

  const sortValue = getSortValueForDisplay(JSON.stringify(sort));

  const scrollToTop = () => {
    if (cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onViewChange = (_event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView) {
      setView(newView);
      // reset sort on view change
      if (sort) {
        const currentSortKey = Object.keys(sort)[0] as keyof SearchResults;
        updateSort(currentSortKey, null);
      }
    }
  };

  const onSortChange = (e: SelectChangeEvent): void => {
    const { value } = e.target;
    const newSort: Sort = JSON.parse(value);
    const newSortKey = Object.keys(newSort)[0] as keyof SearchResults;
    const newSortDir = Object.values(newSort)[0];
    updateSort(newSortKey, newSortDir);
  };

  const onPageChange = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    // start is based on the item index for the first item in the next page,
    // e.g. page 2 for a page size of 100 has a start of 100 (page 1 is 0-99).
    setStart(page * pageSize);
    // Auto scroll back to the top of the card list
    scrollToTop();
  };
  // Reset back to page 1 when the user toggles between views
  useEffect(() => {
    setStart(0);
  }, [view]);

  const onPageSizeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(Number(e.target.value));
    scrollToTop();
  };

  return (
    <ChartCard
      title={["Relevant Text Records", ...(isLoading ? [] : [`${compactFormatter.format(numRecords ?? 0)} records`])]}
      tooltipContent={<RelevantRecordsContent />}
      backgroundColor={"white"}
      sx={{ padding: "18px 18px 0 18px" }}
      actions={
        <Box display="flex" flexDirection="row" ref={cardRef} gap="0.625rem">
          <Box display="grid" sx={{ placeItems: "center" }}>
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: {
                    color: "#25282A",
                    fontSize: "14px",
                    maxWidth: "16rem",
                    lineHeight: "1rem",
                    padding: "0.625rem",
                    backgroundColor: "white",
                    filter:
                      "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.2)) drop-shadow(0px 1px 3px rgba(50, 50, 71, 0.1))",
                  },
                },
                arrow: {
                  sx: { color: "white" },
                },
              }}
              placement="top"
              title={
                execSummaryEnableTooltip
                  ? "Perform a filter or search that returns between 1 and 500 records to enable AI summarization"
                  : ""
              }
              arrow
            >
              {/* `span` allows tooltip to appear on disabled button */}
              <span style={{ gridArea: "1/1" }}>
                <Button
                  onClick={() => {
                    setTriggerExecSummary((i) => ++i);
                  }}
                  disabled={execSummaryDisableButton}
                  sx={{
                    minWidth: "max-content",
                    px: "1rem",
                    color: "#003087",
                    visibility: execSummaryHasContent ? "hidden" : undefined,
                  }}
                  startIcon={<AutoAwesomeOutlined />}
                >
                  Generate summary
                </Button>
              </span>
            </Tooltip>
            {execSummaryLoading && (
              <CircularProgress size="small" sx={{ gridArea: "1/1", width: "1.25rem", height: "1.25rem" }} />
            )}
          </Box>
          {view === VIEW_CARDS && (
            <FormControl size="small" fullWidth>
              {!sort && (
                <InputLabel id="sortby-dropdown-label" shrink={false}>
                  Sort by
                </InputLabel>
              )}
              <Select
                labelId="sortby-dropdown-label"
                id="sortby-dropdown"
                label="SortBy"
                notched={false}
                value={sortValue}
                onChange={onSortChange}
                sx={{ minWidth: "105px", size: "14px", bgcolor: "white" }}
              >
                {SORT_BY_OPTIONS.map(({ value, label }) => (
                  <MenuItem key={label} value={JSON.stringify(value)}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box>
            <ViewToggle selectedView={view} handleViewChange={onViewChange} />
          </Box>
          <RelevantTextRecordsMenu />
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" gap="1.5rem">
        <ExecutiveSummary />
        <Box sx={{ height: "calc(100vh - 167px)", overflowY: "scroll", padding: "2px" }}>
          {view === VIEW_CARDS ? <VerbatimsCards sort={sort} /> : <VerbatimsGrid />}
          {numRecords && !isLoading && view === VIEW_GRID ? (
            <TablePagination
              component="div"
              count={numRecords}
              page={Math.floor(start / pageSize)}
              showFirstButton
              onPageChange={onPageChange}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[25, 50, 100]}
              onRowsPerPageChange={onPageSizeChange}
              labelDisplayedRows={({ from, to, count }: LabelDisplayedRowsArgs) =>
                `${from}—${to} of ${compactFormatter.format(count)} records`
              }
            />
          ) : null}
        </Box>
      </Box>
      <ColumnSelectModal />
      <VerbatimFeedbackModal />
    </ChartCard>
  );
};

export default RelevantTextRecordsCard;
