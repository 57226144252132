import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../components/Authorization/useUserContext";
import Roles from "../components/Authorization/Roles";
import { useCustomTabs } from "../api/customTabs";
import { ArticleOutlined, AutoAwesomeOutlined, Dashboard, SvgIconComponent } from "@mui/icons-material";
import { MenuItemType } from "../utils/models";

export const useTopBarStore = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUserContext();
  const isMedOrCom = user.role?.some((_role) => [Roles.MEDICAL, Roles.COMMERCIAL].includes(_role));
  const isUSLT = user.role?.includes(Roles.USLT_RETRO);
  const userRole = user.role;
  const { customTabs, isLoading } = useCustomTabs();

  const menus: MenuItemType[] = [
    {
      label: "Chat",
      link: "/deepchat",
      startIcon: AutoAwesomeOutlined,
    },
    {
      label: "Explore",
      link: "/explorer",
      startIcon: ArticleOutlined,
    },
  ];

  return {
    location,
    navigate,
    isMedOrCom,
    isUSLT,
    userRole,
    customTabs,
    isLoading,
    menus,
  };
};
