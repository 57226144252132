import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getSentiment } from "../../../api/dataSources";
import { defaultFilterState } from "../../../constants/defaultFilterState";
import { selectAuth } from "../../../store/authorization/auth.selector";
import { SearchFilters } from "../../../models/search";

export default function useGetSentimentOverTimeData(filterState: SearchFilters) {
  const jwt = useSelector(selectAuth);

  return useQuery({
    queryKey: ["sentimentOverTime_response", JSON.stringify(filterState)],
    queryFn: () => getSentiment(filterState || defaultFilterState, jwt),
    enabled: !!filterState,
  });
}
