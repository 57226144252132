import { GridColDef } from "@mui/x-data-grid";
import { Typography as T } from "@mui/material";
import dayjs from "dayjs";
import { DataDistribution } from "../../models/datasource";
import { StyledDataGrid } from "../../components/common";
import { compactFormatter } from "../../utils/numberFormatter";

const DateCell = ({ date }: { date: string }): JSX.Element => {
  const numDays = dayjs().diff(dayjs(date), "day");
  return (
    <div>
      <T display="inline" fontSize={14}>{`${date} · `}</T>
      <T display="inline" sx={{ color: "text.disabled" }} fontSize={14}>
        {numDays} days ago
      </T>
    </div>
  );
};

type DataRefreshTableProps = {
  data: DataDistribution[];
};

const DataRefreshTable = ({ data }: DataRefreshTableProps): JSX.Element => {
  const columns: GridColDef<DataDistribution>[] = [
    {
      field: "name",
      headerName: "Source",
      flex: 1,
      minWidth: 280,
      renderCell: (params) => (
        <T fontWeight={600} fontSize={14}>
          {params.row.name}
        </T>
      ),
    },
    {
      field: "record_count",
      headerName: "Records",
      flex: 0.5,
      renderCell: (params) => compactFormatter.format(params.row.record_count),
    },
    {
      field: "oasis_refresh_date",
      headerName: "Oasis Refresh",
      flex: 1,
      renderCell: (params) => <DateCell date={params.row.oasis_refresh_date} />,
    },
    {
      field: "leibniz_refresh_date",
      headerName: "Deepsense Refresh",
      flex: 1,
      renderCell: (params) => <DateCell date={params.row.leibniz_refresh_date} />,
    },
  ];

  // have to augment the data rows with an ID value; for now, name of datasource.
  return (
    <StyledDataGrid
      autoHeight
      rows={data.map((d) => ({ ...d, id: d.name }))}
      columns={columns}
      hideFooter
      disableColumnMenu
    />
  );
};

export default DataRefreshTable;
