import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import { EditOutlined, FavoriteBorderOutlined, Check } from "@mui/icons-material";
import { SavedSearch } from "../../models/savedSearch";
import IconFilter from "../../icons/IconFilter";

const StyledPaper = styled(Paper)(() => ({
  "& .MuiAutocomplete-listbox": {
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: "transparent",
      "&.Mui-focused": {
        backgroundColor: "transparent",
        color: "#13294B",
      },
    },
  },
  "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
    backgroundColor: "transparent",
    color: "#13294B",
  },
}));

interface AutocompleteSearchesSelectPropsI extends AutocompleteProps<SavedSearch, false, false, false> {
  editFilterMode?: boolean;
  onEditListClick?: () => void;
  onSaveFilterClick?: () => void;
  onSaveDisabled?: boolean;
  onEditListDisabled?: boolean;
}

type AutocompleteSearchesSelectProp = Omit<AutocompleteSearchesSelectPropsI, "renderInput">;

function AutocompleteSearchesSelect(props: AutocompleteSearchesSelectProp) {
  const { editFilterMode, onEditListClick, onSaveFilterClick, onSaveDisabled, onEditListDisabled, ...restProps } =
    props;
  return (
    <Autocomplete
      size="small"
      freeSolo={false}
      disablePortal
      noOptionsText="No saved filters"
      getOptionLabel={(option) => option.title}
      {...restProps}
      sx={{
        ...restProps.sx,
        "& .MuiInputBase-input": {
          paddingLeft: "0px !important",
        },
      }}
      // Purposely spread props above to avoid overwriting below
      PaperComponent={({ children }) => {
        return (
          <StyledPaper>
            {children}
            {editFilterMode && (
              <>
                <Divider />
                <MenuItem
                  sx={{ justifyContent: "flex-start", color: "#13294B" }}
                  onMouseDown={onSaveFilterClick}
                  disabled={onSaveDisabled}
                >
                  <FavoriteBorderOutlined fontSize={"small"} sx={{ mr: 2 }} /> Save as ...
                </MenuItem>
                <MenuItem
                  sx={{ justifyContent: "flex-start", color: "#13294B", paddingBottom: 1 }}
                  onMouseDown={onEditListClick}
                  disabled={onEditListDisabled}
                >
                  <EditOutlined fontSize={"small"} sx={{ mr: 2 }} /> Manage
                </MenuItem>
              </>
            )}
          </StyledPaper>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          variant="outlined"
          placeholder={`Search Saved Filters`}
          InputProps={{
            // make sure to forward param input props
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ "& svg": { fontSize: "20px" } }}>
                <IconFilter />
              </InputAdornment>
            ),
            sx: { color: "#13294B", fontWeight: 600, "& ::placeholder": { fontWeight: 400 } },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Box
            component={Check}
            sx={{ width: 17, height: 17, mr: "5px", ml: "-2px", color: "#13294B" }}
            style={{
              visibility: selected ? "visible" : "hidden",
            }}
          />
          <Box sx={{ color: selected ? "#13294B" : "inherit" }}>{option.title}</Box>
        </li>
      )}
    />
  );
}

export default AutocompleteSearchesSelect;
