import { Paper, Stack, Box, Typography } from "@mui/material";
import { SliceTooltipProps, Point } from "@nivo/line";
import { useMousePosition, useWindowDimensions } from "../../../components/hooks";
import { compactFormatter } from "../../../utils/numberFormatter";

const TOOL_TIP_WIDTH = 400;

const SliceToolTip = ({
  slice,
  formatLabel,
}: SliceTooltipProps & { formatLabel?: (point: Point) => React.ReactNode }) => {
  const mousePosition = useMousePosition();
  const windowDimensions = useWindowDimensions();
  const { x: mouseX } = mousePosition;
  const { width } = windowDimensions;

  const { points } = slice;

  // Sort the points to show most frequent to least frequent on the tool tip
  const sortedPoints = [...points].sort((a, b) => (a.data.y > b.data.y ? -1 : 1));

  if (!mouseX) return null;

  let leftPos = 0;
  if (mouseX + TOOL_TIP_WIDTH > width) {
    const diff = mouseX + (TOOL_TIP_WIDTH + 10) - width;
    leftPos = -diff;
  }

  return (
    <Paper
      style={{
        position: "absolute",
        backgroundColor: "white",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        minWidth: TOOL_TIP_WIDTH,
        left: leftPos,
      }}
    >
      {sortedPoints.map((point: Point, i) => (
        <Stack
          key={i}
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ marginY: "5px" }}
        >
          <Stack direction="row">
            <Box width={16}>
              <Box
                style={{
                  backgroundColor: point.serieColor,
                  width: 16,
                  height: 16,
                  marginTop: 6,
                  display: "flex",
                }}
              />
            </Box>
            <Typography paddingX={2}>{formatLabel ? formatLabel(point) : point.serieId}</Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: "600" }}>{compactFormatter.format(Number(point.data.y))}</Typography>
          </Stack>
        </Stack>
      ))}
    </Paper>
  );
};

export default SliceToolTip;
