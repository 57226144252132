import { FilterProvider } from "./FilterProvider";
import SearchIdProvider from "./SearchIdProvider";

export default function AppContextWrapper({ children }: { children: JSX.Element }) {
  return (
    <SearchIdProvider>
      <FilterProvider>{children}</FilterProvider>
    </SearchIdProvider>
  );
}
