import { Box } from "@mui/material";
import { ChartGrid, FilterDrawer } from "../components";
import { SearchBar } from "../components/filters";
import { TOP_BAR_HEIGHT } from "./AppLayout";
import { useSearchStore } from "../store/useSearchStore";

export default function HomePage() {
  const { applyDefaultSearchState } = useSearchStore();
  applyDefaultSearchState();

  return (
    <>
      <FilterDrawer topPadding={50} />
      <Box component="main" sx={{ p: 0, flexGrow: 1, paddingTop: `${TOP_BAR_HEIGHT}px` }}>
        <div style={{ padding: "24px" }}>
          <SearchBar />
          <ChartGrid />
        </div>
      </Box>
    </>
  );
}
