import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getPortfolioQuarters, getPortfolioSummary } from "../api/uslt";
import { selectAuth } from "../store/authorization/auth.selector";

export default function useUsltData() {
  const jwt = useSelector(selectAuth);

  const PortfolioQuarters = useQuery({
    queryFn: () => getPortfolioQuarters(jwt),
    queryKey: ["usltPortfolioQuarters"],
  });

  const getPortfolioSummaryText = async (quarterKey: string) => {
    return await getPortfolioSummary(jwt, quarterKey);
  };

  return {
    PortfolioQuarters,
    getPortfolioSummaryText,
  };
}
