export type AppEnv = "local" | "dev" | "qa" | "prod" | "all";

export interface FeatureFlagConfig {
  feature: string;
  env: AppEnv[];
}

export const Feature = {
  MedInfo: "medinfo",
  CustomTabs: "customtabs",
  Retros: "retros",
};

export const featureFlagConfig: FeatureFlagConfig[] = [
  { feature: Feature.MedInfo, env: ["local"] },
  { feature: Feature.CustomTabs, env: ["all"] },
  { feature: Feature.Retros, env: ["all"] },
];
