import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { type PropsWithChildren } from "react";
import { useLocalStorage } from "usehooks-ts";
import { CalloutProps } from "./models";

const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

export const useCallout = (text: string) => {
  // To reset: js-console -> Application -> Local storage -> [domain] -> right-click/delete on key
  // To reset programmatically see https://usehooks-ts.com/react-hook/use-local-storage
  const [closed, setClosed] = useLocalStorage(`callout[${text}]`, false);
  const closeCallout = () => setClosed(true);

  const Callout = ({ children, x, y, axis, placement }: PropsWithChildren<CalloutProps>) => {
    const BACKGROUND_COLOR = "#AF1685";
    const transform = `translate${axis}(${
      (axis === "x" && x === "left") || (axis === "y" && y === "top") ? "-" : ""
    }50%)`;

    return (
      <Box position="relative">
        {children}
        {!closed && (
          <Box
            display="flex"
            width="19rem"
            position="absolute"
            bgcolor={BACKGROUND_COLOR}
            borderRadius="0.25rem"
            paddingY="1rem"
            paddingX="1.25rem"
            color="white"
            fontSize="0.875rem"
            lineHeight="1.25rem"
            gap="1.25rem"
            alignItems="start"
            sx={{
              [`margin${capitalize(axis === "y" ? y : x)}`]: "2rem",
              ...placement,
              "&::before": {
                border: "solid transparent 2rem",
                content: '""',
                position: "absolute",
                [`border${capitalize(axis === "y" ? x : y)}Color`]: BACKGROUND_COLOR,
                [x]: 0,
                [y]: 0,
                transform,
              },
            }}
          >
            <Box zIndex="1" textAlign="initial">
              {text}
            </Box>
            <IconButton onClick={() => setClosed(true)} sx={{ margin: "-0.5rem", color: "white" }}>
              <Close />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };
  return [Callout, closeCallout] as const;
};
