import { AppEnv, featureFlagConfig } from "./featureFlagConfig";

export interface FeatureFlagState {
  enabled: boolean;
}

export default function useFeatureFlag(flagName: string): FeatureFlagState {
  const app_env = (import.meta.env.VITE_APP_APP_ENV ?? "local") as AppEnv;

  const feature = featureFlagConfig.find((_config) => _config.feature === flagName);

  if (feature === undefined) {
    console.warn(`Feature flag "${flagName}" not found in config`);
  }

  const featureEnvironments = feature?.env ?? [];

  return { enabled: featureEnvironments.some((_env) => [app_env, "all"].includes(_env)) };
}
