import { forwardRef, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useDebounce, useWindowSize } from "usehooks-ts";
import { Box, Typography as Text } from "@mui/material";
import { getFilter, toggleFilterValues } from "../../../components/filters/FilterFunctions";
import { compactFormatter } from "../../../utils/numberFormatter";
import { useFilterContext } from "../../../contexts/FilterProvider";
import useFilterOptions from "../../../contexts/useFilterOptions";
import StyledToolTip from "../../../components/StyledToolTip";

import useGetEcosystemData from "./useGetEcosystemData";
import useKmlFeatures from "./useKmlFeatures";
import Choropleth, { Datum } from "./Choropleth";
import useGetRegionData from "./useGetRegionData";
import { unstable_batchedUpdates } from "react-dom";
import { SearchFilters } from "../../../models/search";
import { defaultFilterState } from "../../../constants/defaultFilterState";

export interface EcosystemMapProps {
  data?: unknown;
  isFullscreen: boolean;
  mapMode: "ecosystem_name" | "region";
}

export interface GeoFeatureData extends Datum {
  selected?: boolean;
}

const ToolTipContents = ({ featureData, mode }: { featureData?: GeoFeatureData; mode: string }) => {
  const modeLabel = mode == "ecosystem_name" ? "Ecosystem" : "Region";
  if (!featureData)
    return (
      <>
        <Text>No {modeLabel.toLowerCase()} data</Text>
      </>
    );

  const frequency: number = featureData?.rate || 0;
  return (
    <>
      <Text>
        <b>{modeLabel}:</b> {featureData.id}
      </Text>
      <Text>
        <b>Records:</b> {compactFormatter.format(frequency)}
      </Text>
    </>
  );
};

const EcosystemMap = forwardRef(({ isFullscreen, mapMode: filterKey }: EcosystemMapProps, ref) => {
  const size = useDebounce(useWindowSize(), 500);
  const element = useRef<HTMLDivElement>(null);

  const [hoveredGeoFeature, setHoveredGeoFeature] = useState<GeoFeatureData | undefined>();
  const [showGeoTooltip, setShowGeoTooltip] = useState<boolean>(false);

  const { data: filterOptions } = useFilterOptions();
  const { filterState, applyFilters } = useFilterContext();

  const { data: geoFeatureData, isLoading } = (filterKey == "ecosystem_name" ? useGetEcosystemData : useGetRegionData)(
    filterState || defaultFilterState,
  );

  const kmlSourceUri =
    filterKey == "ecosystem_name"
      ? "/ChartData/z2_ecosystem_shape.kml"
      : "/ChartData/z2_ecosystem__commercial_region_shape.kml";
  const { data: featureCollection } = useKmlFeatures(filterKey, kmlSourceUri);

  const renderMap = (filterState: SearchFilters, geoFeatureData: GeoFeatureData[], width: number | null) => {
    if (!element.current || !featureCollection) return;

    const svg = Choropleth<GeoFeatureData>({
      featureCollection: featureCollection,
      data: geoFeatureData,
      width,
      onClickGeo: (elm, event, feature) => {
        const filter_value = feature?.properties?.description;
        if (filter_value && filterOptions) {
          applyFilters(toggleFilterValues(filterState, filterOptions, filterKey, [filter_value]));
        }
      },
      onMouseEnterGeo: (elm, event, feature, data) => {
        unstable_batchedUpdates(() => {
          setHoveredGeoFeature(data);
          setShowGeoTooltip(true);
        });
      },
      onMouseExitGeo: () => {
        unstable_batchedUpdates(() => {
          setHoveredGeoFeature(undefined);
          setShowGeoTooltip(false);
        });
      },
    });

    if (svg) {
      d3.select(element.current).html("");
      element.current.appendChild(svg);
    }
  };

  useEffect(() => {
    if (!filterState) return;

    // We are deliberately using windowSize() as an event trigger, but the actual element's dimensions to calculate if the chart should be showing the "large" vs the "small" chart Legend
    renderMap(filterState, geoFeatureData, element.current?.clientWidth || 0);
  }, [geoFeatureData, filterState, filterOptions, size, isFullscreen]);

  if (!filterState) return <></>;

  const appliedFilters = getFilter(filterState, filterKey);

  return (
    <Box ref={ref} className="EcosystemMap">
      <StyledToolTip
        title={<ToolTipContents featureData={hoveredGeoFeature} mode={filterKey} />}
        open={showGeoTooltip}
        followCursor
        disableFocusListener
      >
        <Box
          ref={element}
          className={["Choropleth", appliedFilters.length ? "has-selection" : "", isLoading ? "loading" : ""]
            .filter((c) => !!c)
            .join(" ")}
          sx={{
            width: "100%",
            aspectRatio: "16/9",

            "@keyframes map-pulse": {
              "0%": {
                opacity: 1.0,
              },
              "50%": {
                opacity: 0.6,
              },
              "100%": {
                opacity: 1.0,
              },
            },

            "&.loading": {
              "& path": {
                fill: "#eeeeee",
                animation: "2s ease-in-out 0.5s infinite normal none running map-pulse",
              },
            },

            "&.has-selection": {
              "& .ecosystem": {
                filter: "saturate(0.1) opacity(0.5)",
              },
            },

            "& .ecosystem": {
              "&.selected": {
                filter: "saturate(1.0)",
              },
              "&:hover": {
                filter: "saturate(1.0) opacity(0.8)",
                cursor: "pointer",
              },
              "&.selected:hover": {
                filter: "saturate(1.0) opacity(0.8)",
                cursor: "pointer",
              },
            },
          }}
        />
      </StyledToolTip>
    </Box>
  );
});

export default EcosystemMap;
