import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const SEARCH_ID_PARAM_KEY = "search_id";

export const SearchIdContext = createContext<SearchIdContextType | null>(null);

export type SearchIdContextType = {
  searchId: string | null;
  setSearchId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const useSearchId = (): SearchIdContextType => {
  const context = useContext(SearchIdContext);
  if (!context) {
    throw new Error("useSearchId must be used within SearchIdProvider.");
  }
  return context;
};

/**
 * Provides the current SearchID to the application and synchronizes it with the application URL "search params"
 * eg: /?search_id=<current_search_id>
 */
export default function SearchIdProvider({ children }: { children: JSX.Element }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialSearchId = searchParams.get(SEARCH_ID_PARAM_KEY);
  const [searchId, setSearchId] = useState<string | null>(initialSearchId);

  useEffect(() => {
    if (searchId) {
      searchParams.set(SEARCH_ID_PARAM_KEY, searchId);
    } else {
      searchParams.delete(SEARCH_ID_PARAM_KEY);
    }
    setSearchParams(searchParams, { replace: true });
  }, [searchId]);

  return <SearchIdContext.Provider value={{ searchId, setSearchId }}>{children}</SearchIdContext.Provider>;
}
