import { createElement, MouseEvent, useCallback, useMemo } from "react";
import { BarDatum, BarItemProps } from "@nivo/bar";
import { animated, to } from "@react-spring/web";
import { useTheme } from "@nivo/core";
import { useTooltip } from "@nivo/tooltip";

const RoundedBarComponent = <RawDatum extends BarDatum>({
  bar: { data, ...bar },

  style: { borderColor, color, height, labelColor, labelOpacity, labelX, labelY, width },

  borderWidth,

  label,
  shouldRenderLabel,

  isInteractive,
  onClick,
  onMouseEnter,
  onMouseLeave,

  tooltip,

  isFocusable,
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
}: BarItemProps<RawDatum>) => {
  const theme = useTheme();

  const { showTooltipFromEvent, showTooltipAt, hideTooltip } = useTooltip();

  const renderTooltip = useMemo(() => () => createElement(tooltip, { ...bar, ...data }), [tooltip, bar, data]);

  const handleClick = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onClick?.({ color: bar.color, ...data }, event);
    },
    [bar, data, onClick],
  );
  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip],
  );
  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(data, event);
      showTooltipFromEvent(renderTooltip(), event);
    },
    [data, onMouseEnter, showTooltipFromEvent, renderTooltip],
  );
  const handleMouseLeave = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(data, event);
      hideTooltip();
    },
    [data, hideTooltip, onMouseLeave],
  );

  // extra handlers to allow keyboard navigation
  const handleFocus = useCallback(() => {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY]);
  }, [showTooltipAt, renderTooltip, bar]);
  const handleBlur = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  const barThickness = bar.height;
  const radius = barThickness / 2;
  // The "first positioned" bar does not need an offset
  const offset = bar.x === 0 ? 0 : barThickness;

  return (
    <animated.g
      // Unfortunately svg does not have the concept of z-index so we have to physically change the element order (which we don't have access to in Nivo) to get the desired result
      // @ts-expect-error - zpos is a made up property so that we can determine the physical order of the individual bar segments such that they overlap the way they are shown in the figma
      zpos={bar.index}
      className={"bar-group"}
      transform={`translate(${bar.x - offset}, ${bar.y})`}
    >
      <animated.rect
        width={to(width, (value) => Math.max(value, 0) + offset)}
        height={to(height, (value) => Math.max(value, 0))}
        rx={radius}
        fill={data.fill ?? color}
        strokeWidth={borderWidth}
        stroke={borderColor}
        focusable={isFocusable}
        tabIndex={isFocusable ? 0 : undefined}
        aria-label={ariaLabel ? ariaLabel(data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
        aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseMove={isInteractive ? handleTooltip : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onClick={isInteractive ? handleClick : undefined}
        onFocus={isInteractive && isFocusable ? handleFocus : undefined}
        onBlur={isInteractive && isFocusable ? handleBlur : undefined}
      />
      {shouldRenderLabel && (
        <animated.text
          x={labelX}
          y={labelY}
          textAnchor="middle"
          dominantBaseline="central"
          fillOpacity={labelOpacity}
          style={{
            ...theme.labels.text,
            pointerEvents: "none",
            fill: labelColor,
          }}
        >
          {label}
        </animated.text>
      )}
    </animated.g>
  );
};

export default RoundedBarComponent;
