import { createContext, useContext } from "react";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import { WebSocketChatResponse } from "./useDeepchat";
import { ReadyState } from "react-use-websocket";

export interface DeepChatSocketContextProps {
  lastJsonMessage: WebSocketChatResponse<unknown>;
  sendJsonMessage: SendJsonMessage;
  readyState: ReadyState;
}

export const DeepChatSocketContext = createContext<DeepChatSocketContextProps | undefined>(undefined);

export const useDeepChatSocketContext = () => {
  const context = useContext(DeepChatSocketContext);
  if (!context) {
    throw new Error("useDeepChatSocketContext must be used within DeepChatSocketContext.");
  }
  return context;
};
