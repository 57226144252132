import axios, { AxiosResponse } from "axios";
import { JWT } from "../store/authorization/auth.types";
import { TPortfolioSummary } from "../components/Deepchat/models";

const API_URI = import.meta.env.VITE_APP_API_URI;

const buildHeaders = (jwt: JWT) => ({
  "Content-Type": "application/json",
  Authorization: `${jwt.token}`,
  "Selected-Role": `${jwt.role?.at(0) ?? ""}`,
});

export const getPortfolioQuarters = async (jwt: JWT) => {
  const response: AxiosResponse<string[]> = await axios.get(`${API_URI}/chat/portfolio-summary/get-quarters`, {
    headers: buildHeaders(jwt),
  });
  return response.data;
};

export const getPortfolioSummary = async (jwt: JWT, quarterKey: string) => {
  const response: AxiosResponse<TPortfolioSummary> = await axios.get(
    `${API_URI}/chat/portfolio-summary/${quarterKey}`,
    {
      headers: buildHeaders(jwt),
    },
  );
  return response.data;
};
