import * as d3 from "d3";
import cloud from "d3-cloud";
import { BoxProps, Box, Typography as Text } from "@mui/material";
import { useRef, useState, useEffect, useMemo } from "react";
import { compactFormatter } from "../../../utils/numberFormatter";
import { Keyword } from "../../../models/datasource";
import StyledToolTip from "../../../components/StyledToolTip";

const MIN_FONT = 40;
const MAX_FONT = 100;

const KeywordTipContents = ({ word }: { word?: cloud.Word }) => {
  if (!word) return <></>;

  // @ts-expect-error - Passing "frequency" through the code that does not support TS generics
  const frequency: number = word?.frequency || 0;

  return (
    <>
      <Text>
        <b>Keyword:</b> {word?.text}
      </Text>
      <Text>
        <b>Frequency:</b> {compactFormatter.format(frequency)}
      </Text>
    </>
  );
};

interface KeywordViewCloudProps {
  keywords: Keyword[];
  selectedWords: string[];
  toggleKeyword: (keyword: string) => void;
}

const KeywordViewCloud = ({ keywords, selectedWords, toggleKeyword, ...props }: KeywordViewCloudProps & BoxProps) => {
  const element = useRef<HTMLDivElement>(null);
  const [words, setWords] = useState<cloud.Word[]>([]);
  const [hoveredWord, setHoveredWord] = useState<cloud.Word | undefined>();

  const colors = ["#003087", "#0155B7", "#0074E0", "#00A3E0"];
  const highlight = "#0B41CD";

  const draw = (words: cloud.Word[]) => {
    d3.select(element.current)
      .html("")
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", `0 0 ${layout.size()[0]} ${layout.size()[1]}`)
      .attr("width", layout.size()[0])
      .selectAll("text")
      .data(words)
      .enter()
      .append("text")
      .style("font-size", (d) => d.size + "px")
      .style("text-decoration", (d) => (selectedWords.includes(d.text || "") ? "underline" : "none"))
      .style("fill", (d) => {
        return selectedWords.includes(d.text || "")
          ? highlight
          : // @ts-expect-error Because D3
            d.color;
      })
      .attr("text-anchor", "middle")
      .attr("cursor", "pointer")
      .attr("transform", function (d) {
        // @ts-expect-error Because D3
        return "translate(" + [d.x + layout.size()[0] / 2, d.y + layout.size()[1] / 2] + ")";
      })
      .text((d) => d.text || "")
      .on("click", (event, d) => {
        const _clickedItemText = d.text || "";
        toggleKeyword(_clickedItemText);
      })
      .on("mouseenter", (event, d) => {
        setHoveredWord(d);
      })
      .on("mouseleave", () => {
        setHoveredWord(undefined);
      });
  };

  const getKeywordMinFrequency = (keywords: Keyword[]) => {
    return keywords?.length > 0
      ? keywords.reduce((prev: Keyword, current: Keyword) => {
          return prev && prev.frequency < current.frequency ? prev : current;
        }).frequency
      : 0;
  };

  const getKeywordMaxFrequency = (keywords: Keyword[]) => {
    return keywords?.length > 0
      ? keywords.reduce((prev: Keyword, current: Keyword) => {
          return prev && prev.frequency > current.frequency ? prev : current;
        }).frequency
      : 0;
  };

  const mapWordsWithScale = (keywords: Keyword[]) => {
    const min = getKeywordMinFrequency(keywords);
    const max = getKeywordMaxFrequency(keywords);

    const FONT_SCALE = MAX_FONT / (max - min);

    const scaleFont = (count: number) => {
      return MIN_FONT + FONT_SCALE * (count - min);
    };

    // The number of words that should be in each color group when broken into a series of even size groups
    const ntile = keywords.length / colors.length;

    const response = keywords.map((word, index) => {
      const colorIndex = colors.length - Math.ceil((keywords.length - index) / ntile);
      return {
        index,
        text: word.keyword,
        size: scaleFont(word.frequency),
        color: colors[colorIndex],
        frequency: word.frequency,
      };
    });

    return response;
  };

  const layout = cloud()
    .size([1600, 1100])
    .words(mapWordsWithScale(keywords))
    .padding(8)
    .rotate(0)
    .font("Gene-Sans-Regular")
    .fontSize((d) => d.size || MIN_FONT);

  // Redraw the cloud whenever there the word selection changes, or we get a new set of words
  useEffect(() => {
    draw(words);
  }, [words, selectedWords]);

  // Generate and cache the initial word cloud geometry since it's expensive
  const generateCloud = useMemo(async () => {
    return await new Promise<cloud.Word[]>((resolve) => {
      layout.on("end", resolve).start();
    });
  }, [keywords]);

  useEffect(() => {
    generateCloud.then((words) => {
      setWords([...words]);
    });
    // Regenerate set words whenever the keywords prop updates
  }, [keywords]);

  return (
    <StyledToolTip
      title={<KeywordTipContents word={hoveredWord} />}
      open={!!hoveredWord}
      followCursor
      disableFocusListener
    >
      <Box
        {...props}
        ref={element}
        sx={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          minWidth: 300,

          "& svg": {
            width: "100%",
            aspectRatio: "16/11",
            overflow: "visible",
          },
          ...props.sx,
        }}
      />
    </StyledToolTip>
  );
};

export default KeywordViewCloud;
