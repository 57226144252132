import dayjs, { Dayjs } from "dayjs";
import { AxisTickProps } from "@nivo/axes";
import { compactFormatter } from "../../utils/numberFormatter";
import { Box, Collapse, IconButton, Badge, Chip, styled } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

// Simple hash function to consistently turn a string into a number so that we can use it to pick a color
export const hashToInt32 = (str: string) => [...str].reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

export const FrequencyLabel = (props: AxisTickProps<number>) => (
  <g transform={`translate(${0 - 35},${props.y + 4})`}>
    <text style={{ fill: "#615E83", fontSize: 14 }}>{compactFormatter.format(Number(props.value))}</text>
  </g>
);

export const MonthLabel = (props: AxisTickProps<number>, tickValues: string) => {
  // Format the date depending on our tick value bucket
  const isWeek = tickValues.includes("week");
  const isDay = tickValues.includes("day");
  const format = isWeek || isDay ? "MM-DD-YY" : "MMM YY";
  // Ensure we add the UTC offset to our display date to avoid previous months being displayed:
  // E.g. "Jan 1st 2020" showing up as "Dec 31 2019"
  const date = new Date(props.value);
  const utcOffset = date.getTimezoneOffset();

  return (
    <g transform={`translate(${props.x - 40},${isWeek ? props.y + 30 : props.y + 40}) rotate(30 50 50)`}>
      <text style={{ fill: "#615E83", fontSize: 12 }}>
        {dayjs(props.value).add(utcOffset, "minutes").format(format)}
      </text>
    </g>
  );
};

export const ResolveTickValues = (min: Dayjs, max: Dayjs) => {
  const dateMonthDelta = Math.abs(max.diff(min, "month")) + 1;
  const dateDayDelta = Math.abs(max.diff(min, "day")) + 1;

  if (dateMonthDelta >= 36) {
    // Show ticks by year if the difference is 3 years or greater
    return "every year";
  } else if (dateMonthDelta >= 24) {
    // Show ticks by six months if the difference is 2 years or greater
    return "every 6 months";
  } else if (dateMonthDelta >= 16) {
    // Show ticks by quarter if the difference is 16 months or greater
    return "every 3 months";
  } else if (dateMonthDelta >= 12) {
    // Show ticks every other month if difference is 1 year or greater
    return "every 2 months";
  } else if (dateMonthDelta >= 3) {
    // Show ticks by month if there is at least a 3 month difference
    return "every month";
  } else if (dateMonthDelta >= 2) {
    // Show ticks by every other week if there is 2 months or greater difference
    return "every 2 weeks";
  } else if (dateMonthDelta >= 1 || dateDayDelta >= 28) {
    // Show ticks weekly if there is at least a month (min 28 day) difference
    return "every week";
    // Show every other day if there is at least a three week gap
  } else if (dateDayDelta >= 21) {
    return "every 2 days";
  } else {
    // Anything less should be daily
    return "every day";
  }
};

export const StyledChip = styled(Chip)(() => ({
  paddingLeft: "2px",
  paddingRight: "2px",
  marginRight: "10px",
  border: "0.7px solid #E5E5EF",
  borderRadius: "6px",
  margin: 2,
  "& .MuiChip-label": {
    fontWeight: 400,
    fontSize: 14,
  },
}));

export interface ExpandableTabProps {
  tabExpanded: boolean;
  hasSelection: boolean;
  toggleExpanded: (isExpanded: boolean) => void;
  children?: React.ReactNode;
}

export const ExpandableTab = ({ tabExpanded, hasSelection, toggleExpanded, children }: ExpandableTabProps) => {
  return (
    <Box sx={{ position: "absolute", zIndex: 100, top: -14, right: 0 }}>
      <Collapse in={tabExpanded} orientation="vertical">
        {children}
      </Collapse>
      <Box display={"flex"} justifyContent={"flex-end"} position={"relative"}>
        <Box sx={{ position: "absolute", top: "-2px", bgcolor: "white" }}>
          <IconButton
            onClick={() => toggleExpanded(!tabExpanded)}
            sx={{
              borderRadius: 0,
              borderBottomRightRadius: "8px",
              borderBottomLeftRadius: "8px",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderTop: "None",
            }}
          >
            <Badge badgeContent={hasSelection ? 1 : 0} color="primary">
              {!tabExpanded ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowUpOutlinedIcon />}
            </Badge>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
