import { Clear, Search } from "@mui/icons-material";
import { InputAdornment, TextField, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { Filter } from "../models/filter";
import { SearchResults } from "../models/search";
import { StyledExpansionPanel } from "./common";
import TreeSelectFilterVirtual, { TreeFilterNode } from "./filters/TreeSelectFilterVirtual";

type FilterSectionVirtualProps = {
  filter: Filter;
  selectedValues: string[];
  appliedValues: string[];
  disabled?: boolean;
  handleFilterChange: (filterName: keyof SearchResults, newValues: string[]) => void;
};

const FilterSectionVirtual = ({
  filter,
  selectedValues,
  appliedValues,
  disabled,
  handleFilterChange,
}: FilterSectionVirtualProps) => {
  const model = useRef<TreeFilterNode[]>([]);
  const [input, setInput] = useState<string>("");

  const handleClear = () => setInput("");

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleFilterClick = (column: keyof SearchResults, filterVal: string, isSelected: boolean) => {
    if (!isSelected) {
      handleFilterChange(column, [...selectedValues, filterVal]);
    } else {
      const newFilterValues = selectedValues.filter((val) => val !== filterVal);
      handleFilterChange(column, newFilterValues);
    }
  };

  const getFilterModel = useCallback(
    (currentState: TreeFilterNode[]) => {
      const { state } = mapDataToFlatFilterModel(currentState, filter, selectedValues, appliedValues);
      return state;
    },
    [filter, selectedValues, appliedValues],
  );

  model.current = getFilterModel(model.current);

  const selectedCount = selectedValues.length;

  return (
    <div style={{ marginTop: "4px" }}>
      <StyledExpansionPanel
        title={
          <>
            <Typography display="inline" fontWeight={600}>
              {filter.filter_name}
            </Typography>
            {selectedCount ? <> &middot; {selectedCount}</> : ""}
          </>
        }
        compressed
      >
        <TextField
          variant="standard"
          value={input}
          onChange={handleInputChange}
          placeholder={`Search...`}
          sx={{ marginLeft: "14px", width: "calc(100% - 22px)" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: Boolean(input.length) && (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                <Clear onClick={handleClear} sx={{ "&.MuiSvgIcon-root": { width: 16, height: 16 } }} />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ padding: "0 4px 0 8px", minHeight: 0 }}>
          <TreeSelectFilterVirtual
            disabled={disabled}
            height={325}
            model={model.current}
            handleFilterStateChange={handleFilterClick}
            filterChildrenByString={input}
          />
        </div>
      </StyledExpansionPanel>
    </div>
  );
};

const mapDataToFlatFilterModel = (
  state: TreeFilterNode[],
  filter: Filter, // NOTE: This is a bit of a hack so this code can handle non-tree filters
  selectedValues: string[],
  appliedValues: string[],
) => {
  const nodesAreEqual = (a: TreeFilterNode, b: TreeFilterNode) => {
    // We are are testing here to see if the selection state has changed, the "metadata" of the nodes should not be changes (eg: title, column)
    return a.applied === b.applied && a.selected === b.selected && a.indeterminate === b.indeterminate;
  };

  // Generate a new parent level array
  const newNodeCollection: TreeFilterNode[] = [];

  let hasChanges = false;
  (filter?.filter_values ?? []).forEach((_filter_value) => {
    const column = filter.column_name;

    // Generate a new node from the inbound data
    const newNode = {
      key: `${column}-${_filter_value}`,
      column: column,
      label: _filter_value,
      value: _filter_value,
      get selected() {
        return selectedValues.includes(_filter_value);
      },
      get applied() {
        return appliedValues.includes(_filter_value);
      },
      indeterminate: false,
      children: [],

      open: false,
    } as TreeFilterNode;

    // Attempt to locate an existing node
    const existingNode = state.find((_existingNode) => _existingNode.key == newNode.key);

    let targetParentNode: TreeFilterNode;
    if (existingNode && nodesAreEqual(existingNode, newNode)) {
      targetParentNode = existingNode;
    } else {
      targetParentNode = newNode;
      hasChanges = true;
    }

    // Add the new node to the top level array
    newNodeCollection.push(targetParentNode);
  });

  newNodeCollection?.sort((a, b) => {
    // Sort selected values alphabetically
    if (a.applied === b.applied) {
      return a.label.localeCompare(b.label);
    } else {
      // Sort based on applied status
      return a.applied ? -1 : 1;
    }
  });

  // We only want to return the new array reference if there is an update, that way we dont trigger a re-render each time we assemble the model
  return { state: hasChanges ? newNodeCollection : state, hasChanges };
};

// const areEqual = (prevProps: Readonly<FilterSectionVirtualProps>, nextProps: Readonly<FilterSectionVirtualProps>) => {
//   // After a tremendous amount of digging, updating FilterFunctions.ts to create new arrays at the property level
//   // for filters seems to fix all the synchronization issues we were running into
//   return (
//     prevProps.selectedValues === nextProps.selectedValues &&
//     prevProps.appliedValues === nextProps.appliedValues &&
//     prevProps.disabled === nextProps.disabled
//   );
// };

// Since all the FilterSection's share a singular state touch point we have to memoize to prevent unnecessary renders
// export default React.memo(FilterSectionVirtual, areEqual);
export default FilterSectionVirtual;
