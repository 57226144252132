import { Box, Skeleton, Typography as Text } from "@mui/material";
import { forwardRef } from "react";
import { InsightChip } from "../../../components/common";
import { Keyword } from "../../../models/datasource";
import { compactFormatter } from "../../../utils/numberFormatter";

interface KeywordViewInsightProps {
  keywords: Keyword[];
  selectedWords: string[];
  toggleKeyword: (keyword: string) => void;
  isLoading?: boolean;
}

interface KeywordChipProps {
  keyword: Keyword;
  selectedWords: string[];
  toggleKeyword: (keyword: string) => void;
}

const KeywordChip = ({ keyword, selectedWords, toggleKeyword }: KeywordChipProps) => {
  const isSelected = selectedWords.includes(keyword.keyword);
  return (
    <InsightChip
      selected={isSelected}
      onClick={() => toggleKeyword(keyword.keyword)}
      label={
        <>
          <Text sx={{ color: isSelected ? "#0B41CD" : "#13294B", display: "inline-block" }}>{keyword.keyword}</Text>
          <Text
            sx={{
              color: "rgba(0, 0, 0, 0.65)",
              fontSize: "0.75em",
              margin: "0 0 0 12px",
              display: "inline-block",
            }}
          >
            {compactFormatter.format(keyword.frequency)}
          </Text>
        </>
      }
    />
  );
};

const KeywordViewInsights = forwardRef(
  ({ keywords, selectedWords, toggleKeyword, isLoading }: KeywordViewInsightProps, ref) => {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          overflow: "hidden",
        }}
        ref={ref}
      >
        {isLoading
          ? [...Array(20).keys()].map((i) => (
              <Skeleton
                key={i}
                variant="rounded"
                height={32}
                width={`${90 + Math.random() * 90}px`}
                sx={{ margin: "0 8px 8px 0" }}
              />
            ))
          : keywords.map((keyword, i) => {
              return <KeywordChip key={i} {...{ keyword, selectedWords, toggleKeyword }} />;
            })}
      </Box>
    );
  },
);

export default KeywordViewInsights;
