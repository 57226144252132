import React from "react";
import { MultiselectFilter } from "./filters";
import { Filter } from "../models/filter";
import { SearchResults } from "../models/search";
import { StyledExpansionPanel } from "./common";
import { Typography as Text } from "@mui/material";

type FilterSectionProps = {
  filter: Filter;
  selectedValues: string[];
  appliedValues: string[];
  disabled?: boolean;
  handleFilterChange: (filterName: keyof SearchResults, newValues: string[]) => void;
};

const FilterSection = ({
  filter,
  selectedValues,
  appliedValues,
  disabled,
  handleFilterChange,
}: FilterSectionProps): JSX.Element => {
  return (
    <div style={{ marginTop: "4px" }}>
      <StyledExpansionPanel
        title={
          <>
            <Text display="inline" fontWeight={600}>
              {filter.filter_name}
            </Text>
            {selectedValues.length ? <> &middot; {selectedValues.length}</> : ""}
          </>
        }
        compressed
      >
        <div style={{ padding: "0 4px 0 8px", minHeight: 0 }}>
          <MultiselectFilter
            disabled={disabled}
            filter={filter}
            selectedValues={selectedValues}
            appliedValues={appliedValues}
            handleFilterChange={handleFilterChange}
          />
        </div>
      </StyledExpansionPanel>
    </div>
  );
};

const areEqual = (prevProps: Readonly<FilterSectionProps>, nextProps: Readonly<FilterSectionProps>) => {
  // After a tremendous amount of digging, updating FilterFunctions.ts to create new arrays at the property level
  // for filters seems to fix all the synchronization issues we were running into
  return (
    prevProps.selectedValues === nextProps.selectedValues &&
    prevProps.appliedValues === nextProps.appliedValues &&
    prevProps.disabled === nextProps.disabled
  );
};

// Since all the FilterSection's share a singular state touch point we have to memoize to prevent unnecessary renders
export default React.memo(FilterSection, areEqual);
