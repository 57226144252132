import { Outlet } from "react-router-dom";
import { Authentication } from "./components/Authorization";

const AuthenticationWrapper = (): JSX.Element => {
  return (
    <Authentication>
      <Outlet />
    </Authentication>
  );
};

export default AuthenticationWrapper;
