import { Stack, Typography as T } from "@mui/material";
import { compactFormatter } from "../../utils/numberFormatter";

type ChartLabelProps = {
  label: string;
  value: number;
  color: string;
  total: number;
  title?: string;
};

const ChartLabel = ({ label, value, color, total, title }: ChartLabelProps) => {
  const SQUARE_SIZE = 12;
  return (
    <Stack direction="row" spacing={1}>
      <div style={{ backgroundColor: color, width: SQUARE_SIZE, height: SQUARE_SIZE, marginTop: 4 }} />
      <div>
        {title && (
          <T fontSize={12} sx={{ fontWeight: 600, textWrap: "nowrap" }}>
            {title}
          </T>
        )}
        <T fontSize={12} sx={{ textTransform: "capitalize" }}>
          {label}
        </T>
        <T fontSize={12}>{`${((100 * value) / total).toFixed(1)}% (${compactFormatter.format(value)} records)`}</T>
      </div>
    </Stack>
  );
};

export default ChartLabel;
