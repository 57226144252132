import { Paper, Portal } from "@mui/material";
import { useMousePosition, useWindowDimensions } from "../../components/hooks";
import ChartLabel from "./ChartLabel";

type TooltipProps = {
  id: string;
  value: number;
  color: string;
  total: number;
  toolTipWidth: number;
  title?: string;
  portal?: boolean;
  defaultPos: {
    top?: number;
    left?: number;
    right?: number;
  };
};

const ChartTooltip = ({ id, value, color, total, toolTipWidth, defaultPos, title, portal }: TooltipProps) => {
  const mousePosition = useMousePosition();
  const windowDimensions = useWindowDimensions();
  const { x: mouseX, y: mouseY } = mousePosition;
  const { width } = windowDimensions;

  if (!mouseX || !mouseY) return null;

  if (mouseX + toolTipWidth > width) {
    const diff = mouseX + toolTipWidth - width;
    if (defaultPos.left) {
      defaultPos.left = -diff;
    } else {
      defaultPos.right = -diff;
    }
  }

  const portalPos = {
    top: mouseY + (defaultPos?.top || 0),
    left: Math.min(mouseX - toolTipWidth / 2, width - toolTipWidth - 32),
  };

  const child = (
    <Paper
      style={{
        position: portal ? "fixed" : "absolute",
        backgroundColor: "white",
        padding: "12px",
        minWidth: toolTipWidth,
        ...(portal ? portalPos : defaultPos),
      }}
    >
      <ChartLabel label={id} value={value} color={color} total={total} title={title?.toString()} />
    </Paper>
  );

  return portal ? <Portal>{child}</Portal> : child;
};

export default ChartTooltip;
