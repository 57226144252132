import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { FilterDrawer } from "../components";
import { RelevantTextRecordsCard } from "../components/cards";
import PortfolioSummaryCard from "../components/cards/PortfolioSummaryCard";
import TopicDistributionCard from "../components/cards/TopicDistributionCard";
import { updateDateRangeDate } from "../components/filters/FilterFunctions";
import { defaultFilterState } from "../constants/defaultFilterState";
import { useFilterContext } from "../contexts/FilterProvider";
import { VerbatimsProvider } from "../contexts/VerbatimsProvider";
import { useRetroStore } from "../store/retros/useRetroStore";
import { useSearchStore } from "../store/useSearchStore";
import { TOP_BAR_HEIGHT } from "./AppLayout";

const HEIGHT = 377;

export default function RetrosPage() {
  const { quarterOptions, summary, clearSummary } = useRetroStore();
  const { defaultSearch, setDefaultSearch } = useSearchStore();
  const { filterState } = useFilterContext();

  useEffect(() => {
    const mostRecentQuarter = (quarterOptions ?? []).at(-1);

    // If we "have quarter options and we have not already set the default search"
    if (!defaultSearch && mostRecentQuarter) {
      setDefaultSearch(updateDateRangeDate(defaultFilterState, mostRecentQuarter.start, mostRecentQuarter.end));
    }
  }, [quarterOptions]);

  useEffect(() => {
    if (filterState) {
      clearSummary();
    }
  }, [filterState]);

  return (
    <>
      <FilterDrawer topPadding={50} />
      <Box component="main" sx={{ p: 0, flexGrow: 1, paddingTop: `${TOP_BAR_HEIGHT}px` }}>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={2}>
            {summary.summary && (
              <Grid item xs={12} sx={{ display: "flex" }}>
                <PortfolioSummaryCard sx={{ flex: 12 }} />
              </Grid>
            )}
            <Grid item xs={12} sx={{ height: HEIGHT, display: "flex" }}>
              <TopicDistributionCard sx={{ flex: 12 }} />
            </Grid>
            <Grid item xs={12}>
              <VerbatimsProvider>
                <RelevantTextRecordsCard />
              </VerbatimsProvider>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
}
