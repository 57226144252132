import { Box, Skeleton, Typography as Text, Tooltip } from "@mui/material";
import { Serie } from "@nivo/line";
import { InsightChip } from "../../../components/common";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { forwardRef } from "react";

interface EmergingTopicsInsightProps {
  topics: Serie[];
  isLoading?: boolean;
  selectedTopic: string;
  handleTopicClick: (topic: string, isSelected: boolean) => void;
}

interface TopicChipProps {
  topic: Serie;
  selectedTopic: string;
  handleTopicClick: (topic: string, isSelected: boolean) => void;
}

const TopicChip = ({ topic, selectedTopic, handleTopicClick }: TopicChipProps) => {
  const deltaChange = topic.deltaPercent;
  const deltaDisplay = Math.abs(deltaChange).toFixed(2);
  const Icon = deltaChange > 0 ? ArrowUpwardIcon : deltaChange < 0 ? ArrowDownwardIcon : null;
  const isSelected = topic.id.toString() === selectedTopic;
  return (
    <InsightChip
      selected={isSelected}
      onClick={() => handleTopicClick(topic.id.toString(), isSelected)}
      label={
        <Box display="flex" flexDirection="row" alignItems="center" sx={{ maxWidth: 450 }}>
          <Tooltip title={topic.id}>
            <Text noWrap sx={{ color: isSelected ? "#0B41CD" : "#13294B" }}>
              {topic.id}
            </Text>
          </Tooltip>
          <Text
            sx={{
              color: "rgba(0, 0, 0, 0.65)",
              fontSize: "0.75em",
              margin: "0 0 0 24px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {Icon ? <Icon sx={{ fontSize: "14px", mr: "2px" }} /> : null}
            {deltaDisplay}%
          </Text>
        </Box>
      }
    />
  );
};

const EmergingTopicsInsights = forwardRef(
  ({ topics, isLoading, selectedTopic, handleTopicClick }: EmergingTopicsInsightProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          overflow: "hidden",
        }}
      >
        {isLoading
          ? [...Array(20).keys()].map((i) => (
              <Skeleton
                key={i}
                variant="rounded"
                height={32}
                width={`${90 + Math.random() * 90}px`}
                sx={{ margin: "0 8px 8px 0" }}
              />
            ))
          : topics.map((topic) => {
              return <TopicChip key={topic.id} {...{ topic, selectedTopic, handleTopicClick }} />;
            })}
      </Box>
    );
  },
);

export default EmergingTopicsInsights;
