import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as FeedbackApi from "../api/feedback";
import { FeedbackSubmission, VerbatimFeedback } from "../components/cards/VerbatimsCard/FeedbackState";
import { selectAuth } from "../store/authorization/auth.selector";

export default function useUserFeedback() {
  const jwt = useSelector(selectAuth);

  const getUserFeedbackByIds = (documentIds: string[]) => {
    return useQuery<VerbatimFeedback[]>({
      queryKey: ["userFeedback", documentIds],
      queryFn: () => FeedbackApi.getUserFeedback(documentIds, jwt),
    });
  };

  const getAllUserFeedback = () => {
    return useQuery<VerbatimFeedback[]>({
      queryKey: ["userFeedbackAll"],
      queryFn: () => FeedbackApi.getAllUserFeedback(jwt),
    });
  };

  const { mutateAsync: saveUserFeedback } = useMutation({
    mutationFn: ({ payload }: { payload: FeedbackSubmission<unknown> }): Promise<VerbatimFeedback> => {
      return FeedbackApi.addUserFeedback(payload, jwt);
    },
  });

  return {
    getUserFeedbackByIds,
    getAllUserFeedback,
    saveUserFeedback,
  };
}
