import { forwardRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Serie } from "@nivo/line";
import EmergingTopicsGraph from "./EmergingTopicsGraph";
import EmergingTopicsInsights from "./EmergingTopicsInsights";
import { selectAuth } from "../../../store/authorization/auth.selector";
import { EmergingTopic, EmergingTopicsResponse } from "../../../models/datasource";
import { useFilterContext } from "../../../contexts/FilterProvider";
import { getEmergingTopics } from "../../../api/dataSources";
import { defaultFilterState } from "../../../constants/defaultFilterState";
import { TopicSentimentFilters } from "../../../models/search";
import { updateTopicSentimentFilters } from "../../../components/filters/FilterFunctions";

export type EmergingTopicsChartMode = "graph" | "chips";

export interface EmergingTopicsChartProps {
  mode: EmergingTopicsChartMode;
  isFullScreen: boolean;
}

const dateFormat = "YYYY-MM-DD";

const buildTopicGraphData = (
  data: EmergingTopic[],
  startDate: string | null,
  endDate: string | null,
): { topicsForDisplay: Serie[]; minDate: Dayjs; maxDate: Dayjs } => {
  const parsedStart = startDate ? dayjs(startDate) : dayjs("2020-01-01"); // default start date
  const parsedEnd = endDate ? dayjs(endDate) : dayjs(); // default to end right now

  const topicsAndDates: Record<string, Serie> = {};
  let minDate = parsedStart;
  let maxDate = parsedEnd;
  // Sort all topics by their epoch date
  const sortedTopics = data.sort((a, b) => (a.epoch > b.epoch ? 1 : -1));
  // Build a dictionary that combines duplicate topics and merges
  // their epoch and doc counts together to be displayed on the graph.
  sortedTopics.forEach((topic: EmergingTopic) => {
    if (topicsAndDates[topic.topic]) {
      const currentTopic = topicsAndDates[topic.topic];
      const parsedDate = dayjs.unix(topic.epoch);

      topicsAndDates[topic.topic] = {
        ...currentTopic,
        deltaPercent: topic.delta_percent,
        data: [...currentTopic.data, { x: parsedDate.format(dateFormat), y: topic.doc_count }],
      };
      // Update our running max value
      if (maxDate.diff(parsedDate, "day") < 1) {
        maxDate = parsedDate;
      }
    } else {
      const parsedDate = dayjs.unix(topic.epoch);
      const data: any[] = [];
      // The first epoch we come across is the earliest time we have
      // since we've already sorted by epoch time.
      // If the first timestamp is greater than a day from our query start date,
      // then we will push our own initial data point on here to help "normalize" our graph view
      // against our search date range.
      if (parsedDate.diff(parsedStart, "day") >= 1) {
        data.push({
          x: parsedStart.format(dateFormat),
          y: 0,
        });
      }
      // Push on the first epoch point
      data.push({
        x: parsedDate.format(dateFormat),
        y: topic.doc_count,
      });

      topicsAndDates[topic.topic] = {
        id: topic.topic,
        deltaPercent: topic.delta_percent,
        data,
      };
      // Update our running min date value
      if (minDate.diff(parsedDate, "day") > 1) {
        minDate = parsedDate;
      }
    }
  });

  return { topicsForDisplay: Object.values(topicsAndDates), minDate, maxDate };
};

const EmergingTopicsChart = forwardRef(({ mode, isFullScreen = false }: EmergingTopicsChartProps, ref) => {
  const jwt = useSelector(selectAuth);
  const { filterState, applyFilters } = useFilterContext();

  const { data: dataResponse, isLoading } = useQuery<EmergingTopicsResponse>({
    queryKey: ["emerging_topics", JSON.stringify(filterState)],
    queryFn: () => getEmergingTopics(filterState || defaultFilterState, jwt),
    enabled: !!filterState,
  });

  const updateTopicFilter = (filterKey: keyof TopicSentimentFilters, value: string | null) => {
    if (!filterState) return;

    applyFilters(updateTopicSentimentFilters(filterState, [filterKey], [value]));
  };

  const emergingTopics = dataResponse?.data ?? [];
  const selectedTopic = filterState?.visual_filters?.topics_sentiment?.topics ?? "";

  const handleTopicClick = (topic: string, isSelected: boolean) => {
    if (isSelected) {
      updateTopicFilter("topics", null);
    } else {
      updateTopicFilter("topics", topic);
    }
  };

  if (!filterState) return <></>;

  const { topicsForDisplay, minDate, maxDate } = buildTopicGraphData(
    emergingTopics,
    filterState.dates.start,
    filterState.dates.end,
  );

  if (!isLoading && topicsForDisplay.length == 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <span>No Emerging Topics Available</span>
      </Box>
    );
  }

  return mode === "graph" ? (
    <EmergingTopicsGraph
      ref={ref}
      topics={topicsForDisplay}
      minDate={minDate}
      maxDate={maxDate}
      isLoading={isLoading}
      selectedTopic={selectedTopic}
      handleTopicClick={handleTopicClick}
      isFullScreen={isFullScreen}
    />
  ) : (
    <EmergingTopicsInsights
      ref={ref}
      topics={topicsForDisplay}
      isLoading={isLoading}
      selectedTopic={selectedTopic}
      handleTopicClick={handleTopicClick}
    />
  );
});

export default EmergingTopicsChart;
