import { forwardRef, useState } from "react";
import { AutoSizer } from "react-virtualized";
import { Skeleton, Box } from "@mui/material";
import { Line, Point, Serie, SliceTooltipProps } from "@nivo/line";
import { AxisTickProps } from "@nivo/axes";
import dayjs from "dayjs";

import { ResolveTickValues, FrequencyLabel, MonthLabel, ExpandableTab } from "../Common";
import useGetSentimentOverTimeData from "./useGetSentimentOverTimeData";
import { useFilterContext } from "../../../contexts/FilterProvider";
import { ApiResponse, SentimentData } from "../../../models/datasource";
import { updateTopicSentimentFilters } from "../../../components/filters/FilterFunctions";
import SliceToolTip from "../common/SliceToolTip";
import ChartLegend from "../common/ChartLegend";
import { defaultFilterState } from "../../../constants/defaultFilterState";

const NEUTRAL_COLOR = "#8DC8E8";
const POSITIVE_COLOR = "#74AA50";
const NEGATIVE_COLOR = "#C8102E";

const sentiments = new Map([
  ["neutral", { label: "Neutral", color: NEUTRAL_COLOR }],
  ["positive", { label: "Positive", color: POSITIVE_COLOR }],
  ["negative", { label: "Negative", color: NEGATIVE_COLOR }],
]);

const formatLabel = (point: Point) => {
  return <>{sentiments.get(String(point.serieId))?.label || ""}</>;
};

interface SentimentOverTimeProps {
  isFullScreen?: boolean;
}

const SentimentOverTime = forwardRef(({ isFullScreen = false }: SentimentOverTimeProps, ref) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { filterState, applyFilters } = useFilterContext();

  const { data: sentimentData, isLoading } = useGetSentimentOverTimeData(filterState ?? defaultFilterState);
  if (!sentimentData) {
    return <></>;
  }

  if (!filterState) return <></>;

  const selectedSentiment = filterState.visual_filters.topics_sentiment.sentiment;

  const handleSentimentClick = (sentiment: string) => {
    const values: string[] = [];
    if (sentiment != selectedSentiment) {
      values.push(sentiment);
    }
    applyFilters(updateTopicSentimentFilters(filterState, ["sentiment"], values));
  };

  const dateFormat = "YYYY-MM-DD";
  const buildGraphData = (startDate: string | null, endDate: string | null, response: ApiResponse<SentimentData>) => {
    let minDate = startDate ? dayjs(startDate) : dayjs("2020-01-01");
    const maxDate = endDate ? dayjs(endDate) : dayjs();

    const dataSeries: Record<string, Serie> = {};

    response.data
      .sort((a, b) => (a.epoch > b.epoch ? 1 : -1))
      .forEach((record) => {
        const parsedDate = dayjs.unix(record.epoch);

        sentiments.forEach((sentiment, key) => {
          // NOTE: If we are filtering by a sentiment, exclude the others from the chart so we don't have flat-lined sentiment lines
          if (!!selectedSentiment && key != selectedSentiment) return;

          if (!dataSeries[key]) {
            dataSeries[key] = {
              id: key,
              label: sentiment.label,
              color: sentiment.color,
              data: [],
            };
          }

          // @ts-expect-error Force in formatted date
          dataSeries[key].data.push({
            x: parsedDate.format(dateFormat),
            y: record.sentiments[key],
          });
        });

        if (minDate.diff(parsedDate, "day") > 1) {
          minDate = parsedDate;
        }
      });

    return { data: Object.values(dataSeries), minDate, maxDate };
  };

  const { data, minDate, maxDate } = buildGraphData(filterState.dates.start, filterState.dates.end, sentimentData);

  // If for some reason we don't get any data, return early and display a subtle message in place the of the chart
  if (!data.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "2em",
          opacity: "0.4",
        }}
      >
        No data available
      </Box>
    );
  }

  const tickValues = ResolveTickValues(minDate, maxDate);

  const legend = (
    <ChartLegend
      selectedId={selectedSentiment}
      data={data}
      handleChipClick={handleSentimentClick}
      isFullScreen={isFullScreen}
    />
  );

  const margin = {
    top: 20,
    right: 40,
    bottom: 50,
    left: 40,
  };

  return isLoading ? (
    <Skeleton variant="rounded" height={350} width={"100%"} sx={{ margin: "0 8px 8px 0" }} />
  ) : (
    <Box className="SentimentOverTime" sx={{ height: "100%", display: "flex", flexDirection: "column" }} ref={ref}>
      {!isFullScreen && (
        <ExpandableTab tabExpanded={drawerOpen} hasSelection={!!selectedSentiment} toggleExpanded={setDrawerOpen}>
          {legend}
        </ExpandableTab>
      )}
      <Box sx={{ display: "flex", height: "100%" }}>
        <div style={{ flex: "1 1 auto" }}>
          <AutoSizer defaultHeight={margin.top + margin.bottom} defaultWidth={margin.left + margin.right}>
            {({ width, height }) => (
              <Line
                width={width}
                height={height}
                data={data}
                curve="monotoneX"
                animate={false}
                margin={margin}
                enableGridX={false}
                xScale={{
                  type: "time",
                  format: "%Y-%m-%d",
                }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                colors={({ color }) => color}
                enablePoints={false}
                enableSlices="x"
                axisLeft={{
                  renderTick: (props: AxisTickProps<number>) => FrequencyLabel(props),
                }}
                axisBottom={{
                  tickValues: tickValues,
                  renderTick: (props: AxisTickProps<number>) => MonthLabel(props, tickValues),
                }}
                sliceTooltip={(props: SliceTooltipProps) => <SliceToolTip {...props} formatLabel={formatLabel} />}
              />
            )}
          </AutoSizer>
        </div>
      </Box>
      {isFullScreen && legend}
    </Box>
  );
});

export default SentimentOverTime;
