import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { FilterResponse } from "../models/filter";
import { selectAuth } from "../store/authorization/auth.selector";
import { getFilters } from "../api/filters";

export default function useFilterOptions() {
  const jwt = useSelector(selectAuth);

  return useQuery<FilterResponse>({
    queryKey: ["filters"],
    queryFn: () => getFilters(jwt),
  });
}
