import { Dayjs } from "dayjs";
import { AutoSizer } from "react-virtualized";
import { Line, CustomLayerProps, Serie, Point, SliceTooltipProps } from "@nivo/line";
import { AxisTickProps } from "@nivo/axes";
import { Box, List, Paper, Skeleton, Stack, Typography as Text, Tooltip } from "@mui/material";
import { compactFormatter } from "../../../utils/numberFormatter";
import { useMousePosition, useWindowDimensions } from "../../../components/hooks";
import { forwardRef, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";

import { ResolveTickValues, FrequencyLabel, MonthLabel, hashToInt32, ExpandableTab, StyledChip } from "../Common";

const TOOL_TIP_WIDTH = 400;

// Custom color palette for the graph
const colors: string[] = [
  "hsla(219, 100%, 26%, 1)",
  "hsla(227, 96%, 78%, 1)",
  "hsla(196, 100%, 44%, 1)",
  "hsla(27, 85%, 57%, 1)",
  "hsla(356, 50%, 50%, 1)",
  "hsla(201, 66%, 73%, 1)",
  "hsla(228, 100%, 65%, 1)",
  "hsla(96, 36%, 49%, 1)",
  "hsla(350, 49%, 85%, 1)",
  "hsla(75, 42%, 70%, 1)",
];

interface EmergingTopicsGraphProps {
  topics: Serie[];
  minDate: Dayjs;
  maxDate: Dayjs;
  isLoading?: boolean;
  selectedTopic?: string;
  handleTopicClick: (topic: string, isSelected: boolean) => void;
  isFullScreen: boolean;
}

// https://nivo.rocks/storybook/?path=/story/line--custom-line-style
const DashedLine = ({ series, lineGenerator, xScale, yScale }: CustomLayerProps) => {
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      // TypeScript thinks the following do not have
      // call signatures so we need to explicitly mark
      // these errors as expected so this compiles.
      // @ts-expect-error Because D3
      d={lineGenerator(
        data.map((d) => ({
          // @ts-expect-error Because D3
          x: xScale(d.data.x),
          // @ts-expect-error Because D3
          y: yScale(d.data.y),
        })),
      )}
      fill="none"
      stroke={color}
      style={{
        strokeWidth: 2,
      }}
    />
  ));
};

const SliceToolTip = ({ slice }: SliceTooltipProps) => {
  const mousePosition = useMousePosition();
  const windowDimensions = useWindowDimensions();
  const { x: mouseX } = mousePosition;
  const { width } = windowDimensions;

  const { points } = slice;

  // Sort the points to show most frequent to least frequent on the tool tip
  const sortedPoints = [...points].sort((a, b) => (a.data.y > b.data.y ? -1 : 1));

  if (!mouseX) return null;

  let leftPos = 0;
  if (mouseX + TOOL_TIP_WIDTH > width) {
    const diff = mouseX + (TOOL_TIP_WIDTH + 10) - width;
    leftPos = -diff;
  }

  return (
    <Paper
      style={{
        position: "absolute",
        backgroundColor: "white",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        minWidth: TOOL_TIP_WIDTH,
        left: leftPos,
      }}
    >
      {sortedPoints.map((point: Point, i) => (
        <Stack
          key={i}
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ marginY: "5px" }}
        >
          <Stack direction="row">
            <Box width={16}>
              <Box
                style={{
                  backgroundColor: point.serieColor,
                  width: 16,
                  height: 16,
                  marginTop: 6,
                  display: "flex",
                }}
              />
            </Box>
            <Text paddingX={2}>{point.serieId}</Text>
          </Stack>
          <Stack>
            <Text sx={{ fontWeight: "600" }}>{compactFormatter.format(Number(point.data.y))}</Text>
          </Stack>
        </Stack>
      ))}
    </Paper>
  );
};

interface ChartLegendProps {
  selectedTopic?: string;
  topics: Serie[];
  colorMap: Record<string, string>;
  handleTopicClick: (topic: string, isSelected: boolean) => void;
  isFullScreen: boolean;
}

const ChartLegend = ({ selectedTopic, topics, colorMap, handleTopicClick, isFullScreen }: ChartLegendProps) => {
  // Sort topics alphabetically
  const sortedTopics = topics.sort((a, b) => (a.id > b.id ? 1 : -1));
  return (
    <List
      component={Box}
      dense
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        listStyle: "none",
        ...(isFullScreen
          ? {}
          : {
              margin: 0,
              padding: 2,
              paddingBottom: 4,
              backgroundColor: "background.paper",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderTop: "None",
              borderBottomLeftRadius: "8px",
            }),
      }}
    >
      {sortedTopics.map(({ id }) => {
        const isSelected = selectedTopic === id.toString();
        return (
          <Tooltip key={id} title={id}>
            <StyledChip
              variant={isSelected ? "filled" : "outlined"}
              size="small"
              label={`${id}`}
              onClick={() => handleTopicClick(id.toString(), isSelected)}
              icon={<CircleIcon style={{ fontSize: "8px", color: colorMap[`${id}Color`], marginRight: "0px" }} />}
              sx={{ marginTop: "8px", maxWidth: 300 }}
            />
          </Tooltip>
        );
      })}
    </List>
  );
};

const EmergingTopicsGraph = forwardRef(
  (
    {
      topics,
      minDate,
      maxDate,
      isLoading,
      selectedTopic,
      handleTopicClick,
      isFullScreen = false,
    }: EmergingTopicsGraphProps,
    ref,
  ) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    // To ensure to graph colors match the assigned chip colors,
    // dynamically build the color palette and assign to each topic
    const colorMap: Record<string, string> = {};
    topics.reduce((accum, topic) => {
      accum[`${topic.id}Color`] = colors[Math.abs(hashToInt32(String(topic.id))) % colors.length];
      return accum;
    }, colorMap);

    const tickValues = ResolveTickValues(minDate, maxDate);

    const legend = (
      <ChartLegend
        selectedTopic={selectedTopic}
        topics={topics}
        colorMap={colorMap}
        handleTopicClick={handleTopicClick}
        isFullScreen={isFullScreen}
      />
    );

    return isLoading ? (
      <Skeleton variant="rounded" height={350} width={"100%"} sx={{ margin: "0 8px 8px 0" }} />
    ) : (
      <Box sx={{ position: "relative" }} ref={ref}>
        {!isFullScreen && (
          <ExpandableTab tabExpanded={drawerOpen} hasSelection={!!selectedTopic} toggleExpanded={setDrawerOpen}>
            {legend}
          </ExpandableTab>
        )}
        <Box sx={{ display: "flex", aspectRatio: "2" }}>
          <div style={{ flex: "1 1 auto" }}>
            <AutoSizer>
              {({ width, height }) => (
                <Line
                  width={width}
                  height={height}
                  data={topics}
                  animate={false}
                  margin={{
                    top: 20,
                    right: 40,
                    bottom: 50,
                    left: 40,
                  }}
                  enableGridX={false}
                  xScale={{
                    type: "time",
                    format: "%Y-%m-%d",
                  }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: false,
                    reverse: false,
                  }}
                  layers={[
                    // includes all default layers
                    "grid",
                    "markers",
                    "axes",
                    "areas",
                    "crosshair",
                    "slices",
                    "points",
                    "mesh",
                    "legends",
                    DashedLine, // add the custom dashed layer here
                  ]}
                  colors={({ id }) => String(colorMap[`${id}Color`])}
                  enableSlices="x"
                  axisLeft={{
                    renderTick: (props: AxisTickProps<number>) => FrequencyLabel(props),
                  }}
                  axisBottom={{
                    tickValues: tickValues,
                    renderTick: (props: AxisTickProps<number>) => MonthLabel(props, tickValues),
                  }}
                  sliceTooltip={(props: SliceTooltipProps) => <SliceToolTip {...props} />}
                />
              )}
            </AutoSizer>
          </div>
        </Box>
        {isFullScreen && legend}
      </Box>
    );
  },
);

export default EmergingTopicsGraph;
