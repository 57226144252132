import { Box, Typography } from "@mui/material";
import { useSampleQuestionStore } from "../../../store/home/useSampleQuestionStore";
import QuestionCard from "./QuestionCard";

export default function SampleQuestions() {
  const { submitQuestion, randomQuestion } = useSampleQuestionStore();
  return (
    <Box width="100%" gap="1.5rem" display="flex" flexDirection="column">
      <Typography alignSelf="center" fontSize="20px" color="rgb(0, 48, 135)">
        Not sure what to ask? Try these:
      </Typography>
      <Box display="flex" gap="1.5rem">
        {randomQuestion.map(({ text, Icon }) => (
          <QuestionCard text={text} Icon={Icon} onClick={submitQuestion} key={text} />
        ))}
      </Box>
    </Box>
  );
}
