import { GridColDef } from "@mui/x-data-grid";
import { Typography as T } from "@mui/material";
import { DataDistribution } from "../../models/datasource";
import { StyledDataGrid } from "../../components/common";
import { compactFormatter } from "../../utils/numberFormatter";

const PercentageCell = ({ value }: { value?: number }): JSX.Element => {
  const output = value != null ? `${compactFormatter.format(value)}%` : "-";
  return (
    <div>
      <T display="inline" fontSize={14}>
        {output}
      </T>
    </div>
  );
};

type DataCompletenessTableProps = {
  data: DataDistribution[];
};

const DataCompletenessTable = ({ data }: DataCompletenessTableProps): JSX.Element => {
  const columns: GridColDef<DataDistribution>[] = [
    {
      field: "name",
      headerName: "Source",
      flex: 2,
      minWidth: 280,
      renderCell: (params) => (
        <T fontWeight={600} fontSize={14}>
          {params.row.name}
        </T>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => <PercentageCell value={params.row.completeness_percentage.date} />,
    },
    {
      field: "therapeutic_area",
      headerName: "Therapeutic Area",
      flex: 1,
      renderCell: (params) => <PercentageCell value={params.row.completeness_percentage.therapeutic_area} />,
    },
    {
      field: "product_molecule",
      headerName: "Product/Molecule",
      flex: 1,
      renderCell: (params) => <PercentageCell value={params.row.completeness_percentage.product_molecule} />,
    },
    {
      field: "indication",
      headerName: "Indication",
      flex: 1,
      renderCell: (params) => <PercentageCell value={params.row.completeness_percentage.indication} />,
    },
    {
      field: "ecosystem_name",
      headerName: "Ecosystem",
      flex: 1,
      renderCell: (params) => <PercentageCell value={params.row.completeness_percentage.ecosystem_name} />,
    },
    {
      field: "corp_account",
      headerName: "Corp Account",
      flex: 1,
      renderCell: (params) => <PercentageCell value={params.row.completeness_percentage.corp_account} />,
    },
    {
      field: "account_type",
      headerName: "Account Type",
      flex: 1,
      renderCell: (params) => <PercentageCell value={params.row.completeness_percentage.account_type} />,
    },
    {
      field: "role_type",
      headerName: "Role Type",
      flex: 1,
      renderCell: (params) => <PercentageCell value={params.row.completeness_percentage.role_type} />,
    },
  ];

  // have to augment the data rows with an ID value; for now, name of datasource.
  return (
    <StyledDataGrid
      autoHeight
      rows={data.map((d) => ({ ...d, id: d.name }))}
      columns={columns}
      hideFooter
      disableColumnMenu
    />
  );
};

export default DataCompletenessTable;
