import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Chip, Paper, PaperProps, Skeleton, styled, Typography as T } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useQuery } from "@tanstack/react-query";
import { getTopicDistribution } from "../../api/dataSources";
import { defaultFilterState } from "../../constants/defaultFilterState";
import { TopicDistribution, TopicsSentiment, UsltSentiment } from "../../models/datasource";
import ChartCard from "../../components/ChartCard";
import { TopicDistributionChart } from "../../components/charts";
import { selectAuth } from "../../store/authorization/auth.selector";
import { useFilterContext } from "../../contexts/FilterProvider";
import { updateTopicSentimentFilters } from "../../components/filters/FilterFunctions";
import { TopicSentimentFilters } from "../../models/search";
import { ChartMenu, FullScreen, DownloadButton } from "../../components/common";
import { downloadAsPng } from "../../utils/element-export";
import { green, blue, red } from "../../colors";
import Roles from "../Authorization/Roles";

const GetSentimentTypes = (selected_role?: string) => {
  if (selected_role == Roles.USLT_RETRO) {
    return [
      { label: "Opportunity", color: blue[300] },
      { label: "Tailwinds", color: green[500] },
      { label: "Headwinds", color: red[500] },
    ];
  } else {
    return [
      { label: "Neutral", color: blue[300] },
      { label: "Positive", color: green[500] },
      { label: "Negative", color: red[500] },
    ];
  }
};

const StyledChip = styled(Chip)(() => ({
  paddingLeft: "2px",
  paddingRight: "2px",
  marginRight: "10px",
  border: "1px solid #EFF0F0",
  borderRadius: "4px",
  fontWeight: 400,
}));

const TopicDistributionContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    <T fontWeight={900}>Topic Distribution</T>
    This widget surfaces the total record count per topic. The graph also displays the sentiment breakdown for documents
    within each topic.
    <br />
    <br />
    <T fontWeight={900}>How are the topics created?</T>
    deepsense uses machine learning topic modeling to generate topics and keywords. Topic Modeling groups a collection
    of documents based on semantic similarity into clusters of 20 or more similar documents, each of which becomes a
    topic. Documents not fitting into a large enough cluster are not assigned a topic.
    <br />
    <br />
    Within the records constituting each topic, the model can determine the most relevant keywords. These are
    significant words or phrases extracted from the records in that topic group.
    <br />
    <br />
    The topic labels for these groups are created using generative AI. The labeling model is given a few representative
    documents from the topic group and the respective keywords, in order to generate a human-readable label. This label
    describes the collection of semantically similar documents as a whole.
  </Paper>
);

const TopicDistributionCard = (props: PaperProps): JSX.Element => {
  const jwt = useSelector(selectAuth);
  const { filterState, applyFilters } = useFilterContext();

  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const isUsltUser = jwt.role?.at(0) == Roles.USLT_RETRO;

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "topic_distribution");
    }
  };

  const updateTopicFilter = (filterKey: keyof TopicSentimentFilters, value: string | null) => {
    if (!filterState) return;
    applyFilters(updateTopicSentimentFilters(filterState, [filterKey], [value]));
  };

  const handleSentimentClick = (sentiment: string, isSelected: boolean) => {
    if (isSelected) {
      updateTopicFilter("sentiment", null);
    } else {
      // HACK: USLT demands Title case, whereas Med/Com demands lower case, ideally we want to normalize the data to 1, 0, -1
      updateTopicFilter("sentiment", isUsltUser ? sentiment : sentiment.toLowerCase());
    }
  };

  // Handles updating the applied filter state when
  // the user clicks on a specific topic & sentiment combination
  // from the topic distribution bar graph
  const handleTopicSentimentClick = (
    topic: string,
    isTopicSelected: boolean,
    sentiment: string | undefined,
    isSentimentSelected: boolean | undefined,
  ) => {
    if (!filterState) return;

    const filterKeys = ["topics" as keyof TopicSentimentFilters];
    const values = [isTopicSelected ? null : topic];
    if (sentiment) {
      filterKeys.push("sentiment");
      if (isUsltUser) {
        if (sentiment.toLowerCase() == "positive") {
          values.push(isSentimentSelected ? null : "Tailwinds");
        } else if (sentiment.toLowerCase() == "neutral") {
          values.push(isSentimentSelected ? null : "Opportunity");
        } else if (sentiment.toLowerCase() == "negative") {
          values.push(isSentimentSelected ? null : "Headwinds");
        }
      } else {
        values.push(isSentimentSelected ? null : sentiment.toLowerCase());
      }
    }
    applyFilters(updateTopicSentimentFilters(filterState, filterKeys, values));
  };

  const getChartData = (data: unknown[]) => {
    let response: TopicsSentiment[] = [];
    if (isUsltUser) {
      response = (data as UsltSentiment[]).map((row) => {
        return {
          topic: row.topic,
          sentiments: {
            positive: row.sentiments.tailwinds,
            negative: row.sentiments.headwinds,
            neutral: row.sentiments.opportunity,
          },
        } as TopicsSentiment;
      });
    } else {
      response = data as TopicsSentiment[];
    }

    return response;
  };

  const {
    data: dataResponse,
    isLoading,
    isError,
  } = useQuery<TopicDistribution<unknown>>({
    queryKey: ["topic_distribution", JSON.stringify(filterState)],
    queryFn: () => getTopicDistribution(filterState || defaultFilterState, jwt),
    enabled: !!filterState,
  });

  const data = !isError && dataResponse?.data ? getChartData(dataResponse.data) : [];
  const selectedSentiment = filterState?.visual_filters?.topics_sentiment?.sentiment ?? null;
  const selectedTopic = filterState?.visual_filters?.topics_sentiment?.topics ?? "";

  const SENTIMENT_TYPES = GetSentimentTypes(jwt.role?.at(0));

  return (
    <ChartCard
      {...props}
      title="Topic Distribution"
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      tooltipContent={<TopicDistributionContent />}
      actions={
        <>
          <Box flexDirection={"row"}>
            {SENTIMENT_TYPES.map(({ label, color }) => {
              const isSelected = label.toLowerCase() === selectedSentiment?.toLowerCase();
              return (
                <StyledChip
                  key={label}
                  variant={isSelected ? "filled" : "outlined"}
                  size="small"
                  label={label}
                  onClick={() => handleSentimentClick(label, isSelected)}
                  icon={<CircleIcon style={{ fontSize: "8px", color: color, marginRight: "0px" }} />}
                />
              );
            })}
          </Box>
          <ChartMenu onDownload={triggerDownload} onFullScreen={() => setExpanded(true)} />
        </>
      }
    >
      {isLoading ? (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
          {[1, 2, 3, 4, 5, 6].map((value) => (
            <Skeleton key={value} variant="rounded" height={20} width={"80%"} sx={{ marginTop: "12px" }} />
          ))}
        </Box>
      ) : data.length > 1 ? (
        <FullScreen
          title="Topic Distribution"
          expanded={expanded}
          handleClose={() => setExpanded(false)}
          p={"1em"}
          actions={<DownloadButton action={triggerDownload} />}
        >
          <TopicDistributionChart
            ref={ref}
            data={data}
            selectedSentiment={selectedSentiment}
            selectedTopic={selectedTopic}
            handleTopicSentimentClick={handleTopicSentimentClick}
            isFullScreen={expanded}
          />
        </FullScreen>
      ) : (
        <Box display="flex" flexDirection="row" justifyContent="center">
          <T>No records to show.</T>
        </Box>
      )}
    </ChartCard>
  );
};

export default TopicDistributionCard;
