import { useState, useEffect } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { difference } from "lodash";
import { West, East } from "@mui/icons-material";
import { Box, Paper, TextField, Button, Modal, Typography } from "@mui/material";
import { ChartKey, CustomTab } from "../../models/customTabs";
import { selectAuth } from "../../store/authorization/auth.selector";
import ChartList from "./ChartList";
import { formatCustomTabName, CommercialChartCardTable, MedInfoChartCardTable } from "./common";

interface ModalProps {
  open: boolean;
  onApply: (state: CustomTab) => void;
  onCancel: () => void;
  onDelete: () => void;
  initialState: CustomTab;
}

export default function EditModal({
  open,
  initialState,
  onApply: handleApply,
  onCancel: handleCancel,
  onDelete: handleDelete,
}: ModalProps) {
  const [chartKeys, setChartKeys] = useState<ChartKey[]>([]);
  const [name, setName] = useState("");
  const [selectedLeft, setSelectedLeft] = useState<Set<ChartKey>>(new Set());
  const [selectedRight, setSelectedRight] = useState<Set<ChartKey>>(new Set());
  const { role } = useSelector(selectAuth);

  const charts = role?.includes("commercial")
    ? CommercialChartCardTable
    : role?.includes("med_info")
      ? MedInfoChartCardTable
      : CommercialChartCardTable;

  useEffect(() => {
    if (open) {
      setChartKeys(initialState.charts.map((c) => c.chart_type));
      setName(initialState.name);
    }
  }, [open]);

  return (
    <Modal open={open}>
      <Box
        onSubmit={(e) => e.preventDefault()}
        component="form"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Paper
          sx={{
            "& p,input::placeholder": { letterSpacing: ".025em" },
            borderRadius: "1rem",
            maxHeight: "47rem",
            height: "90%",
            px: 4,
            pb: 3.5,
            display: "flex",
            flexDirection: "column",
            gap: 3.5,
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            height="3.5rem"
            justifyContent="center"
            alignItems="center"
            borderBottom="1px solid rgba(0,0,0,0.1)"
            flexShrink={0}
          >
            <Typography fontWeight="bold" fontSize="1.25rem" color="rgba(0,0,0,0.8)">
              {formatCustomTabName(name) || "New Tab"}
            </Typography>
          </Box>
          <TextField
            onChange={({ target: { value } }) => setName(value)}
            value={name}
            placeholder="Enter name here (required)…"
            sx={{ "& .MuiInputBase-input": { py: 1.5 }, "& .MuiFormLabel-root": { mt: "-0.0rem" } }}
          />
          <Box display="flex" overflow="hidden" flexGrow={1} gap=".875rem">
            <ChartList
              title="Common Charts"
              selected={selectedLeft}
              onClick={setSelectedLeft}
              list={difference(Object.keys(charts), chartKeys)}
            />
            <Box
              gap={1.5}
              flexGrow={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <Button
                onClick={() => {
                  setChartKeys([...chartKeys, ...selectedLeft]);
                  setSelectedLeft(new Set());
                }}
                disabled={selectedLeft.size === 0}
                sx={{
                  background: "rgba(217, 217, 217, 0.15)",
                  color: "rgba(0,0,0,0.6)",
                  justifyContent: "center",
                  minWidth: "3.5rem",
                }}
                size="small"
                variant="contained"
              >
                <East />
              </Button>
              <Button
                onClick={() => {
                  setChartKeys(difference(chartKeys, [...selectedRight]));
                  setSelectedRight(new Set());
                }}
                disabled={selectedRight.size === 0}
                sx={{
                  background: "rgba(217, 217, 217, 0.15)",
                  color: "rgba(0,0,0,0.6)",
                  justifyContent: "center",
                  minWidth: "3.5rem",
                }}
                size="small"
                variant="contained"
              >
                <West />
              </Button>
            </Box>
            <ChartList title="Selected Charts" selected={selectedRight} onClick={setSelectedRight} list={chartKeys} />
          </Box>
          <Box display="flex" gap={1.5} justifyContent="center" alignItems="center">
            {/* Not part of figma docs so let's ask Julie where to put delete button */}
            {initialState.name !== "" && (
              <Button
                onClick={handleDelete}
                sx={{
                  "&:hover": { color: "#C8102E", outlineColor: "#C8102E" },
                  color: "#5A5B5D",
                  background: "rgba(217, 217, 217, 0.25)",
                }}
                variant="contained"
              >
                Delete Tab
              </Button>
            )}
            <Button
              onClick={handleCancel}
              sx={{ color: "#5A5B5D", background: "rgba(217, 217, 217, 0.25)" }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              sx={{ color: "#0B41CD", background: "rgba(11,65,205,0.15)" }}
              disabled={chartKeys.length === 0 || name === ""}
              onClick={() => {
                handleApply({ name: name, charts: chartKeys.map((_key) => ({ chart_type: _key })) });
              }}
              variant="contained"
            >
              {initialState.name === "" ? "Create" : "Update"}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
