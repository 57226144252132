import React, { useState, useEffect } from "react";
import { Clear, InfoOutlined, Search } from "@mui/icons-material";
import {
  Checkbox,
  InputAdornment,
  Stack,
  TextField,
  FormControlLabel,
  Divider,
  Tooltip,
  Typography as T,
  Paper,
  styled,
  TooltipProps,
  tooltipClasses,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { TOOL_TIP_CONTENT } from "./constants";
import { updateQuery } from "./FilterFunctions";
import { useFilterContext } from "../../contexts/FilterProvider";
import { useSearchId } from "../../contexts/SearchIdProvider";

const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      padding: 0,
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        backgroundColor: "white",
        border: "1px solid #dadde9",
      },
    },
  };
});

const StyledList = styled(List)({
  listStyleType: "disc",
  paddingLeft: "16px",
  paddingTop: 0,
  paddingBottom: 0,
  "& .MuiListItem-root": {
    display: "list-item",
    paddingLeft: 0,
    paddingBottom: 0,
  },
});

const ToolTipContent = () => (
  <Paper sx={{ p: 2 }}>
    {TOOL_TIP_CONTENT.map(({ title, tips }) => {
      return (
        <>
          <T sx={{ fontWeight: "700" }}>{title}</T>
          <StyledList dense>
            {tips.map(({ primaryText, secondaryText }) => (
              <ListItem>
                <ListItemText primary={primaryText} secondary={secondaryText ?? ""} />
              </ListItem>
            ))}
          </StyledList>
        </>
      );
    })}
  </Paper>
);

const SearchToolTip = () => (
  <StyledToolTip title={<ToolTipContent />} arrow enterTouchDelay={250}>
    <InfoOutlined sx={{ "&.MuiSvgIcon-root": { width: 20, height: 20 } }} />
  </StyledToolTip>
);

const SearchBar = (): JSX.Element => {
  const { filterState, applyFilters } = useFilterContext();
  const { searchId } = useSearchId();
  const [input, setInput] = useState(filterState?.query ?? "");
  const semanticSearch = filterState?.semantic ?? true;

  useEffect(() => {
    // Whenever a saved search is selected, update the input with the saved search query text
    if (searchId) {
      setInput(filterState?.query ?? "");
    }
  }, [searchId, filterState]);

  const handleClear = () => {
    if (!filterState) return;
    // reset input / query text
    setInput("");
    applyFilters(updateQuery(filterState, null, semanticSearch));
  };

  const handleSubmitSearch = (e: React.KeyboardEvent) => {
    if (!filterState) return;

    if (e.key === "Enter") {
      applyFilters(updateQuery(filterState, input, semanticSearch));
      e.preventDefault();
      // remove focus from search bar
      (e.target as HTMLElement).blur();
    }
  };

  const handleInputUpdate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleExactSearchToggle = () => {
    if (!filterState) return;

    applyFilters(updateQuery(filterState, input, !semanticSearch));
  };

  return (
    <TextField
      size="small"
      fullWidth
      sx={{
        backgroundColor: "white",
        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: "#e0e0e0",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#e0e0e0",
            background: "rgba(11, 65, 205, 0.05)",
          },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ cursor: "pointer" }}>
            <Stack direction="row" alignItems={"center"} spacing={1}>
              {input.length ? (
                <Clear onClick={handleClear} sx={{ "&.MuiSvgIcon-root": { width: 16, height: 16 } }} />
              ) : (
                <div style={{ width: 24, height: 24, marginRight: "8px" }} />
              )}
              <SearchToolTip />
              <div style={{ height: "35px" }}>
                <Divider orientation="vertical" />
              </div>
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: 12 } }}
                control={
                  <Checkbox
                    size="small"
                    checked={!semanticSearch}
                    onChange={handleExactSearchToggle}
                    sx={{
                      width: 16,
                      height: 16,
                      mr: "6px",
                      "& .PrivateSwitchBase-input": { width: 16, height: 16 },
                      "& .MuiSvgIcon-root": { width: 16, height: 16 },
                      "&.Mui-checked": { color: "grey.600" },
                    }}
                  />
                }
                label="Exact search"
              />
            </Stack>
          </InputAdornment>
        ),
      }}
      value={input}
      onChange={handleInputUpdate}
      onKeyDown={handleSubmitSearch}
    />
  );
};

export default SearchBar;
