import { SxProps } from "@mui/material";
import { DataDistributionCard, KeywordsCloudCard, RelevantTextRecordsCard } from "../../components/cards";
import { DistIcon, WordIcon, LineIcon, MapIcon, BarIcon, TableIcon } from "../../customTabAssets";

import DocumentUsageCard from "../../components/cards/DocumentUsageCard";
import EcosystemMapCard from "../../components/cards/EcosystemMapCard";
import EmergingTopicsCard from "../../components/cards/EmergingTopicsCard";
import SentimentOverTimeCard from "../../components/cards/SentimentOverTimeCard";
import TopicDistributionCard from "../../components/cards/TopicDistributionCard";
import TopicsNoInfoInquiriesCard from "../../components/cards/TopicsNoInfoInquiriesCard";
import InquiryVolumeOverTime from "../../components/cards/InquiryVolumeOverTimeCard";
import InquiryVolumeBySource from "../../components/cards/InquiryVolumeBySourceCard";

export const LIST_WIDTH = 352;
export const HEIGHT = 377;

// Can't depend on CSS maxWidth because we want the button
// and the name displayed here to truncate at the same place
// but that won't happen since they have different fonts
const MAX_NAME = 12;
export const formatCustomTabName = (name: string) =>
  `${name.slice(0, MAX_NAME).trim()}${name.trim().length > MAX_NAME ? "…" : ""}`;

interface CardItem {
  type: keyof typeof TypeTable;
  Component: React.ComponentType;
  verbatims: boolean;
  sx: SxProps;
  title: string;
  description: string;
}

export const TypeTable = {
  dist: { label: "Distribution", Graphic: DistIcon },
  word: { label: "Word Cloud", Graphic: WordIcon },
  line: { label: "Line Chart", Graphic: LineIcon },
  map: { label: "Ecosystems Map", Graphic: MapIcon },
  bar: { label: "Bar Chart", Graphic: BarIcon },
  table: { label: "Summary", Graphic: TableIcon },
};

// Keys are validated on the server so if they
// change here, they'll also need to change in
// leibniz-service/leibniz/models/enums.py

export const CommercialChartCardTable: { [key: string]: CardItem } = {
  topicDistribution: {
    type: "bar",
    title: "Topic Distribution",
    description: `Displays a histogram showing the number of records for the each topic.`,
    Component: TopicDistributionCard,
    sx: { flexBasis: "unset", aspectRatio: "10/4", maxWidth: "unset", width: "100%" },
    verbatims: false,
  },
  dataDistribution: {
    type: "dist",
    title: "Data Distribution",
    description: "Displays the total number records for each Data Source.",
    Component: DataDistributionCard,
    sx: { flexBasis: "unset", maxWidth: "unset", aspectRatio: "unset", width: "100%" },
    verbatims: false,
  },
  emergingTopics: {
    type: "word",
    title: "Emerging Topics",
    description: "Displays a word cloud of the emerging topics.",
    Component: EmergingTopicsCard,
    // Width derived from ChartGrid with height 377
    sx: {},
    verbatims: false,
  },
  keywords: {
    type: "word",
    title: "Keywords",
    description: "Displays a word cloud for keywords.",
    Component: KeywordsCloudCard,
    // Width derived from ChartGrid with height 377
    sx: {},
    verbatims: false,
  },
  sentimentOverTime: {
    type: "line",
    title: "Sentiment over Time",
    description: "Displays a time-series record count for topic sentiments.",
    Component: SentimentOverTimeCard,
    sx: {},
    verbatims: false,
  },
  ecosystemMapCard: {
    type: "map",
    title: "Ecosystems Map",
    description: `Displays the record count for topics grouped by ecosystems.`,
    Component: EcosystemMapCard,
    sx: { "& .Choropleth": { width: "90%", margin: "auto" } },
    verbatims: false,
  },
  relevantTextRecords: {
    type: "table",
    title: "Relevant Text Records",
    description: "Displays text summaries that are created using generative AI.",
    Component: RelevantTextRecordsCard,
    sx: { flexBasis: "unset", maxWidth: "unset", aspectRatio: "unset", width: "100%" },
    verbatims: true,
  },
};

export const MedInfoChartCardTable: { [key: string]: CardItem } = {
  documentUsage: {
    type: "bar",
    title: "Document Usage",
    description: "This chart displays the distribution of data sources in the underlying dataset.",
    Component: DocumentUsageCard,
    sx: {},
    verbatims: false,
  },
  inquiryVolumeOverTime: {
    type: "line",
    title: "Inquiry Volume Over Time",
    description: "This is a brief description of what the chart is depicting",
    Component: InquiryVolumeOverTime,
    sx: { display: "flex", height: HEIGHT, "&>div": { flex: 12 } },
    verbatims: false,
  },
  inquiryVolumeBySource: {
    type: "line",
    title: "Inquiry Volume By Source",
    description: "This is a brief description of what the chart is depicting",
    Component: InquiryVolumeBySource,
    sx: { display: "flex", height: HEIGHT, "&>div": { flex: 12 } },
    verbatims: false,
  },
  topicsNoInfoInquiries: {
    type: "line",
    title: "Topics—No (Further) Info Inquiries",
    description: "This is a brief description of what the chart is depicting",
    Component: TopicsNoInfoInquiriesCard,
    sx: { display: "flex", height: HEIGHT, "&>div": { flex: 12 } },
    verbatims: false,
  },
  ...CommercialChartCardTable,
};

export type CommercialChartKey = keyof typeof CommercialChartCardTable;
export type MedInfoChartKey = keyof typeof MedInfoChartCardTable;
export type ChartKey = CommercialChartKey | MedInfoChartKey;
export interface ListEvent {
  merge: boolean;
  item: ChartKey;
}
