import { Box, PaperProps, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getInquiryVolumeBySource } from "../../api/dataSources";
import ChartCard from "../../components/ChartCard";
import { DownloadToolTipContent, LineChart, formatIds } from "../../components/charts";
import { ChartMenu, DownloadButton, FullScreen } from "../../components/common";
import { defaultFilterState } from "../../constants/defaultFilterState";
import { useFilterContext } from "../../contexts/FilterProvider";
import { InquiryVolumeBySourceResponse } from "../../models/datasource";
import { InquirySourceBreakdownType } from "../../models/search";
import { selectAuth } from "../../store/authorization/auth.selector";
import { downloadAsPng } from "../../utils/element-export";
import CommonTooltipContent from "../CommonTooltipContent";

const labelMap: Record<InquirySourceBreakdownType, string> = {
  direct_contact: "Direct",
  msl_facilitated: "MSL",
  med_facilitated: "MED",
  other_facilitated: "Other",
};

const InquiryVolumeBySourceCard = (props: PaperProps): JSX.Element => {
  const jwt = useSelector(selectAuth);
  const { filterState, applyFilters } = useFilterContext();

  const ref = useRef<HTMLCanvasElement>(null);
  const [expanded, setExpanded] = useState(false);

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "InquiryVolumeBySource");
    }
  };

  const {
    data: dataResponse,
    isLoading,
    isError,
  } = useQuery<InquiryVolumeBySourceResponse>({
    queryKey: ["inquiry_volume_by_source", JSON.stringify(filterState)],
    queryFn: () => getInquiryVolumeBySource(filterState || defaultFilterState, jwt),
    enabled: !!filterState,
  });

  const data = !isError && dataResponse?.data ? dataResponse.data : [];
  const selectedTopic: InquirySourceBreakdownType | undefined =
    filterState?.visual_filters?.inquiry_source_breakdown?.[0];

  const handleSelectTopic = (topic: string) => {
    if (!filterState) return;

    const typeKeyword = (Object.keys(labelMap) as InquirySourceBreakdownType[]).find((key) => labelMap[key] === topic);
    const inquirySourceBreakdown = typeKeyword === selectedTopic ? [] : [typeKeyword];
    const filterCopy = {
      ...filterState,
      visual_filters: {
        ...filterState.visual_filters,
        inquiry_source_breakdown: inquirySourceBreakdown as InquirySourceBreakdownType[],
      },
    };

    applyFilters(filterCopy);
  };

  return (
    <ChartCard
      {...props}
      title={["Inquiry Volume By Source"]}
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      tooltipContent={
        <CommonTooltipContent
          header="Inquiry Volume By Source"
          desc={
            <>
              The Inquiry Volume By Source widget breaks down the change in volume of inquiries by showing the volume of
              inquiries from each individual data source over the date range selected, based on your search and filter
              parameters.
            </>
          }
        />
      }
      actions={
        <ChartMenu
          onDownload={triggerDownload}
          onFullScreen={() => setExpanded(true)}
          downloadToolTip={<DownloadToolTipContent />}
        />
      }
    >
      {isLoading ? (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
          {[1, 2, 3, 4, 5, 6].map((value) => (
            <Skeleton key={value} variant="rounded" height={20} width={"80%"} sx={{ marginTop: "12px" }} />
          ))}
        </Box>
      ) : (
        <FullScreen
          title="Inquiry Volume By Source"
          expanded={expanded}
          handleClose={() => setExpanded(false)}
          p={"1em"}
          actions={<DownloadButton action={triggerDownload} />}
        >
          <LineChart
            onSelectTopic={handleSelectTopic}
            selectedTopic={selectedTopic ? labelMap[selectedTopic] : undefined}
            ref={ref}
            data={formatIds(data, labelMap)}
            isFullScreen={expanded}
          />
        </FullScreen>
      )}
    </ChartCard>
  );
};

export default InquiryVolumeBySourceCard;
