import { SvgIcon } from "@mui/material";

export default function IconExport2() {
  return (
    <SvgIcon width={"18px"} sx={{ "& path": { fill: "#757575" } }} viewBox="0 0 14 14" fontSize="inherit">
      <g>
        <path
          d="M3.0625 6.01743H2.95508V6.12486V11.3749V11.4823H3.0625H10.9375H11.0449V11.3749V6.12486V6.01743H10.9375H9.625C9.53746 6.01743 9.4535 5.98266 9.3916 5.92076C9.3297 5.85886 9.29492 5.7749 9.29492 5.68736C9.29492 5.59981 9.3297 5.51586 9.3916 5.45396C9.4535 5.39205 9.53746 5.35728 9.625 5.35728H10.9375C11.1411 5.35728 11.3363 5.43815 11.4803 5.5821C11.6242 5.72605 11.7051 5.92128 11.7051 6.12486V11.3749C11.7051 11.5784 11.6242 11.7737 11.4803 11.9176C11.3363 12.0616 11.1411 12.1424 10.9375 12.1424H3.0625C2.85893 12.1424 2.66369 12.0616 2.51974 11.9176C2.37579 11.7737 2.29492 11.5784 2.29492 11.3749V6.12486C2.29492 5.92128 2.37579 5.72605 2.51974 5.5821C2.66369 5.43815 2.85893 5.35728 3.0625 5.35728H4.375C4.46254 5.35728 4.5465 5.39205 4.6084 5.45396C4.6703 5.51586 4.70508 5.59981 4.70508 5.68736C4.70508 5.7749 4.6703 5.85885 4.6084 5.92076C4.5465 5.98266 4.46254 6.01743 4.375 6.01743H3.0625ZM6.66992 2.36837V2.10896L6.48653 2.29243L5.04607 3.73343C5.04607 3.73343 5.04606 3.73344 5.04606 3.73344C4.98411 3.79538 4.9001 3.83018 4.8125 3.83018C4.72489 3.83018 4.64088 3.79538 4.57893 3.73343C4.51698 3.67148 4.48218 3.58746 4.48218 3.49986C4.48218 3.41225 4.51698 3.32823 4.57893 3.26628L6.76643 1.07878L6.76647 1.07874C6.79712 1.04805 6.83353 1.02371 6.8736 1.00709C6.91367 0.990483 6.95662 0.981934 7 0.981934C7.04338 0.981934 7.08633 0.990483 7.1264 1.00709C7.16647 1.02371 7.20288 1.04805 7.23353 1.07874L7.23357 1.07878L9.42107 3.26628C9.48302 3.32823 9.51782 3.41225 9.51782 3.49986C9.51782 3.58746 9.48302 3.67148 9.42107 3.73343C9.35913 3.79538 9.27511 3.83018 9.1875 3.83018C9.0999 3.83018 9.01589 3.79538 8.95394 3.73344C8.95394 3.73344 8.95393 3.73343 8.95393 3.73343L7.51347 2.29243L7.33008 2.10896V2.36837V7.43736C7.33008 7.5249 7.2953 7.60886 7.2334 7.67076C7.1715 7.73266 7.08754 7.76743 7 7.76743C6.91246 7.76743 6.8285 7.73266 6.7666 7.67076C6.7047 7.60886 6.66992 7.5249 6.66992 7.43736V2.36837Z"
          fill="#13294B"
        />
        <path
          d="M3.0625 6.01743H2.95508V6.12486V11.3749V11.4823H3.0625H10.9375H11.0449V11.3749V6.12486V6.01743H10.9375H9.625C9.53746 6.01743 9.4535 5.98266 9.3916 5.92076C9.3297 5.85886 9.29492 5.7749 9.29492 5.68736C9.29492 5.59981 9.3297 5.51586 9.3916 5.45396C9.4535 5.39205 9.53746 5.35728 9.625 5.35728H10.9375C11.1411 5.35728 11.3363 5.43815 11.4803 5.5821C11.6242 5.72605 11.7051 5.92128 11.7051 6.12486V11.3749C11.7051 11.5784 11.6242 11.7737 11.4803 11.9176C11.3363 12.0616 11.1411 12.1424 10.9375 12.1424H3.0625C2.85893 12.1424 2.66369 12.0616 2.51974 11.9176C2.37579 11.7737 2.29492 11.5784 2.29492 11.3749V6.12486C2.29492 5.92128 2.37579 5.72605 2.51974 5.5821C2.66369 5.43815 2.85893 5.35728 3.0625 5.35728H4.375C4.46254 5.35728 4.5465 5.39205 4.6084 5.45396C4.6703 5.51586 4.70508 5.59981 4.70508 5.68736C4.70508 5.7749 4.6703 5.85885 4.6084 5.92076C4.5465 5.98266 4.46254 6.01743 4.375 6.01743H3.0625ZM6.66992 2.36837V2.10896L6.48653 2.29243L5.04607 3.73343C5.04607 3.73343 5.04606 3.73344 5.04606 3.73344C4.98411 3.79538 4.9001 3.83018 4.8125 3.83018C4.72489 3.83018 4.64088 3.79538 4.57893 3.73343C4.51698 3.67148 4.48218 3.58746 4.48218 3.49986C4.48218 3.41225 4.51698 3.32823 4.57893 3.26628L6.76643 1.07878L6.76647 1.07874C6.79712 1.04805 6.83353 1.02371 6.8736 1.00709C6.91367 0.990483 6.95662 0.981934 7 0.981934C7.04338 0.981934 7.08633 0.990483 7.1264 1.00709C7.16647 1.02371 7.20288 1.04805 7.23353 1.07874L7.23357 1.07878L9.42107 3.26628C9.48302 3.32823 9.51782 3.41225 9.51782 3.49986C9.51782 3.58746 9.48302 3.67148 9.42107 3.73343C9.35913 3.79538 9.27511 3.83018 9.1875 3.83018C9.0999 3.83018 9.01589 3.79538 8.95394 3.73344C8.95394 3.73344 8.95393 3.73343 8.95393 3.73343L7.51347 2.29243L7.33008 2.10896V2.36837V7.43736C7.33008 7.5249 7.2953 7.60886 7.2334 7.67076C7.1715 7.73266 7.08754 7.76743 7 7.76743C6.91246 7.76743 6.8285 7.73266 6.7666 7.67076C6.7047 7.60886 6.66992 7.5249 6.66992 7.43736V2.36837Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M3.0625 6.01743H2.95508V6.12486V11.3749V11.4823H3.0625H10.9375H11.0449V11.3749V6.12486V6.01743H10.9375H9.625C9.53746 6.01743 9.4535 5.98266 9.3916 5.92076C9.3297 5.85886 9.29492 5.7749 9.29492 5.68736C9.29492 5.59981 9.3297 5.51586 9.3916 5.45396C9.4535 5.39205 9.53746 5.35728 9.625 5.35728H10.9375C11.1411 5.35728 11.3363 5.43815 11.4803 5.5821C11.6242 5.72605 11.7051 5.92128 11.7051 6.12486V11.3749C11.7051 11.5784 11.6242 11.7737 11.4803 11.9176C11.3363 12.0616 11.1411 12.1424 10.9375 12.1424H3.0625C2.85893 12.1424 2.66369 12.0616 2.51974 11.9176C2.37579 11.7737 2.29492 11.5784 2.29492 11.3749V6.12486C2.29492 5.92128 2.37579 5.72605 2.51974 5.5821C2.66369 5.43815 2.85893 5.35728 3.0625 5.35728H4.375C4.46254 5.35728 4.5465 5.39205 4.6084 5.45396C4.6703 5.51586 4.70508 5.59981 4.70508 5.68736C4.70508 5.7749 4.6703 5.85885 4.6084 5.92076C4.5465 5.98266 4.46254 6.01743 4.375 6.01743H3.0625ZM6.66992 2.36837V2.10896L6.48653 2.29243L5.04607 3.73343C5.04607 3.73343 5.04606 3.73344 5.04606 3.73344C4.98411 3.79538 4.9001 3.83018 4.8125 3.83018C4.72489 3.83018 4.64088 3.79538 4.57893 3.73343C4.51698 3.67148 4.48218 3.58746 4.48218 3.49986C4.48218 3.41225 4.51698 3.32823 4.57893 3.26628L6.76643 1.07878L6.76647 1.07874C6.79712 1.04805 6.83353 1.02371 6.8736 1.00709C6.91367 0.990483 6.95662 0.981934 7 0.981934C7.04338 0.981934 7.08633 0.990483 7.1264 1.00709C7.16647 1.02371 7.20288 1.04805 7.23353 1.07874L7.23357 1.07878L9.42107 3.26628C9.48302 3.32823 9.51782 3.41225 9.51782 3.49986C9.51782 3.58746 9.48302 3.67148 9.42107 3.73343C9.35913 3.79538 9.27511 3.83018 9.1875 3.83018C9.0999 3.83018 9.01589 3.79538 8.95394 3.73344C8.95394 3.73344 8.95393 3.73343 8.95393 3.73343L7.51347 2.29243L7.33008 2.10896V2.36837V7.43736C7.33008 7.5249 7.2953 7.60886 7.2334 7.67076C7.1715 7.73266 7.08754 7.76743 7 7.76743C6.91246 7.76743 6.8285 7.73266 6.7666 7.67076C6.7047 7.60886 6.66992 7.5249 6.66992 7.43736V2.36837Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M3.0625 6.01743H2.95508V6.12486V11.3749V11.4823H3.0625H10.9375H11.0449V11.3749V6.12486V6.01743H10.9375H9.625C9.53746 6.01743 9.4535 5.98266 9.3916 5.92076C9.3297 5.85886 9.29492 5.7749 9.29492 5.68736C9.29492 5.59981 9.3297 5.51586 9.3916 5.45396C9.4535 5.39205 9.53746 5.35728 9.625 5.35728H10.9375C11.1411 5.35728 11.3363 5.43815 11.4803 5.5821C11.6242 5.72605 11.7051 5.92128 11.7051 6.12486V11.3749C11.7051 11.5784 11.6242 11.7737 11.4803 11.9176C11.3363 12.0616 11.1411 12.1424 10.9375 12.1424H3.0625C2.85893 12.1424 2.66369 12.0616 2.51974 11.9176C2.37579 11.7737 2.29492 11.5784 2.29492 11.3749V6.12486C2.29492 5.92128 2.37579 5.72605 2.51974 5.5821C2.66369 5.43815 2.85893 5.35728 3.0625 5.35728H4.375C4.46254 5.35728 4.5465 5.39205 4.6084 5.45396C4.6703 5.51586 4.70508 5.59981 4.70508 5.68736C4.70508 5.7749 4.6703 5.85885 4.6084 5.92076C4.5465 5.98266 4.46254 6.01743 4.375 6.01743H3.0625ZM6.66992 2.36837V2.10896L6.48653 2.29243L5.04607 3.73343C5.04607 3.73343 5.04606 3.73344 5.04606 3.73344C4.98411 3.79538 4.9001 3.83018 4.8125 3.83018C4.72489 3.83018 4.64088 3.79538 4.57893 3.73343C4.51698 3.67148 4.48218 3.58746 4.48218 3.49986C4.48218 3.41225 4.51698 3.32823 4.57893 3.26628L6.76643 1.07878L6.76647 1.07874C6.79712 1.04805 6.83353 1.02371 6.8736 1.00709C6.91367 0.990483 6.95662 0.981934 7 0.981934C7.04338 0.981934 7.08633 0.990483 7.1264 1.00709C7.16647 1.02371 7.20288 1.04805 7.23353 1.07874L7.23357 1.07878L9.42107 3.26628C9.48302 3.32823 9.51782 3.41225 9.51782 3.49986C9.51782 3.58746 9.48302 3.67148 9.42107 3.73343C9.35913 3.79538 9.27511 3.83018 9.1875 3.83018C9.0999 3.83018 9.01589 3.79538 8.95394 3.73344C8.95394 3.73344 8.95393 3.73343 8.95393 3.73343L7.51347 2.29243L7.33008 2.10896V2.36837V7.43736C7.33008 7.5249 7.2953 7.60886 7.2334 7.67076C7.1715 7.73266 7.08754 7.76743 7 7.76743C6.91246 7.76743 6.8285 7.73266 6.7666 7.67076C6.7047 7.60886 6.66992 7.5249 6.66992 7.43736V2.36837Z"
          stroke="#13294B"
          strokeWidth="0.214844"
        />
        <path
          d="M3.0625 6.01743H2.95508V6.12486V11.3749V11.4823H3.0625H10.9375H11.0449V11.3749V6.12486V6.01743H10.9375H9.625C9.53746 6.01743 9.4535 5.98266 9.3916 5.92076C9.3297 5.85886 9.29492 5.7749 9.29492 5.68736C9.29492 5.59981 9.3297 5.51586 9.3916 5.45396C9.4535 5.39205 9.53746 5.35728 9.625 5.35728H10.9375C11.1411 5.35728 11.3363 5.43815 11.4803 5.5821C11.6242 5.72605 11.7051 5.92128 11.7051 6.12486V11.3749C11.7051 11.5784 11.6242 11.7737 11.4803 11.9176C11.3363 12.0616 11.1411 12.1424 10.9375 12.1424H3.0625C2.85893 12.1424 2.66369 12.0616 2.51974 11.9176C2.37579 11.7737 2.29492 11.5784 2.29492 11.3749V6.12486C2.29492 5.92128 2.37579 5.72605 2.51974 5.5821C2.66369 5.43815 2.85893 5.35728 3.0625 5.35728H4.375C4.46254 5.35728 4.5465 5.39205 4.6084 5.45396C4.6703 5.51586 4.70508 5.59981 4.70508 5.68736C4.70508 5.7749 4.6703 5.85885 4.6084 5.92076C4.5465 5.98266 4.46254 6.01743 4.375 6.01743H3.0625ZM6.66992 2.36837V2.10896L6.48653 2.29243L5.04607 3.73343C5.04607 3.73343 5.04606 3.73344 5.04606 3.73344C4.98411 3.79538 4.9001 3.83018 4.8125 3.83018C4.72489 3.83018 4.64088 3.79538 4.57893 3.73343C4.51698 3.67148 4.48218 3.58746 4.48218 3.49986C4.48218 3.41225 4.51698 3.32823 4.57893 3.26628L6.76643 1.07878L6.76647 1.07874C6.79712 1.04805 6.83353 1.02371 6.8736 1.00709C6.91367 0.990483 6.95662 0.981934 7 0.981934C7.04338 0.981934 7.08633 0.990483 7.1264 1.00709C7.16647 1.02371 7.20288 1.04805 7.23353 1.07874L7.23357 1.07878L9.42107 3.26628C9.48302 3.32823 9.51782 3.41225 9.51782 3.49986C9.51782 3.58746 9.48302 3.67148 9.42107 3.73343C9.35913 3.79538 9.27511 3.83018 9.1875 3.83018C9.0999 3.83018 9.01589 3.79538 8.95394 3.73344C8.95394 3.73344 8.95393 3.73343 8.95393 3.73343L7.51347 2.29243L7.33008 2.10896V2.36837V7.43736C7.33008 7.5249 7.2953 7.60886 7.2334 7.67076C7.1715 7.73266 7.08754 7.76743 7 7.76743C6.91246 7.76743 6.8285 7.73266 6.7666 7.67076C6.7047 7.60886 6.66992 7.5249 6.66992 7.43736V2.36837Z"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="0.214844"
        />
        <path
          d="M3.0625 6.01743H2.95508V6.12486V11.3749V11.4823H3.0625H10.9375H11.0449V11.3749V6.12486V6.01743H10.9375H9.625C9.53746 6.01743 9.4535 5.98266 9.3916 5.92076C9.3297 5.85886 9.29492 5.7749 9.29492 5.68736C9.29492 5.59981 9.3297 5.51586 9.3916 5.45396C9.4535 5.39205 9.53746 5.35728 9.625 5.35728H10.9375C11.1411 5.35728 11.3363 5.43815 11.4803 5.5821C11.6242 5.72605 11.7051 5.92128 11.7051 6.12486V11.3749C11.7051 11.5784 11.6242 11.7737 11.4803 11.9176C11.3363 12.0616 11.1411 12.1424 10.9375 12.1424H3.0625C2.85893 12.1424 2.66369 12.0616 2.51974 11.9176C2.37579 11.7737 2.29492 11.5784 2.29492 11.3749V6.12486C2.29492 5.92128 2.37579 5.72605 2.51974 5.5821C2.66369 5.43815 2.85893 5.35728 3.0625 5.35728H4.375C4.46254 5.35728 4.5465 5.39205 4.6084 5.45396C4.6703 5.51586 4.70508 5.59981 4.70508 5.68736C4.70508 5.7749 4.6703 5.85885 4.6084 5.92076C4.5465 5.98266 4.46254 6.01743 4.375 6.01743H3.0625ZM6.66992 2.36837V2.10896L6.48653 2.29243L5.04607 3.73343C5.04607 3.73343 5.04606 3.73344 5.04606 3.73344C4.98411 3.79538 4.9001 3.83018 4.8125 3.83018C4.72489 3.83018 4.64088 3.79538 4.57893 3.73343C4.51698 3.67148 4.48218 3.58746 4.48218 3.49986C4.48218 3.41225 4.51698 3.32823 4.57893 3.26628L6.76643 1.07878L6.76647 1.07874C6.79712 1.04805 6.83353 1.02371 6.8736 1.00709C6.91367 0.990483 6.95662 0.981934 7 0.981934C7.04338 0.981934 7.08633 0.990483 7.1264 1.00709C7.16647 1.02371 7.20288 1.04805 7.23353 1.07874L7.23357 1.07878L9.42107 3.26628C9.48302 3.32823 9.51782 3.41225 9.51782 3.49986C9.51782 3.58746 9.48302 3.67148 9.42107 3.73343C9.35913 3.79538 9.27511 3.83018 9.1875 3.83018C9.0999 3.83018 9.01589 3.79538 8.95394 3.73344C8.95394 3.73344 8.95393 3.73343 8.95393 3.73343L7.51347 2.29243L7.33008 2.10896V2.36837V7.43736C7.33008 7.5249 7.2953 7.60886 7.2334 7.67076C7.1715 7.73266 7.08754 7.76743 7 7.76743C6.91246 7.76743 6.8285 7.73266 6.7666 7.67076C6.7047 7.60886 6.66992 7.5249 6.66992 7.43736V2.36837Z"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="0.214844"
        />
      </g>
    </SvgIcon>
  );
}
