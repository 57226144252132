export const indigo = {
  900: "#13294B", //This is GNE A1 -- use for headings, button text, icons, etc.
  800: "#0A2D69",
  700: "#003087", //This is GNE A2 -- Genentech Blue
  600: "#2446A7",
  500: "#485CC7", //This is GNE A3
  400: "#7285D6",
  300: "#9BAEE4", //This is GNE A4
  200: "#BECBEE",
  100: "#E1E7F7", //This is GNE A5
  50: "#F0F3FB",
};

export const blue = {
  900: "#004677", //This is GNE B1
  800: "#0644A2",
  700: "#0B41CD", //This is GNE B2 -- Roche Blue
  600: "#0672D7",
  500: "#00A3E0", //This is GNE B3 -- Myelin Blue
  400: "#47B6E4",
  300: "#8DC8E8", //This is GNE B4
  200: "#B5DBF0",
  100: "#DDEEF8", //This is GNE B5
  50: "#EEF7FC",
};

export const purple = {
  900: "#470A68", //This is GNE C1
  800: "#57146F",
  700: "#671E75", //This is GNE C2
  600: "#7A3387",
  500: "#8C4799", //This is GNE C3
  400: "#A372AF",
  300: "#BA9CC5", //This is GNE C4
  200: "#D2BFDA",
  100: "#EAE1EE", //This is GNE C5
  50: "#F5F0F7",
};

export const pink = {
  900: "#51284F", //This is GNE D1
  800: "#622553",
  700: "#722257", //This is GNE D2
  600: "#911C6E",
  500: "#Af1685", //This is GNE D3
  400: "#C75FB1",
  300: "#DEA8DD", //This is GNE D4
  200: "#E9C5E8",
  100: "#F3E1F3", //This is GNE D5
  50: "#F9F0F9",
};

export const red = {
  900: "#6A2C3E", //This is GNE E1
  800: "#782939",
  700: "#862633", //This is GNE E2
  600: "#A71B31",
  500: "#C8102E", //This is GNE E3
  400: "#DA6C7E",
  300: "#ECC7CD", //This is GNE E4
  200: "#F2D9DD",
  100: "#F8EBED", //This is GNE E5
  50: "#FCF5F6",
};

export const orange = {
  900: "#823B34", //This is GNE F1
  800: "#AF401D",
  700: "#DC4405", //This is GNE F2
  600: "#EE6303",
  500: "#FF8200", //This is GNE F3
  400: "#FEAD2C",
  300: "#FCD757", //This is GNE F4, and really more of a yellow
  200: "#FDE694",
  100: "#FEF4D1", //This is GNE F5, and really more light yellow
  50: "#FFFAE8",
};

export const green = {
  900: "#005844", //This is GNE G1
  800: "#00722D",
  700: "#008C15", //This is GNE G2
  600: "#3A9B33",
  500: "#74AA50", //This is GNE G3
  400: "#9CBF72",
  300: "#C3D393", //This is GNE G4
  200: "#D7E3B6",
  100: "#EAF3D9", //This is GNE G5
  50: "#F5F9EC",
};

export const grey = {
  900: "#25282A", //This is GNE H1
  800: "#3D4042",
  700: "#545859", //This is GNE H2 -- use for button text
  600: "#797D7E",
  500: "#9EA2A2", //This is GNE H3 -- use for light text
  400: "#B7BBBB",
  300: "#D0D3D4", //This is GNE H4
  200: "#E0E2E2",
  100: "#EFF0F0", //This is GNE H5 -- use for outlines and rules
  50: "#F7F8F8", //This is the app background color
};
