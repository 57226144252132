import { Close } from "@mui/icons-material";
import { Box, BoxProps, IconButton, Modal, Typography as T } from "@mui/material";

type ModalHeaderProps = {
  handleModalClose: () => void;
  title: string;
};

const ModalHeader = ({ handleModalClose, title, ...props }: ModalHeaderProps & BoxProps): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
        textAlign: "center",
        height: "56px",
        padding: "12px",
        ...props.sx,
      }}
    >
      <T fontWeight={600} fontSize={20}>
        {title}
      </T>
      <div style={{ position: "absolute", right: "6px", top: "10px" }}>
        <IconButton onClick={handleModalClose}>
          <Close />
        </IconButton>
      </div>
    </Box>
  );
};

type StyledModalProps = {
  open: boolean;
  handleModalClose: () => void;
  title: string;
  children: JSX.Element;
  actions?: JSX.Element;
  headerBox?: BoxProps;
};

const StyledModal = ({
  open,
  handleModalClose,
  title,
  children,
  actions,
  headerBox,
}: StyledModalProps): JSX.Element => {
  return (
    <Modal open={open} onClose={handleModalClose}>
      <>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            py: "4px",
            borderRadius: "8px",
            minHeight: 300,
            pb: "30px",
          }}
        >
          <ModalHeader title={title} handleModalClose={handleModalClose} {...headerBox} />
          <Box sx={{ overflow: "scroll", p: 4, maxHeight: 600 }}>{children}</Box>
          {actions && <Box width="100%">{actions}</Box>}
        </Box>
      </>
    </Modal>
  );
};

export default StyledModal;
