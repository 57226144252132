import { useState } from "react";
import { Typography as T, Stack, Button, Box, styled, LinearProgress, TextField } from "@mui/material";
import { getSearchFiltersForTableList } from "./utils";
import { RowType } from "../../models/search";
import { StyledModal, TableList } from "../../components/common";
import useSavedSearch from "../../contexts/useSavedSearch";
import emitSnackbar from "../../emitSnackbar";
import { useSearchId } from "../../contexts/SearchIdProvider";
import { useFilterContext } from "../../contexts/FilterProvider";

const BUTTON_WIDTH = "115px";
const MAX_VIEW_NAME_LENGTH = 30;

const StyledCancelButton = styled(Button)({
  backgroundColor: "rgba(217, 217, 217, 0.25)",
  color: "rgba(0, 0, 0, 0.8)",
  border: "none",
  justifyContent: "center",
  width: BUTTON_WIDTH,
  "&:hover": {
    border: "none",
    backgroundColor: "rgba(217, 217, 217, 0.50)",
  },
});

const StyledSaveButton = styled(Button)({
  color: "rgba(11, 65, 205, 0.9)",
  fontWeight: 700,
  backgroundColor: "rgba(11, 65, 205, 0.15)",
  justifyContent: "center",
  width: BUTTON_WIDTH,
  "&:hover": {
    outline: "none",
    backgroundColor: "rgba(11, 65, 205, 0.25)",
  },
});

type SaveFiltersModalProps = {
  open: boolean;
  handleModalClose: () => void;
};

const SaveFiltersModal = ({ open, handleModalClose }: SaveFiltersModalProps): JSX.Element => {
  const { filterState } = useFilterContext();
  const { setSearchId } = useSearchId();
  const { onSaveSearch, isLoading, isValidSavedFilterName } = useSavedSearch();
  const [viewName, setViewName] = useState<string>("");

  if (!filterState) return <div />;

  const filters: RowType[] = getSearchFiltersForTableList(filterState);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const newValue = event.target.value;
    setViewName(newValue);
  };

  const viewNameValidationProps = () => {
    return isValidSavedFilterName(viewName) ? {} : { error: true, helperText: "Filter name already exists" };
  };

  const allowFilterSave = () => {
    return viewName.trim().length > 0 && activeFilters.length && !isLoading && isValidSavedFilterName(viewName);
  };

  const onCloseRequested = (): void => {
    // Reset view name when closing modal
    setViewName("");
    handleModalClose();
  };

  const handleSaveView = () => {
    // Mutation to save the filter search criteria
    if (filterState) {
      onSaveSearch(
        { viewName, filters: filterState },
        {
          onSuccess: (response) => {
            setSearchId(response.search_id);
            // Reset the view name before closing the modal
            setViewName("");
            // On success, show the snackbar and auto close the modal
            emitSnackbar(`Search filter "${viewName}" has been saved successfully`, "success");
            handleModalClose();
          },
          onError: () => {
            // On error, show the user an error message
            emitSnackbar(`Error saving search filter "${viewName}". Please try again later.`, "error");
          },
        },
      );
    }
  };

  const activeFilters = [...filters];

  return (
    <StyledModal
      title="Save Filters"
      open={open}
      handleModalClose={onCloseRequested}
      actions={
        <Stack direction="row" spacing={2} justifyContent="center">
          <StyledCancelButton variant="outlined" onClick={onCloseRequested} disabled={isLoading}>
            Cancel
          </StyledCancelButton>
          <StyledSaveButton variant="contained" onClick={handleSaveView} disabled={!allowFilterSave()}>
            Save
          </StyledSaveButton>
        </Stack>
      }
    >
      {activeFilters.length ? (
        <div>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="Filter name"
            value={viewName}
            onChange={handleChange}
            inputProps={{
              maxLength: MAX_VIEW_NAME_LENGTH,
            }}
            helperText={`${viewName.length}/${MAX_VIEW_NAME_LENGTH} characters`}
            {...viewNameValidationProps()}
          />
          {isLoading && <LinearProgress />}
          <Box mt="10px" display={"flex"} justifyContent={"center"}>
            <TableList rows={activeFilters} />
          </Box>
        </div>
      ) : (
        <Box mt="10px" display={"flex"} justifyContent={"center"}>
          <T>No filters selected</T>
        </Box>
      )}
    </StyledModal>
  );
};

export default SaveFiltersModal;
