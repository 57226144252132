import StyledIconButton from "./StyledIconButton";
import { useState } from "react";
import { Check, ContentCopy } from "@mui/icons-material";
import { SxProps, Tooltip } from "@mui/material";

// amount of time to show "success" button
const DELAY_TIME = 3000;

type CopyTextButtonProps = {
  text: string;
  tooltipText?: string;
  buttonSx?: SxProps;
  iconSx?: SxProps;
};

const CopyTextButton = ({ text, tooltipText, buttonSx, iconSx }: CopyTextButtonProps): JSX.Element => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, DELAY_TIME);
  };

  return (
    <Tooltip title={copied ? "Copied!" : tooltipText || "Copy"}>
      <div>
        <StyledIconButton onClick={handleClick} sx={buttonSx}>
          {copied ? <Check fontSize="inherit" sx={iconSx} /> : <ContentCopy fontSize="inherit" sx={iconSx} />}
        </StyledIconButton>
      </div>
    </Tooltip>
  );
};

export default CopyTextButton;
