export enum AUTH_ACTION_TYPES {
  SET_JWT = "auth/SET_JWT",
  UPDATE_APP = "auth/UPDATE_APP",
}

export type JWT = {
  token: string | null;
  role: string[] | null;
  app: string | null;
  isLoading: boolean;
};
