import { SvgIcon } from "@mui/material";

export default function IconFilter() {
  return (
    <SvgIcon width={"24px"} sx={{ margin: "4px", "& path": { fill: "#757575" } }}>
      <svg viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2077_1250)">
          <path
            d="M14.5 2H12.45C12.22 0.86 11.21 0 10 0C8.79 0 7.78 0.86 7.55 2H0.5C0.22 2 0 2.22 0 2.5C0 2.78 0.22 3 0.5 3H7.55C7.78 4.14 8.79 5 10 5C11.21 5 12.22 4.14 12.45 3H14.5C14.78 3 15 2.78 15 2.5C15 2.22 14.78 2 14.5 2ZM10 4C9.17 4 8.5 3.33 8.5 2.5C8.5 1.67 9.17 1 10 1C10.83 1 11.5 1.67 11.5 2.5C11.5 3.33 10.83 4 10 4ZM14.5 9H7.45C7.22 7.86 6.21 7 5 7C3.79 7 2.78 7.86 2.55 9H0.5C0.22 9 0 9.22 0 9.5C0 9.78 0.22 10 0.5 10H2.55C2.78 11.14 3.79 12 5 12C6.21 12 7.22 11.14 7.45 10H14.5C14.78 10 15 9.78 15 9.5C15 9.22 14.78 9 14.5 9ZM5 11C4.17 11 3.5 10.33 3.5 9.5C3.5 8.67 4.17 8 5 8C5.83 8 6.5 8.67 6.5 9.5C6.5 10.33 5.83 11 5 11Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_2077_1250">
            <rect width="15" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
