import styled from "@emotion/styled";
import { InfoOutlined } from "@mui/icons-material";
import { tooltipClasses, TooltipProps, Tooltip, SxProps } from "@mui/material";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 500,
      fontSize: "0.875rem",
      border: "1px solid #dadde9",
      padding: 0,
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        backgroundColor: "white",
        border: "1px solid #dadde9",
      },
    },
  };
});

const CardTooltip = ({ tooltipContent, sx }: { tooltipContent?: React.ReactNode; sx?: SxProps }) => {
  return (
    <StyledTooltip title={tooltipContent} arrow enterTouchDelay={250}>
      <InfoOutlined sx={{ "&.MuiSvgIcon-root": { width: 20, height: 20, verticalAlign: "text-bottom" }, ...sx }} />
    </StyledTooltip>
  );
};

export default CardTooltip;
