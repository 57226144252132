import { Box, Typography } from "@mui/material";
import { ChartKey, ListEvent, TypeTable, MedInfoChartCardTable, CommercialChartCardTable } from "./common";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { selectAuth } from "../../store/authorization/auth.selector";

export default function ListItem({
  item,
  selected,
  onClick: handleClick,
}: {
  item: ChartKey;
  selected: boolean;
  onClick: (event: ListEvent) => void;
}) {
  const { role } = useSelector(selectAuth);

  const charts = role?.includes("commercial")
    ? CommercialChartCardTable
    : role?.includes("med_info")
      ? MedInfoChartCardTable
      : CommercialChartCardTable;

  const { type, title, description } = charts[item];
  const { label, Graphic } = TypeTable[type];

  return (
    <Box
      position="relative"
      onClick={({ ctrlKey, metaKey }) => {
        handleClick({ merge: ctrlKey || metaKey, item });
      }}
      display="flex"
      height="4rem"
      paddingX="0.75rem"
      alignItems="center"
      gap="0.75rem"
      borderBottom="1px solid #d9d9d9"
    >
      <Box
        width="4rem"
        height="2.75rem"
        flexShrink="0"
        display="flex"
        alignItems="center"
        border="1px solid rgba(0,0,0,0.2)"
        borderRadius="0.25rem"
      >
        <Graphic />
      </Box>
      <Box display="flex" flexDirection="column" overflow="hidden">
        <Typography
          color="rgba(0,0,0,0.7)"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          fontSize="12px"
          fontWeight="bold"
        >
          {label} · {title}
        </Typography>
        <Typography color="rgba(0,0,0,0.6)" fontSize="12px">
          {description}
        </Typography>
      </Box>
      {selected && (
        <Box
          position="absolute"
          sx={{
            opacity: 0.4,
            top: -1.5,
            bottom: -1.5,
            left: 0,
            right: 0,
            border: "2px solid #004677",
            borderRadius: "4px",
          }}
        />
      )}
    </Box>
  );
}
