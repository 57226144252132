import { Stack } from "@mui/material";
import { Bar } from "@nivo/bar";
import { AutoSizer } from "react-virtualized";
import { DataDistribution } from "../../models/datasource";
import ChartLabel from "./ChartLabel";
import ChartToolTip from "./ChartTooltip";
import RoundedBarComponent from "./RoundedBarComponent";
import { useRef } from "react";

type HEX = `#${string}`;

const NUM_DATASOURCES_OUTSIDE_OTHER_CATEGORY = 4;
const colors: HEX[] = ["#0B41CD", "#00A3E0", "#8DC8E8", "#F28200", "#FCCA90"];

// orders data in order of largest to smallest, then groups anything
// above NUM_DATASOURCES_OUTSIDE_OTHER_CATEGORY within an "other" category
const buildChartData = (data: DataDistribution[]): [Record<string, number>, number] => {
  const sortedData = data
    .sort((a, b) => (a.record_count > b.record_count ? -1 : 1))
    .map((d) => ({ name: d.name, record_count: d.record_count }));

  let total = 0;
  const resultObj: Record<string, number> = {};
  // if we have NUM_DATASOURCES_OUTSIDE_OTHER_CATEGORY + 1 records total, we want to
  // render that final record not as "Other"
  if (sortedData.length <= NUM_DATASOURCES_OUTSIDE_OTHER_CATEGORY + 1) {
    sortedData.forEach((d) => {
      total += d.record_count;
      return (resultObj[d.name] = d.record_count);
    });
    return [resultObj, total];
  }

  const totalOtherRecords = sortedData
    .slice(NUM_DATASOURCES_OUTSIDE_OTHER_CATEGORY)
    .map((d) => d.record_count)
    .reduce((total, n) => total + n, 0);

  total += totalOtherRecords;

  sortedData.slice(0, NUM_DATASOURCES_OUTSIDE_OTHER_CATEGORY).forEach((d) => {
    total += d.record_count;
    return (resultObj[d.name] = d.record_count);
  });

  return [{ ...resultObj, Other: totalOtherRecords }, total];
};

// Per the Nivo docs, the only way to get consistent coloring is to embed the desired colors with the data itself
// The convention from Nivo is to add an element with the suffix "Color", eg: { "SomeDataLabelColor": "#123456" }, to the data payload
// and update the <Bar> colors attr to a function that extracts the color from the chart data
const spliceBarChartInlineColor = (formattedData: Record<string, number>): Record<string, number | HEX> => {
  const response: Record<string, number | HEX> = {};

  Object.keys(formattedData).forEach((val, i) => {
    response[val] = formattedData[val];
    response[val + "Color"] = colors[i % 5];
  });

  return response;
};

type LegendProps = {
  formattedData: Record<string, number>;
  total: number;
};
// separate legend for chart to prevent formatting issues present in
// Nivo implementation of the legend
const Legend = ({ formattedData, total }: LegendProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      {Object.keys(formattedData).map((d, i) => (
        <ChartLabel key={d} label={d} value={formattedData[d]} color={colors[i]} total={total} />
      ))}
    </Stack>
  );
};

type DataDistributionChartProps = {
  data: DataDistribution[];
};

const DataDistributionChart = ({ data }: DataDistributionChartProps): JSX.Element => {
  const self = useRef<HTMLDivElement>(null);
  const [formattedData, total] = buildChartData(data);
  const dataWithInlineColors = spliceBarChartInlineColor(formattedData);

  return (
    <div ref={self}>
      <div style={{ height: 25 }}>
        <AutoSizer>
          {({ height, width }) => (
            <Bar
              height={height}
              width={width}
              data={[dataWithInlineColors]}
              // Reverse the keys here since the bar has the 'reverse' prop below
              keys={Object.keys(formattedData).reverse()}
              layout="horizontal"
              colors={({ id, data }) => String(data[`${id}Color`])}
              barComponent={RoundedBarComponent}
              // This allows us to workaround the ordering of layers being rendered for the "fish scales"
              reverse
              enableGridX={false}
              enableGridY={false}
              animate={false}
              enableLabel={false}
              tooltip={({ id, value, color }) => (
                <ChartToolTip
                  id={id as string}
                  value={value}
                  color={color}
                  total={total}
                  toolTipWidth={150}
                  defaultPos={{ top: 10, left: 10 }}
                />
              )}
            />
          )}
        </AutoSizer>
      </div>
      <div style={{ width: "100%", paddingTop: 12, paddingRight: 12 }}>
        <Legend formattedData={formattedData} total={total} />
      </div>
    </div>
  );
};

export default DataDistributionChart;
