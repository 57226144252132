import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, SxProps, Typography as T } from "@mui/material";
import { EditVerbatimFeedback, FeedbackBase, FeedbackItems, FeedbackSubmission } from "./FeedbackState";
import { useAtom } from "jotai";
import { useRef } from "react";
import { SearchResults } from "../../../models/search";

const ThumbStyle: SxProps = {
  verticalAlign: "sub",
  fill: "#B7BBBB",
  cursor: "pointer",
  "&:hover": {
    fill: "#13294B",
  },
  "&.selected": {
    fill: "#003087",
  },
};

export interface VerbatimCardFeedbackProps<T> {
  // Note: Since this is going to be used for more than one model type, the consumer will be responsible for identifying the "ID" separate from the record itself
  record_id: string;
  record: T;
}

export default function VerbatimCardFeedback<T>({ record, record_id }: VerbatimCardFeedbackProps<T>) {
  const [userFeedback] = useAtom(FeedbackItems);
  const [, setEditVerbatimFeedback] = useAtom(EditVerbatimFeedback);
  const element = useRef<HTMLDivElement>(null);

  const feedback = userFeedback.find((f) => f.document_id == record_id);

  // NOTE: We are deliberately loading the feedback object last so that any saved feedback will not get its state overwritten
  const handleThumbsUp = () => {
    setEditVerbatimFeedback({
      feedback: {
        document_id: record_id,
        ...FeedbackBase,
        sentiment: 1, // positive
        ...feedback,
      },
      document: record,
    } as FeedbackSubmission<T>);
  };

  const handleThumbsDn = () => {
    setEditVerbatimFeedback({
      feedback: {
        document_id: record_id,
        ...FeedbackBase,
        sentiment: -1, // negative
        ...feedback,
      },
      document: record,
    } as FeedbackSubmission<T>);
  };

  return (
    <Box
      ref={element}
      sx={{
        background: "#eff0f0",
        padding: "0.5em 1em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <T sx={{ display: "inline-block", color: "#9EA2A2", fontSize: "11px" }}>
          Was this information accurate and helpful?
        </T>
      </Box>
      <Box sx={{ display: "flex", gap: "0.75em" }}>
        <ThumbUp sx={ThumbStyle} className={feedback?.sentiment === 1 ? "selected" : ""} onClick={handleThumbsUp} />
        <ThumbDown sx={ThumbStyle} className={feedback?.sentiment === -1 ? "selected" : ""} onClick={handleThumbsDn} />
      </Box>
    </Box>
  );
}
