import { useState, useRef } from "react";
import { Box, BoxProps, Button, Chip, Collapse, Grid, Paper, Stack, Typography } from "@mui/material";
import { OutcomeRecord } from "../../../models/search";
import VerbatimCardFeedback from "./VerbatimCardFeedback";

type TextSectionType = {
  title: string;
  content: string;
};

const TextSection = ({ title, content, ...props }: TextSectionType & BoxProps) => {
  return (
    <Box {...props} sx={{ marginBottom: "12px", ...props.sx }}>
      <Typography fontSize="10px" sx={{ color: "text.disabled", textTransform: "uppercase" }}>
        {title}
      </Typography>
      <Typography fontSize={14}>{content}</Typography>
    </Box>
  );
};

const LabeledText = ({ title, content, ...props }: TextSectionType & BoxProps) => {
  return (
    <Box {...props}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontSize="10px" color="rgba(90, 91, 93, 0.6)" textTransform="uppercase" marginTop="4px">
          {title}
        </Typography>
        <Typography fontSize="13px" marginTop="3px" textAlign="right" maxWidth="120px">
          {content}
        </Typography>
      </Stack>
    </Box>
  );
};

const sentimentColorMap: Record<string, string> = {
  tailwinds: "#74AA50",
  opportunity: "#8DC8E8",
  headwinds: "#BA3E52",
};

type OutcomeCardType = {
  record: OutcomeRecord;
};

export default function OutcomeCard({ record }: OutcomeCardType): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const summaryBoxRef = useRef<HTMLDivElement>();

  return (
    <Paper elevation={1} sx={{ borderRadius: "12px", overflow: "hidden" }}>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            fontWeight={500}
            fontSize="10px"
            ml="24px"
            paddingTop="2px"
            sx={{ display: "flex", alignItems: "center", lineHeight: "20px" }}
          >
            {record.data_source}
          </Typography>
          <Chip
            label={record.sentiment || "unknown"}
            sx={{
              backgroundColor: record.sentiment
                ? sentimentColorMap[record.sentiment.toLowerCase()]
                : "rgba(255, 255, 255, 0.5",
              color: Object.keys(sentimentColorMap).includes(record.sentiment?.toLowerCase())
                ? "white"
                : "primary.dark",
              borderRadius: "0 0 0 8px",
              textTransform: "uppercase",
              letterSpacing: "0.05em",
              lineHeight: 1,
              width: "100px",
              height: "24px",
            }}
          />
        </Stack>
        <div style={{ padding: "14px 0px", marginLeft: "24px" }}>
          <Chip
            label={record.topics || "No Topic"}
            sx={{
              backgroundColor: "primary.light",
              color: "primary.dark",
              mr: "8px",
              fontSize: "13px",
              fontWeight: "600",
              lineHeight: 1,
            }}
          />
          <Typography
            fontWeight={500}
            fontSize="10px"
            paddingTop="0.5em"
            sx={{ display: "flex", alignItems: "center", lineHeight: "20px" }}
          >
            {record.outcome_category}
          </Typography>
        </div>
        <Box px="24px">
          <Collapse collapsedSize={"5em"} in={expanded}>
            <Box ref={summaryBoxRef}>
              <TextSection title="Feedback" content={record.retro_comments} sx={{ lineHeight: "19.5px" }} />
            </Box>
          </Collapse>
          <Collapse collapsedSize={0} in={expanded}>
            <TextSection title="Outcome Description" content={record.outcome_description} />
            {Boolean(record.key_impact_measurement) && (
              <LabeledText title="Key Measure" content={record.key_impact_measurement ?? ""} />
            )}
          </Collapse>
          <Grid container>
            <Grid item flexGrow={1} />
            <Grid item xs="auto">
              <Button
                variant="text"
                size="small"
                sx={{
                  fontSize: "10px",
                  color: "primary.dark",
                  marginTop: "8px",
                  minWidth: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                onClick={() => setExpanded(!expanded)}
              >
                {`Show ${expanded ? "Less" : "More"}`}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <VerbatimCardFeedback record={record} record_id={record._id} />
    </Paper>
  );
}
