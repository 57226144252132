import { atom, useAtom } from "jotai";
import useDeepchat from "../../contexts/useDeepchat";
import { ChatResponse } from "../../components/Deepchat/models";
import { useEffect } from "react";

//ATOMS
export const historyLoadingAtom = atom(true);
export const historyAtom = atom<ChatResponse[][]>([]);

export const useRecentThreads = () => {
  const Deepchat = useDeepchat();
  const [historyLoading, setHistoryLoading] = useAtom(historyLoadingAtom);
  const [history, setHistory] = useAtom(historyAtom);

  useEffect(() => {
    Deepchat.getHistory().then((_history) => {
      setHistoryLoading(false);
      setHistory(_history);
    });
  }, []);

  return {
    historyLoading,
    history,
  };
};
