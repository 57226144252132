import { InputAdornment, TextField, Typography } from "@mui/material";
import { StyledExpansionPanel } from "../../components/common";
import { FilterResponse, TreeFilter, NationalAccountFilter } from "../../models/filter";
import { SearchFilters, SearchResults } from "../../models/search";
import { useRef, useState } from "react";
import { getFilter } from "./FilterFunctions";
import { Clear, Search } from "@mui/icons-material";
import TreeSelectFilterVirtual, { TreeFilterNode } from "./TreeSelectFilterVirtual";

type NationalAccountTreeFilterProps = {
  filterOptions: FilterResponse;
  filterState: SearchFilters;
  appliedFilterState: SearchFilters;
  disabled?: boolean;
  handleFilterChange: (filterName: keyof SearchResults, newValues: string[]) => void;
};

export default function NationalAccountTreeFilter({
  filterOptions,
  filterState,
  disabled,
  appliedFilterState,
  handleFilterChange,
}: NationalAccountTreeFilterProps) {
  const model = useRef<TreeFilterNode[]>([]);
  const [input, setInput] = useState<string>("");

  if (!filterOptions.national_account_filters) {
    return <></>;
  }

  const handleClear = () => setInput("");

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleFilterClick = (column: keyof SearchResults, filterVal: string, isSelected: boolean) => {
    const selected = getFilter(filterState, column);

    if (!isSelected) {
      handleFilterChange(column, [...selected, filterVal]);
    } else {
      const newFilterValues = selected.filter((val) => val !== filterVal);
      handleFilterChange(column, newFilterValues);
    }
  };

  const { state } = mapDataToFilterModel(
    model.current,
    filterOptions.national_account_filters,
    filterState,
    appliedFilterState,
  );

  model.current = state;

  const selectedCount = getFilter(filterState, "corp_account").length;

  return (
    <div style={{ marginTop: "4px" }}>
      <StyledExpansionPanel
        title={
          <>
            <Typography display="inline" fontWeight={600}>
              Corp. Account
            </Typography>
            {selectedCount ? <> &middot; {selectedCount}</> : ""}
          </>
        }
        compressed
      >
        <TextField
          variant="standard"
          value={input}
          onChange={handleInputChange}
          placeholder={`Search...`}
          sx={{ marginLeft: "12px", width: "calc(100% - 12px)" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: Boolean(input.length) && (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                <Clear onClick={handleClear} sx={{ "&.MuiSvgIcon-root": { width: 16, height: 16 } }} />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ padding: "0 4px 0 8px", minHeight: 0 }}>
          <TreeSelectFilterVirtual
            disabled={disabled}
            height={325}
            model={model.current}
            handleFilterStateChange={handleFilterClick}
            filterChildrenByString={input}
          />
        </div>
      </StyledExpansionPanel>
    </div>
  );
}

const mapDataToFilterModel = (
  state: TreeFilterNode[],
  filter: TreeFilter<NationalAccountFilter>,
  filterState: SearchFilters,
  appliedFilterState: SearchFilters,
) => {
  // NOTE: These controls are being built as one off solutions, ideally we will transition to a more uniform hierarchal model that wont require manual mapping for each tree filter control
  // Generating a hierarchical model that can perform reference invalidation so that display elements downstream can
  // selectively skip re-rendering when there is no update to a node or any of its descendants

  const nodesAreEqual = (a: TreeFilterNode, b: TreeFilterNode) => {
    // We are are testing here to see if the selection state has changed, the "metadata" of the nodes should not be changes (eg: title, column)
    return a.applied === b.applied && a.selected === b.selected && a.indeterminate === b.indeterminate;
  };

  // Generate a new parent level array
  const newNodeCollection: TreeFilterNode[] = [];

  let hasChanges = false;
  (filter?.filter_values || []).forEach((_filter) => {
    const column: keyof SearchResults = "corp_account"; // I suspect our use of `keyof` will break down when we expand the search filters interface
    const _filter_value = _filter.national_account_type;

    // Generate a new node from the inbound data
    const newNode = {
      key: `${column}-${_filter_value}`,
      column: column,
      label: _filter_value,
      value: _filter_value,
      get selected() {
        return getFilter(filterState, column).includes(_filter_value);
      },
      get applied() {
        return getFilter(appliedFilterState, column).includes(_filter_value);
      },
      allow_select: false, // HACK: We dont allow the parent label to be selected for NAF
      indeterminate: false,
      children: [],

      open: false, // TODO: determine if this is where we want to maa
    } as TreeFilterNode;

    // Attempt to locate an existing node
    const existingNode = state.find((_existingNode) => _existingNode.key == newNode.key);

    // Assemble the child node elements, checking for changes along the way
    const newChildNodeCollection: TreeFilterNode[] = [];

    let hasChildStateChanges = false;
    _filter.corp_account_types.forEach((_child_filter) => {
      const column: keyof SearchResults = "corp_account";
      const newChildNode = {
        key: `${column}-${_child_filter}`,
        column: column,
        label: _child_filter,
        value: _child_filter,

        get selected() {
          return getFilter(filterState, column).includes(_child_filter);
        },
        get applied() {
          return getFilter(appliedFilterState, column).includes(_child_filter);
        },
        indeterminate: false,
      } as TreeFilterNode;

      const existingChildNode = existingNode?.children?.find(
        (_existingChildNode) => _existingChildNode.key == newChildNode.key,
      );

      if (existingChildNode && nodesAreEqual(existingChildNode, newChildNode)) {
        newChildNodeCollection.push(existingChildNode);
      } else {
        newChildNodeCollection.push(newChildNode);
        hasChildStateChanges = true;
        hasChanges = true;
      }
    });

    // Apply the sort here so we are not scrambling the output if there is no change
    newChildNodeCollection?.sort((a, b) => {
      // Sort selected values alphabetically
      if (a.applied === b.applied) {
        return a.label.localeCompare(b.label);
      } else {
        // Sort based on applied status
        return a.applied ? -1 : 1;
      }
    });

    // Child node and selection count logic for determining parent check/selection state
    const childrenNodeCount = newChildNodeCollection.length;
    const childSelectionCount = newChildNodeCollection.filter((_child) => _child.selected).length;

    // Determine if the parent checkbox should be flagged as "indeterminate"
    const hasChildSelection = childSelectionCount > 0;
    const hasAllChildrenSelected = childrenNodeCount > 0 && childSelectionCount == childrenNodeCount;
    newNode.indeterminate = !newNode.selected && hasChildSelection && !hasAllChildrenSelected;

    let targetParentNode: TreeFilterNode;
    if (existingNode && !hasChildStateChanges && nodesAreEqual(existingNode, newNode)) {
      targetParentNode = existingNode;
    } else {
      targetParentNode = newNode;
      hasChanges = true;
    }

    // If any child in the stack had changes, overwrite the list of children with the new array to create a new object reference
    // otherwise retain the reference to the original collection of child nodes
    targetParentNode.children = hasChildStateChanges ? newChildNodeCollection : existingNode?.children;

    // Add the new node to the top level array
    newNodeCollection.push(targetParentNode);

    newNodeCollection?.sort((a, b) => (a.label < b.label ? -1 : 1));

    // Return the untouched state if there are no changes
    return hasChanges ? newNodeCollection : state;
  });

  // We only want to return the new array reference if there is an update, that way we dont trigger a re-render each time we assemble the model
  return { state: hasChanges ? newNodeCollection : state, hasChanges };
};
