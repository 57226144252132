import useFeatureFlag from "./useFeatureFlag";

export interface FeatureFlagProps {
  flag: string;
  children: React.ReactNode;
}

export default function FeatureFlag({ flag, children }: FeatureFlagProps) {
  const feature = useFeatureFlag(flag);
  return feature.enabled ? children : <></>;
}
