import { Close, Check } from "@mui/icons-material";
import { Paper, Box, IconButton, PaperProps, CircularProgress } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { useQuestionEnhancer } from "../../../store/home/useQuestionEnhancer";

export type QueryEnhancePanelProps = {
  onSelectOption: (text: string) => void;
} & PaperProps;

export default function QueryEnhancePanel({ sx, onSelectOption }: QueryEnhancePanelProps) {
  const { QE, closeEnhancerPanel, setSelectedOption } = useQuestionEnhancer();

  const handleClose = () => {
    closeEnhancerPanel();
  };

  const handleOptionSelection = (enhancedText: string, index: number) => {
    setSelectedOption(index);
    onSelectOption(enhancedText);
  };

  const allOptionsHaveText = QE.options.every((enhancedQuestionText) => String(enhancedQuestionText).length > 0);

  // What are patients and customers saying about TV Commercials and Advertisements?
  // Can you summarize the feedback regarding Xolair for Food Allergies?
  return (
    <Paper
      sx={{
        ...sx,
        margin: "1em",
        padding: "1em",
        "& .qe-option": {
          cursor: "pointer",
          margin: "0.5em 0",
          padding: "0.5em 1em",
          display: "flex",
          flexDirection: "row",
          gap: "1em",
          borderRadius: "0.5em",

          "& .qe-option-check": {
            visibility: "hidden",
          },

          "&:hover": {
            backgroundColor: indigo[100],
            ".qe-option-check": {
              visibility: "initial",
            },
          },
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Box sx={{ alignContent: "center" }}>SUGGESTED QUESTION PROMPTS</Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ alignContent: "center" }}>{QE.loading && <CircularProgress size={20} />}</Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box>
        {allOptionsHaveText ? (
          QE.options.map((enhancedQuestionText, i) => (
            <Box className="qe-option" key={i} onClick={() => handleOptionSelection(enhancedQuestionText, i)}>
              <Box className="qe-option-check" sx={{ alignContent: "center" }}>
                <Check />
              </Box>
              <Box>{enhancedQuestionText}</Box>
            </Box>
          ))
        ) : (
          <Box>Could not enhance the input provided, please try rephrasing your question.</Box>
        )}
      </Box>
    </Paper>
  );
}
