import { AUTH_ACTION_TYPES, JWT } from "./auth.types";

import { createAction, ActionWithPayload, withMatcher } from "../../utils/reducer/reducer.utils";

type SetJWT = ActionWithPayload<AUTH_ACTION_TYPES.SET_JWT, JWT>;

export const setJWT = withMatcher((jwt: JWT): SetJWT => createAction(AUTH_ACTION_TYPES.SET_JWT, jwt));

export const setJwt = (incoming: JWT) => {
  return setJWT(incoming);
};

const updateAPP = (jwt: JWT, appSelection: string): JWT => {
  return { ...jwt, app: appSelection };
};

export const updateApp = (jwt: JWT, appSelection: string) => {
  const updated = updateAPP(jwt, appSelection);
  return setJWT(updated);
};

const isLoading = (jwt: JWT, loading: boolean): JWT => {
  return { ...jwt, isLoading: loading };
};

export const authIsLoading = (jwt: JWT, loading: boolean) => {
  const updated = isLoading(jwt, loading);
  return setJWT(updated);
};
