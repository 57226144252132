import React, { forwardRef } from "react";
import { TransitionProps } from "notistack";
import { Slide, Dialog, AppBar, Toolbar, IconButton, Typography, Box, BoxProps } from "@mui/material";
import { Close } from "@mui/icons-material";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type FullScreenProps = {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  expanded: boolean;
  handleClose: () => void;
} & BoxProps;

export default function FullScreen({ title, actions, children, expanded, handleClose, ...props }: FullScreenProps) {
  if (expanded) {
    return (
      <React.Fragment>
        <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative", height: "48px" }}>
            <Toolbar sx={{ minHeight: "48px !important", paddingLeft: "1em !important", paddingRight: "0 !important" }}>
              <Typography sx={{ flex: 1 }} variant="h6" component="div">
                {title}
              </Typography>
              {actions}
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box {...props}>{children}</Box>
        </Dialog>
      </React.Fragment>
    );
  }
  return children;
}
