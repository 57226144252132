import axios from "axios";
import { AuthorizationPostResponse } from "../models/auth";

export const getAuthorization = async (
  authUri: string,
  redirectUri: string,
  accessCode: string,
): Promise<AuthorizationPostResponse> => {
  const postBody = {
    access_code: accessCode,
    redirect_uri: redirectUri,
  };
  const response = await axios.post(authUri, postBody, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
