import { Paper, Typography as T } from "@mui/material";
import { TCommonTooltipContent } from "./Deepchat/models";

export default function CommonTooltipContent({ header, desc }: TCommonTooltipContent) {
  return (
    <Paper sx={{ p: 2, color: "#13294B" }}>
      <T fontWeight={900}>{header}</T>
      {desc}
    </Paper>
  );
}
