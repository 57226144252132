import { useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, TextField, InputAdornment, Typography as T } from "@mui/material";
import { Filter } from "../../models/filter";
import { SearchResults } from "../../models/search";
import { Clear, Search } from "@mui/icons-material";
import CheckboxIcon from "./CheckboxIcon";

type FilterDisplay = {
  key: string;
  label: string;
  selected: boolean;
};

type FilterListProps = {
  filterValues: FilterDisplay[];
  handleFilterClick: (val: string, selected: boolean) => void;
  disabled: boolean;
};

const FilterList = ({ filterValues, handleFilterClick, disabled }: FilterListProps): JSX.Element => {
  return (
    <FormGroup
      sx={{
        mb: 0,
        minHeight: "0px",
        maxHeight: "325px",
        flexWrap: "nowrap",
        overflow: "auto",
      }}
    >
      {filterValues.map(({ key, selected, label }) => {
        return (
          <FormControlLabel
            sx={{ margin: "0 0 -4px 0" }}
            key={key}
            disabled={disabled}
            control={
              <Checkbox
                size="small"
                icon={<CheckboxIcon size="small" />}
                checked={selected}
                onChange={() => handleFilterClick(label, selected)}
              />
            }
            label={label}
          />
        );
      })}
      {filterValues?.length == 0 && (
        <T fontStyle="italic" fontSize={14} sx={{ color: "text.disabled", pt: "10px", pl: "10px" }}>
          No filters.
        </T>
      )}
    </FormGroup>
  );
};

type MultiselectFilterProps = {
  filter: Filter;
  selectedValues: string[];
  appliedValues: string[];
  disabled?: boolean;
  handleFilterChange: (filterName: keyof SearchResults, newValues: string[]) => void;
};

const MultiselectFilter = ({
  filter,
  selectedValues,
  appliedValues,
  handleFilterChange,
  disabled = false,
}: MultiselectFilterProps): JSX.Element => {
  const [input, setInput] = useState<string>("");

  const filterValues = (filter.filter_values || [])
    .filter((val) => (input ? val.toLowerCase().includes(input.toLowerCase().trim()) : true))
    .map((val) => {
      return {
        key: `${filter.column_name}-${val}-checkbox`,
        label: val,
        selected: selectedValues.includes(val),
        applied: appliedValues.includes(val),
      };
    })
    .sort((a, b) => {
      // Sort selected values alphabetically
      if (a.applied === b.applied) {
        return a.label.localeCompare(b.label);
      } else {
        // Sort based on applied status
        return a.applied ? -1 : 1;
      }
    });

  const handleClear = () => setInput("");

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleFilterClick = (filterVal: string, selected: boolean) => {
    if (selected) {
      const newFilterValues = selectedValues.filter((val) => val !== filterVal);
      handleFilterChange(filter.column_name, newFilterValues);
    } else {
      handleFilterChange(filter.column_name, [...selectedValues, filterVal]);
    }
  };

  return (
    <div>
      <TextField
        variant="standard"
        value={input}
        onChange={handleInputChange}
        placeholder={`Search...`}
        sx={{ marginLeft: "12px", width: "calc(100% - 12px)" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: Boolean(input.length) && (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              <Clear onClick={handleClear} sx={{ "&.MuiSvgIcon-root": { width: 16, height: 16 } }} />
            </InputAdornment>
          ),
        }}
      />
      <FilterList disabled={disabled} filterValues={filterValues} handleFilterClick={handleFilterClick} />
    </div>
  );
};

export default MultiselectFilter;
