import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getEcosystems } from "../../../api/dataSources";
import { defaultFilterState } from "../../../constants/defaultFilterState";
import { selectAuth } from "../../../store/authorization/auth.selector";
import { SearchFilters } from "../../../models/search";
import { GeoFeatureData } from "./EcosystemMap";

export default function useGetEcosystemData(filterState: SearchFilters) {
  const jwt = useSelector(selectAuth);

  const ecosystem_filters = filterState.base_filters?.ecosystem_name || [];

  // Remove Ecosystem filter from the copy of the filters we will use to query for ecosystems, so
  // that unselected states (ecosystems) can retain their document count even when not selected.
  const filtersExcludingEcosystem = ((state) => {
    const filters = { ...state };
    filters.base_filters = { ...filters.base_filters, ecosystem_name: undefined };
    return filters;
  })(filterState);

  const { data: dataResponse, isLoading } = useQuery({
    queryKey: ["ecosystem_response", JSON.stringify(filtersExcludingEcosystem)],
    queryFn: () => getEcosystems(filtersExcludingEcosystem || defaultFilterState, jwt),
    enabled: !!filterState,
  });

  const data = useMemo(
    () =>
      (dataResponse?.data || []).map((ecosystem) => {
        const ecosystemIsAppliedFilter = ecosystem_filters.includes(ecosystem.name);
        return {
          id: ecosystem.name,
          rate: ecosystem.record_count,
          selected: ecosystemIsAppliedFilter,
        } as GeoFeatureData;
      }),
    [filterState, dataResponse],
  );

  return { data, isLoading };
}
