import { useState, useEffect } from "react";

type MousePosition = {
  x: number | null;
  y: number | null;
};
const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState<MousePosition>({ x: null, y: null });

  useEffect(() => {
    const updateMousePos = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", updateMousePos);

    return () => {
      window.removeEventListener("mousemove", updateMousePos);
    };
  }, []);

  return mousePosition;
};

export default useMousePosition;
