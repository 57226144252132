import { DefinedRange } from "date-range-picker-mui";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);

const today = dayjs().startOf("day");

export const calendarDefaults: DefinedRange[] = [
  {
    label: "Today",
    startDate: today.toDate(),
    endDate: today.endOf("day").toDate(),
  },
  {
    label: "Yesterday",
    startDate: today.subtract(1, "day").toDate(),
    endDate: today.subtract(1, "day").endOf("day").toDate(),
  },
  {
    label: "This Week",
    startDate: today.startOf("week").toDate(),
    endDate: today.endOf("day").toDate(),
  },
  {
    label: "Last Week",
    startDate: today.subtract(1, "week").startOf("week").toDate(),
    endDate: today.subtract(1, "week").endOf("week").toDate(),
  },
  {
    label: "This Month",
    startDate: today.startOf("month").toDate(),
    endDate: today.endOf("day").toDate(),
  },
  {
    label: "Last Month",
    startDate: today.subtract(1, "month").startOf("month").toDate(),
    endDate: today.subtract(1, "month").endOf("month").toDate(),
  },
  {
    label: "This Quarter",
    startDate: today.startOf("quarter").toDate(),
    endDate: today.toDate(),
  },
  {
    label: "Last Quarter",
    startDate: today.subtract(1, "quarter").startOf("quarter").toDate(),
    endDate: today.subtract(1, "quarter").endOf("quarter").toDate(),
  },
  {
    label: "This Year",
    startDate: today.startOf("year").toDate(),
    endDate: today.endOf("day").toDate(),
  },
  {
    label: "Last Year",
    startDate: today.subtract(1, "year").startOf("year").toDate(),
    endDate: today.subtract(1, "year").endOf("year").toDate(),
  },
];
