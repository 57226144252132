import dayjs from "dayjs";
import { SavedSearch } from "../../models/savedSearch";
import { RowType, SearchFilters, SearchResults, TopicSentimentFilters } from "../../models/search";

const LONG_DATE_FORMAT = "DD MMM YYYY";

const formatDateAsString = (dateString: string | Date) => dayjs(dateString).format(LONG_DATE_FORMAT);

// Collect the filters (either active search filters or a saved search) and return a complied
// array containing the label and selected values. Used to display the filters in a table list within a modal
export const getSearchFiltersForTableList = (filterState: SearchFilters | SavedSearch): RowType[] => {
  let selectedFilters: RowType[] = Object.keys(filterState.base_filters)
    .map((key) => ({
      name: key,
      values: filterState.base_filters[key as keyof SearchResults] || [],
    }))
    .filter((f) => f.values.length > 0)
    // Sort to ensure active filters display in a consistent order
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  // Only show the date range in the selected filters if the user selects values outside of the defaults.
  if (filterState.dates?.start || filterState.dates?.end) {
    const selectedDateRange = {
      name: "Date Range",
      values: [``],
    };
    const {
      dates: { start, end },
      dynamic_date_range,
    } = filterState;
    const today = formatDateAsString(new Date());
    const startDate = start ? formatDateAsString(start) : "01 January 2020";
    const endDate = end ? formatDateAsString(end) : today;
    selectedDateRange.values = [`${startDate} - ${endDate}`];

    if (dynamic_date_range) {
      selectedDateRange.values.push(`(${dynamic_date_range})`);
    }

    selectedFilters = [selectedDateRange, ...selectedFilters];
  }

  if (filterState.visual_filters?.topics_sentiment) {
    const selectedTopicsSentiments: RowType[] = Object.keys(filterState.visual_filters.topics_sentiment)
      .map((key) => ({
        name: key,
        values: [filterState.visual_filters.topics_sentiment[key as keyof TopicSentimentFilters] ?? ""],
      }))
      .filter((f) => f.values.length > 0)
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    selectedFilters = [...selectedFilters, ...selectedTopicsSentiments];
  }

  if (filterState.visual_filters?.keywords?.length) {
    selectedFilters = [...selectedFilters, { name: "Keywords", values: filterState.visual_filters.keywords }];
  }

  if (filterState.query?.trim()) {
    // If a search term exists, append in to the beginning of the active filter list
    selectedFilters = [{ name: "Search Term", values: [`"${filterState.query.trim()}"`] }, ...selectedFilters];
  }
  return selectedFilters;
};
