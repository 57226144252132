import { Avatar, AvatarProps } from "@mui/material";
import { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";

interface AvatarWithFallbackProps extends AvatarProps {
  src: string;
  alt: string;
}

export const AvatarWithFallback = ({ src, alt, ...props }: AvatarWithFallbackProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageLoaded(true);
    };
    img.onerror = () => {
      setError(true);
    };
  }, [src]);

  return (
    <Avatar alt={alt} src={imageLoaded && !error ? src : undefined} {...props}>
      {!imageLoaded || error ? <PersonIcon /> : null}
    </Avatar>
  );
};
