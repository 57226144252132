import { AnyAction } from "redux";
import { setJWT } from "./auth.action";

import { JWT } from "./auth.types";

export interface AuthState {
  readonly jwt: JWT;
}

export const AUTH_INITIAL_STATE: AuthState = {
  jwt: { token: null, role: null, app: null, isLoading: true },
};

export const authReducer = (state = AUTH_INITIAL_STATE, action: AnyAction): AuthState => {
  if (setJWT.match(action)) {
    return { ...state, jwt: action.payload };
  }
  return state;
};
