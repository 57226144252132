import { HTMLProps } from "react";
import bargraph from "./bargraph.svg";
import distribution from "./distribution.svg";
import map from "./map.png";
import table from "./table.svg";
import line from "./trendline.svg";
import cloud from "./wordCloud.png";

const createGraphic =
  (asset: string, extraProps?: Omit<HTMLProps<HTMLImageElement>, "src">) =>
  (props: Omit<HTMLProps<HTMLImageElement>, "src">) => {
    return <img width="64px" height="36px" draggable={false} src={asset} {...props} {...extraProps} />;
  };

export const BarIcon = createGraphic(bargraph, { width: 50, height: undefined });
export const LineIcon = createGraphic(line);
export const DistIcon = createGraphic(distribution, { width: "61px", height: undefined, style: { margin: "auto" } });
export const MapIcon = createGraphic(map, { width: 56, height: undefined, style: { margin: "auto", opacity: 0.75 } });
export const WordIcon = createGraphic(cloud, { width: "50px", height: undefined, style: { margin: "auto" } });
export const TableIcon = createGraphic(table, { style: { opacity: 0.25, transform: "rotate(180deg)" } });
