import axios from "axios";
import * as toGeoJson from "@tmcw/togeojson";
import { FeatureCollection } from "geojson";
import { useQuery } from "@tanstack/react-query";

export default function useKmlFeatures(key: string, uri: string) {
  return useQuery([key], async () => {
    // ex:
    // "/ChartData/z2_ecosystem__commercial_region_shape.kml"
    // "/ChartData/z2_ecosystem_shape.kml"
    const response = await axios.get<string>(uri);

    const parser = new DOMParser();
    const xml = parser.parseFromString(response.data, "text/xml");

    const featureCollection = toGeoJson.kml(xml) as FeatureCollection;

    return featureCollection;
  });
}
