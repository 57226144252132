import { useMediaQuery, useTheme } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect } from "react";

import {
  QuestionIsLoadingAtom,
  QuestionTextAtom,
  SessionThreadAtom,
} from "../../components/Deepchat/state/DeepchatState";
import useLocalFilterState from "../../components/filters/useLocalFilterState";
import { useDeepChatSocketContext } from "../../contexts/DeepChatSocketContext";
import { useFilterContext } from "../../contexts/FilterProvider";
import useDeepchat from "../../contexts/useDeepchat";
import emitSnackbar from "../../emitSnackbar";
import { useHideFilterDrawer } from "../../pages/AppLayout";
import { useCallout } from "../../components/Deepchat/Callout";

const rightCalloutOrientations: {
  [key in "sm" | "md" | "lg"]: {
    x: "right" | "left";
    y: "top" | "bottom";
    axis: "x" | "y";
    placement: { top?: string; left?: string; bottom?: string; right?: string };
  };
} = {
  sm: { x: "right", y: "top", axis: "y", placement: { right: "50%", top: "100%" } },
  md: { x: "left", y: "top", axis: "y", placement: { left: "50%", top: "100%" } },
  lg: { x: "left", y: "top", axis: "x", placement: { left: "100%", top: "50%" } },
};

export const useQuestionInputStore = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("xl"));
  const orientation = rightCalloutOrientations[lg ? "lg" : "sm"];
  const [LeftCallout, closeLeftCallout] = useCallout(
    "Use the filters to narrow down your dataset to the most relevant sources, then write your prompt",
  );
  const [RightCallout, closeRightCallout] = useCallout(
    "When your prompt is ready, press Enter or click this button to apply your filters and ask your question",
  );
  const DeepChatSocket = useDeepChatSocketContext();
  const { filterState, applyFilters } = useFilterContext();
  const { askQuestionStreaming } = useDeepchat();
  const { transientFilterState } = useLocalFilterState(filterState);
  const { hideFilterDrawer, setHideFilterDrawer } = useHideFilterDrawer();

  //Atoms
  const [question, setQuestion] = useAtom(QuestionTextAtom);
  const [loading, setLoading] = useAtom(QuestionIsLoadingAtom);
  const [sessionThread] = useAtom(SessionThreadAtom);

  const disableFilters = sessionThread.length > 0 || loading;

  const submitQuestion = (question: string) => {
    const isValidQuestion = /\w/.test(question.trim());
    const errorMessage = isValidQuestion
      ? ""
      : "Please enter a valid question, questions cannot be empty or consist of only punctuation.";

    if (!loading && isValidQuestion) {
      setLoading(true);

      if (transientFilterState) {
        applyFilters(transientFilterState);
      }

      askQuestionStreaming(DeepChatSocket.sendJsonMessage, transientFilterState ?? filterState, question);
    } else if (!loading) {
      setLoading(false);
      emitSnackbar(errorMessage, "error", {
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        autoHideDuration: 5000,
        preventDuplicate: true,
      });
    }
  };

  useEffect(() => {
    if (loading) {
      closeLeftCallout();
      closeRightCallout();
      setHideFilterDrawer(true);
    }
  }, [loading]);

  return {
    disableFilters,
    loading,
    question,
    LeftCallout,
    RightCallout,
    submitQuestion,
    orientation,
    setQuestion,
    setHideFilterDrawer,
    hideFilterDrawer,
  };
};
