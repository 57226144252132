import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import { useCustomTabs } from "../api/customTabs";
import { FilterDrawer } from "../components";
import EditModal from "../components/CustomDashboards/EditModal";
import { MedInfoChartCardTable } from "../components/CustomDashboards/common";
import { VerbatimsProvider } from "../contexts/VerbatimsProvider";
import emitSnackbar from "../emitSnackbar";
import { CustomTab } from "../models/customTabs";
import { selectAuth } from "../store/authorization/auth.selector";
import { TOP_BAR_HEIGHT } from "./AppLayout";
import { useSearchStore } from "../store/useSearchStore";

const emitErrorSnackbar = (message: string) => {
  emitSnackbar(message, "error", {
    anchorOrigin: { horizontal: "center", vertical: "top" },
    autoHideDuration: 6000,
    preventDuplicate: true,
  });
};

export const CustomDashboard = () => {
  const { applyDefaultSearchState } = useSearchStore();
  applyDefaultSearchState();

  const [modalOpen, setModalOpen] = useState(false);
  const jwt = useSelector(selectAuth);
  const userRole = jwt.role;
  const { id } = useParams();
  const { customTabs, setCustomTabs, isLoading } = useCustomTabs();
  const editing = useMatch("/dashboards/custom/:id/edit");
  const navigate = useNavigate();
  const { state } = useLocation();
  const chartNum = id === "new" ? customTabs.length : parseInt(id ?? "");

  useEffect(() => {
    if (id === "new" || editing != null) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  });

  const notValid = isNaN(chartNum) || chartNum > customTabs.length - (id === "new" ? 0 : 1) || chartNum < 0;

  useEffect(() => {
    if (notValid && !isLoading) {
      // I'm not convinced this is working as well as we want it to.
      navigate(customTabs.length > 0 ? `/dashboards/custom/${customTabs.length - 1}` : "/");
      return;
    }
  });

  if (notValid) {
    return;
  }

  const handleApply = (newState: CustomTab, chartNum: number) => {
    const normalizedName = newState.name.replace(/\s/g, "").toLowerCase();
    const oldTab = customTabs[chartNum];

    // exclude old tab from iteration
    const isNameUnique = customTabs
      .filter((_tab) => _tab !== oldTab)
      .every((tab) => tab.name.replace(/\s/g, "").toLowerCase() !== normalizedName);

    // Define restrictions based on user role
    const isMedicalRole = userRole?.includes("medical");
    const isCommercialRole = userRole?.includes("commercial");

    let isNameAllowed = true;

    // Check restrictions based on user role
    if (isMedicalRole) {
      isNameAllowed = !["deepchat", "explorer", "medinfo"].includes(normalizedName);
    } else if (isCommercialRole) {
      isNameAllowed = !["deepchat", "explorer"].includes(normalizedName);
    }
    if (normalizedName == "") {
      emitErrorSnackbar("Tab names cannot be blank. Please choose a name and try again.");
      return;
    }

    // If an existing tab with the same name or similar name is found and it's not the same tab being updated, show a message
    if (!isNameUnique) {
      emitErrorSnackbar("Tab name or similar tab already exists. Please choose a new name and try again.");
      return;
    }

    if (!isNameAllowed) {
      emitErrorSnackbar(`Tab name "${newState.name}" cannot be used. Please choose a new name and try again.`);
      return;
    }

    const newTabs = [...customTabs];
    newTabs[chartNum] = newState;

    setCustomTabs(newTabs);
    navigate(`/dashboards/custom/${chartNum}`);
  };

  const chartList = customTabs[chartNum]?.charts ?? [];

  return (
    <>
      <FilterDrawer topPadding={0} />

      <Box component="main" sx={{ p: 0, flexGrow: 1, paddingTop: `${TOP_BAR_HEIGHT}px` }}>
        <div style={{ padding: "24px" }}>
          <Grid container gap={1} flexDirection={"row"} wrap="wrap">
            {chartList.map((chartConfig) => {
              //requires map of all charts to iterate through
              const { Component, sx, verbatims } = MedInfoChartCardTable[chartConfig.chart_type];
              const Child = () =>
                verbatims ? (
                  <VerbatimsProvider>
                    <Component />
                  </VerbatimsProvider>
                ) : (
                  <Component />
                );
              return (
                <Box
                  sx={{
                    ...{
                      display: "flex",
                      aspectRatio: "5/4",
                      flexBasis: "50%",
                      maxWidth: "calc(50% - 4px)",
                      "& > div": { width: "100%" },
                    },
                    ...sx,
                  }}
                  key={chartConfig.chart_type}
                >
                  <Child />
                </Box>
              );
            })}
          </Grid>
        </div>
      </Box>

      <EditModal
        onCancel={() => navigate(editing ? `/dashboards/custom/${chartNum}` : state?.prevTab || "/")}
        onApply={(newState: CustomTab) => handleApply(newState, chartNum)}
        onDelete={() => {
          let currentChart = chartNum;
          // Updated the tab set with the current tab removed from the list
          const newTabSet = customTabs.filter((item) => item !== customTabs[currentChart]);

          if (newTabSet.length == 0) {
            // If we deleted all the charts go back to the Explore tab
            navigate("/explorer");
          } else {
            // Otherwise, try to "move back" (left) one chart after the current chart has been deleted
            const targetChart = Math.max(--currentChart, 0);
            navigate(`/dashboards/custom/${targetChart}`);
          }

          setCustomTabs(newTabSet);
        }}
        open={modalOpen}
        initialState={editing ? customTabs[chartNum] : { name: "", charts: [] }}
      />
    </>
  );
};
