import { Link } from "@mui/material";
import { VariantType, enqueueSnackbar, closeSnackbar, OptionsObject } from "notistack";

export default function emitSnackbar(message: string, variant: VariantType = "default", options?: OptionsObject) {
  return enqueueSnackbar(message, {
    ...options,
    variant,
    action: (id) => (
      <Link
        href="#"
        underline="hover"
        color="white"
        sx={{ marginRight: "8px" }}
        onClick={(e) => {
          e.preventDefault();
          closeSnackbar(id);
        }}
      >
        CLOSE
      </Link>
    ),
  });
}
