import { Grid } from "@mui/material";
import { DataDistributionCard, RelevantTextRecordsCard, KeywordsCloudCard } from "./cards";
import { VerbatimsProvider } from "../contexts/VerbatimsProvider";
import TopicDistributionCard from "./cards/TopicDistributionCard";
import EmergingTopicsCard from "./cards/EmergingTopicsCard";
import FilterBreadcrumbs from "./FilterBreadcrumbs";
import EcosystemMapCard from "./cards/EcosystemMapCard";
import SentimentOverTimeCard from "./cards/SentimentOverTimeCard";

const ChartGrid = (): JSX.Element => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <FilterBreadcrumbs />
    </Grid>
    <Grid item xs={12}>
      <DataDistributionCard />
    </Grid>

    <Grid item xs={12} sx={{ display: "flex", gap: "16px", aspectRatio: "2.75" }}>
      <EmergingTopicsCard sx={{ flex: 7 }} />
      <KeywordsCloudCard sx={{ flex: 5 }} />
    </Grid>

    <Grid item xs={12} sx={{ display: "flex", gap: "16px", aspectRatio: "2.75" }}>
      <SentimentOverTimeCard sx={{ flex: 7 }} />
      <EcosystemMapCard sx={{ flex: 5 }} />
    </Grid>

    <Grid item xs={12} sx={{ display: "flex", gap: "16px", aspectRatio: "2.75" }}>
      <TopicDistributionCard sx={{ flex: 12 }} />
    </Grid>

    <Grid item xs={12}>
      <VerbatimsProvider>
        <RelevantTextRecordsCard />
      </VerbatimsProvider>
    </Grid>
  </Grid>
);

export default ChartGrid;
