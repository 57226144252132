import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  SxProps,
} from "@mui/material";
import { Check, EditOutlined, FavoriteBorderOutlined, ExpandMore } from "@mui/icons-material";
import { defaultFilterState } from "../constants/defaultFilterState";
import { useFilterContext } from "../contexts/FilterProvider";
import useSavedSearch from "../contexts/useSavedSearch";
import { SavedSearch } from "../models/savedSearch";
import IconFilter from "../icons/IconFilter";
import { useSearchId } from "../contexts/SearchIdProvider";
import { EditSavedFiltersModal } from "./filters";
import SaveFiltersModal from "./filters/SaveFiltersModal";

const SavedSearchesDropdown = (): JSX.Element => {
  const { searchId } = useSearchId();
  const { filterState, hasActiveFilters, resetFilters, applyFilters } = useFilterContext();
  const { savedSearches, getSavedSearch, isLoading } = useSavedSearch();

  const [manageFiltersModalOpen, setManageFiltersModalOpen] = useState(false);
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const applySavedSearch = (searchId: string) => {
    const filters = getSavedSearch(searchId);
    if (filters) {
      applyFilters(filters, searchId);
    }
  };

  const handleChange = (e: SelectChangeEvent): void => {
    const search_id = e.target.value;
    if (search_id === ALL_DATA.search_id) {
      resetFilters();
    }
    applySavedSearch(e.target.value);
  };

  const openDropdown = () => {
    setOpen(true);
  };
  const closeDropdown = () => {
    setOpen(false);
  };

  const handleManageFiltersModalClose = () => {
    setManageFiltersModalOpen(false);
    closeDropdown();
  };

  const handleSaveFiltersModalClose = () => {
    setSaveFilterModalOpen(false);
    closeDropdown();
  };

  // Default Saved Search option
  // All Data represents zero filters applied
  const ALL_DATA: SavedSearch = {
    ...defaultFilterState,
    title: "All Data",
    search_id: "all_data",
  };
  // Custom search represents the transitory state
  // for when a user has added new filters but
  // has not yet saved them under a new search
  const CUSTOM_SEARCH: SavedSearch = {
    // HACK: filterState should never remain undefined but there isn't an easy way to handle this while the application is loading
    ...(filterState ?? defaultFilterState),
    title: "Custom",
    search_id: "custom",
  };
  // The filter options to render in the select dropdown
  const savedFilterOptions = [ALL_DATA, ...(savedSearches ?? [])];

  let foundSearch = savedFilterOptions.find(({ search_id }) => search_id === searchId);
  // If no saved search is selected,
  // either assign custom search if filters are applied
  // or default to the `allData` search.
  let foundSearchStyle: SxProps = { color: "#13294B", fontWeight: 600 };
  if (!foundSearch) {
    if (hasActiveFilters) {
      foundSearch = CUSTOM_SEARCH;
      foundSearchStyle = { ...foundSearchStyle, fontStyle: "italic", fontWeight: 500 };
      // Add custom search to select options
      savedFilterOptions.push(CUSTOM_SEARCH);
    } else {
      foundSearch = ALL_DATA;
    }
  }

  // On initial page load, check to see if we have a searchId set, and load that search if we do
  useEffect(() => {
    if (searchId) {
      applySavedSearch(searchId);
    }
  }, []);

  if (isLoading) {
    return <Skeleton height={40} variant="rounded" />;
  }

  return (
    <>
      <FormControl size="small" fullWidth>
        <Select
          labelId="app-dropdown-label"
          id="app-dropdown"
          variant="outlined"
          label="Saved "
          notched={false}
          open={open}
          onClose={closeDropdown}
          onOpen={openDropdown}
          value={foundSearch.search_id}
          onChange={handleChange}
          renderValue={(selected) => savedFilterOptions.find(({ search_id }) => search_id === selected)?.title}
          startAdornment={
            <InputAdornment position="start">
              <IconFilter />
            </InputAdornment>
          }
          inputProps={{
            sx: foundSearchStyle,
          }}
          IconComponent={ExpandMore}
          MenuProps={{
            marginThreshold: 0,
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "transparent",
                color: "#13294B",
              },
            },
          }}
          style={{
            paddingLeft: "4px",
          }}
        >
          {savedFilterOptions.map(({ title, search_id }) => (
            <MenuItem key={title} value={search_id}>
              {search_id === foundSearch?.search_id ? (
                <Check fontSize="small" sx={{ color: "#13294B", mr: "6px" }} />
              ) : (
                <Box width={"26px"} />
              )}
              {title}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            sx={{ justifyContent: "flex-start", color: "#13294B" }}
            onMouseDown={() => setSaveFilterModalOpen(true)}
            disabled={foundSearch.search_id === ALL_DATA.search_id}
          >
            <FavoriteBorderOutlined fontSize={"small"} sx={{ mr: 2 }} /> Save as ...
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "flex-start", color: "#13294B", paddingBottom: 1 }}
            onMouseDown={() => setManageFiltersModalOpen(true)}
            disabled={savedSearches?.length === 0}
          >
            <EditOutlined fontSize={"small"} sx={{ mr: 2 }} /> Manage
          </MenuItem>
        </Select>
      </FormControl>
      <EditSavedFiltersModal open={manageFiltersModalOpen} handleModalClose={handleManageFiltersModalClose} />
      <SaveFiltersModal open={saveFilterModalOpen} handleModalClose={handleSaveFiltersModalClose} />
    </>
  );
};

export default SavedSearchesDropdown;
