import { Box, Button, Divider, LinearProgress, Stack, styled } from "@mui/material";
import { useState } from "react";
import { SelectableExpansionPanel, StyledModal, TableList } from "../../components/common";
import useSavedSearch from "../../contexts/useSavedSearch";
import emitSnackbar from "../../emitSnackbar";
import { SavedSearch } from "../../models/savedSearch";
import AutocompleteSearchesSelect from "./AutocompleteSearchesSelect";
import { getSearchFiltersForTableList } from "./utils";

type RowType = {
  name: string;
  values: string[] | number[];
};

const BUTTON_WIDTH = "115px";

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  marginTop: "10px",
  borderRadius: "8px",
}));

const StyledGreyButton = styled(Button)({
  backgroundColor: "rgba(217, 217, 217, 0.25)",
  color: "rgba(0, 0, 0, 0.8)",
  border: "none",
  justifyContent: "center",
  width: BUTTON_WIDTH,
  "&:hover": {
    border: "none",
    backgroundColor: "rgba(217, 217, 217, 0.50)",
  },
});

const StyledDeleteButton = styled(Button)({
  color: "rgba(200, 16, 46, 0.9)",
  fontWeight: 700,
  background: "rgba(200, 16, 46, 0.15)",
  justifyContent: "center",
  width: BUTTON_WIDTH,
  "&:hover": {
    outline: "none",
    backgroundColor: "rgba(200, 16, 46, 0.30)",
  },
});

type EditSavedFilterModalProps = {
  open: boolean;
  handleModalClose: () => void;
};

const EditSavedFiltersModal = ({ open, handleModalClose }: EditSavedFilterModalProps): JSX.Element => {
  const { savedSearches, onDeleteSavedSearch, isLoading } = useSavedSearch();
  const [selectedSearches, setSelectedSearches] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  if (!savedSearches) return <div />;

  const onCloseRequested = (): void => {
    // Reset selected searches before closing
    setSelectedSearches([]);
    handleModalClose();
  };

  const onSearchSelect = (searchId: string): void => {
    let newSearches: string[] = [];
    if (selectedSearches.includes(searchId)) {
      newSearches = selectedSearches.filter((id) => id !== searchId);
    } else {
      newSearches = [...selectedSearches, searchId];
    }
    setSelectedSearches(newSearches);
  };

  const toggleSelectAll = () => {
    // If all searches are selected, then unselect all rows
    if (selectedSearches.length === savedSearches.length) {
      setSelectedSearches([]);
    } else {
      // Mark all searches as selected
      const searchIds = savedSearches.map(({ search_id }) => search_id);
      setSelectedSearches(searchIds);
    }
  };

  const handleSearchDelete = () => {
    // Collect list of filter names to display in snackbar
    const filterNames: string[] = [];
    selectedSearches.forEach((id) => {
      const savedSearch = savedSearches.find(({ search_id }) => search_id === id);
      if (savedSearch) {
        filterNames.push(savedSearch.title);
      }
    });
    onDeleteSavedSearch(
      { savedSearchIds: selectedSearches },
      {
        onSuccess: () => {
          // On success, show the snackbar and auto close the modal
          emitSnackbar(`Search filter(s) "${filterNames.join(", ")}" have been successfully deleted.`, "success");
          handleModalClose();
        },
        onError: () => {
          // On error, show the user an error message
          emitSnackbar(`Error deleting search filter(s) "${filterNames.join(", ")}". Please try again later.`, "error");
        },
      },
    );
    setSelectedSearches([]);
  };

  const handleChange = (newSearch: SavedSearch | null): void => {
    if (newSearch) {
      onSearchSelect(newSearch.search_id);
    }
    setInputValue("");
  };

  return (
    <StyledModal
      title="Manage Saved Filters"
      open={open}
      handleModalClose={onCloseRequested}
      actions={
        <Stack direction="row" spacing={2} justifyContent="center">
          <StyledGreyButton variant="outlined" onClick={onCloseRequested} disabled={isLoading}>
            Cancel
          </StyledGreyButton>
          <StyledDeleteButton
            variant="contained"
            disabled={isLoading || selectedSearches.length === 0}
            onClick={handleSearchDelete}
          >
            Delete {selectedSearches.length > 0 ? `(${selectedSearches.length})` : ""}
          </StyledDeleteButton>
        </Stack>
      }
    >
      <Box>
        <Stack display={"flex"} flex={1} direction="row">
          <StyledGreyButton variant="outlined" disabled={isLoading} onClick={toggleSelectAll}>
            Select All
          </StyledGreyButton>
          <AutocompleteSearchesSelect
            id={"edit-saved-searches-autocomplete"}
            onChange={(e, newVal) => handleChange(newVal)}
            inputValue={inputValue}
            onInputChange={(_event: React.SyntheticEvent, newInput: string) => setInputValue(newInput)}
            options={savedSearches}
            disabled={savedSearches.length === 0}
            sx={{ flex: 1, ml: 4 }}
          />
        </Stack>
        <StyledBox>
          {isLoading && <LinearProgress />}
          {savedSearches.map((filter, index) => {
            const selectedFilters: RowType[] = getSearchFiltersForTableList(filter);
            return (
              <div key={index}>
                <SelectableExpansionPanel
                  title={filter.title}
                  selected={selectedSearches.includes(filter.search_id)}
                  onSelect={() => onSearchSelect(filter.search_id)}
                >
                  <Box paddingX={4}>
                    <TableList rows={selectedFilters} noBorder />
                  </Box>
                </SelectableExpansionPanel>
                {index !== savedSearches.length - 1 && <Divider />}
              </div>
            );
          })}
        </StyledBox>
      </Box>
    </StyledModal>
  );
};

export default EditSavedFiltersModal;
