import { useState, useEffect } from "react";
import { useFilterContext } from "../contexts/FilterProvider";
import { TopicSentimentFilters, VisualFilters } from "../models/search";
import { defaultFilterState } from "../constants/defaultFilterState";

import { transform, isEqual, isArray, isObject } from "lodash";
import { Box, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

type VisualFilterDiff = {
  topics_sentiment?: TopicSentimentFilters;
  keywords?: string[];
};

type Breadcrumb = {
  field: string;
  subField?: string;
  value: string;
};

/*
 * Used to generate a diff between our previous visual filters object and latest applied visual filters
 */
const getObjDiff = (origObj: VisualFilters, newObj: VisualFilters): VisualFilterDiff => {
  const changes = (origObj: object, newObj: object) => {
    let arrayIndexCounter = 0;
    return transform(newObj, (result: Record<string, object | string | string[]>, value, key) => {
      if (origObj && !isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey as unknown as keyof typeof result] =
          isObject(value) && isObject(origObj[key]) ? changes(origObj[key], value) : value;
      }
    });
  };
  return changes(origObj, newObj);
};

const removeAndApplyVisualFilters = (
  removedVisualFilters: VisualFilterDiff,
  addedVisualFilters: VisualFilterDiff,
  breadcrumbStack: Breadcrumb[],
): Breadcrumb[] => {
  // Generated a copy of the breadcrumb stack for us to manipulate as we parse through the filter diffs.
  let breadcrumbStackCopy = [...breadcrumbStack];

  const allFilterSets = [
    { filterSet: removedVisualFilters, mode: "remove" },
    { filterSet: addedVisualFilters, mode: "add" },
  ];

  /*
   * Loop through both sets of filters:
   * (1) To remove filters from our breadcrumb stack
   * (2) To apply new filters to the end of our stack
   */
  allFilterSets.forEach(({ filterSet, mode }) => {
    Object.keys(filterSet).forEach((key) => {
      // This is the top level visual filter such as "keywords" or "topics_sentiment"
      const visualFilter = filterSet[key as keyof VisualFilterDiff];
      // If "topics_sentiment" filter object then loop through the child filters
      if (visualFilter && isObject(visualFilter) && !isArray(visualFilter)) {
        Object.keys(visualFilter).forEach((childFilter) => {
          const childFilterVal = visualFilter[childFilter as keyof TopicSentimentFilters];
          if (childFilterVal?.length) {
            // Removing a filter consists of just filtering out the child filter from our breadcrumb stack
            if (mode === "remove") {
              breadcrumbStackCopy = breadcrumbStackCopy.filter(({ subField }) => subField !== childFilter);
            } else {
              // Adding a filter consists of pushing onto the end of the stack to reflect the order of filter being applied
              breadcrumbStackCopy.push({
                field: key,
                subField: childFilter,
                value: isArray(childFilterVal) ? [...childFilterVal].join(",") : childFilterVal,
              });
            }
          }
        });
      } else {
        // This is a top level filter such as "keywords"
        if (visualFilter?.length) {
          if (mode === "remove") {
            // Removing a filter consists of just filtering out the child filter from our breadcrumb stack
            breadcrumbStackCopy = breadcrumbStackCopy.filter(
              ({ field, value }) => field !== key || (field === key && !visualFilter.includes(value)),
            );
          } else {
            // Adding a filter consists of pushing onto the end of the stack to reflect the order of filter being applied
            breadcrumbStackCopy.push({
              field: key,
              value: isArray(visualFilter) ? visualFilter.join(",") : visualFilter,
            });
          }
        }
      }
    });
  });
  return breadcrumbStackCopy;
};

const FilterBreadcrumbs = () => {
  const { filterState, applyFilters, getDefaultSearchFilters } = useFilterContext();
  const [breadcrumbStack, setBreadcrumbStack] = useState<Breadcrumb[]>([]);
  const [prevVisualFilters, setPrevVisualFilters] = useState<VisualFilters>(defaultFilterState.visual_filters);

  useEffect(() => {
    if (!filterState) return;

    // LEIBNIZ-982: Semi hack to prevent weirdness with filter stack not clearing when user clicks "Reset Filters"
    if (isEqual(filterState, getDefaultSearchFilters())) {
      return setBreadcrumbStack([]);
    }

    // Generate two diffs: first to see what filters have been added and then second for which filters have been removed.
    const addedVisualFilters = getObjDiff(prevVisualFilters, filterState.visual_filters);
    const removedVisualFilters = getObjDiff(filterState.visual_filters, prevVisualFilters);

    const newBreadcrubStack = removeAndApplyVisualFilters(removedVisualFilters, addedVisualFilters, breadcrumbStack);
    setBreadcrumbStack([...newBreadcrubStack]);
    // Save the visual filters for future filter comparisons
    setPrevVisualFilters(filterState.visual_filters);

    // Anytime the applied filter state changes, push the latest change on to a queue
    // This queue maintains our breadcrumbs and order of filters being applied
  }, [filterState]);

  const handleBreadcrumbClick = (index: number) => {
    if (!filterState) return;

    // Add one to be inclusive from index 0 to our selected breadcrumb
    const newBreadcrumbStack = breadcrumbStack.slice(0, index);

    let newVisualFilters = { ...defaultFilterState.visual_filters };
    newBreadcrumbStack.forEach(({ field, subField, value }) => {
      if (subField) {
        newVisualFilters = {
          ...newVisualFilters,
          [field]: {
            [subField]: value,
          },
        };
      } else {
        const visualFilter = newVisualFilters[field as keyof VisualFilters];
        newVisualFilters = {
          ...newVisualFilters,
          // Top level filters like "keywords" requires an array of strings
          [field]: isArray(visualFilter) ? [...visualFilter, value] : [value],
        };
      }
    });

    const newFilterState = {
      ...filterState,
      visual_filters: newVisualFilters,
    };
    // Updating the new filter state will re-trigger the breadcrumbs to update via the useEffect above
    applyFilters(newFilterState);
  };

  return (
    <Box display="flex" flexDirection="row" flex={1} flexWrap={"wrap"}>
      {breadcrumbStack.map((breadcrumb, index) => {
        const { field, subField, value } = breadcrumb;
        return (
          <Box key={`${field} ${subField} ${value}`} display={"flex"} flexDirection="row" alignItems={"center"}>
            <Link
              component="button"
              variant="body2"
              onClick={() => handleBreadcrumbClick(index)}
              sx={{ textDecoration: "none" }}
            >
              {value}
            </Link>
            <NavigateNextIcon fontSize="small" />
          </Box>
        );
      })}
    </Box>
  );
};

export default FilterBreadcrumbs;
