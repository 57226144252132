import { useState } from "react";
import { Button, Collapse, Grid, SxProps, Typography as T, Checkbox } from "@mui/material";
import { ExpandLess, ExpandMore, CheckBoxOutlined } from "@mui/icons-material";

type SelectableExpansionPanelProps = {
  title: string;
  children: JSX.Element;
  defaultOpen?: boolean;
  compressed?: boolean;
  selected: boolean;
  onSelect: () => void;
  sx?: SxProps;
};

const SelectableExpansionPanel = ({
  title,
  children,
  defaultOpen,
  compressed,
  selected,
  onSelect,
  sx,
}: SelectableExpansionPanelProps): JSX.Element => {
  const [expanded, setExpanded] = useState(!!defaultOpen);

  const handleExpansionChange = () => {
    setExpanded(!expanded);
  };

  const handleCheckboxSelect = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Stop propagating event to prevent toggling the expanded state
    event.stopPropagation();
    onSelect();
  };

  return (
    <div>
      <Button
        fullWidth
        disableRipple
        variant="contained"
        sx={{
          color: "text.primary",
          backgroundColor: "background.paper",
          outline: "none !important",
          paddingLeft: "12px",
          height: compressed ? 32 : 56,
          ...sx,
        }}
        onClick={handleExpansionChange}
      >
        <Grid container alignItems="center">
          <Grid item flexGrow={1}>
            <Button onClick={handleCheckboxSelect}>
              <Checkbox checked={selected} checkedIcon={<CheckBoxOutlined />} />
              <T fontWeight={600} fontSize={compressed ? 14 : 16}>
                {title}
              </T>
            </Button>
          </Grid>
          <Grid item height={24}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </Grid>
        </Grid>
      </Button>
      <Collapse in={expanded}>{children}</Collapse>
    </div>
  );
};

export default SelectableExpansionPanel;
