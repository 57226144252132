import { Button, ButtonProps, SxProps, Theme } from "@mui/material";

type StyledButtonProps = {
  children: React.ReactNode;
  active?: boolean;
  sx?: SxProps<Theme>;
} & ButtonProps;

const StyledButton = ({ children, active = false, sx, ...props }: StyledButtonProps) => {
  return (
    <Button
      {...props}
      sx={{
        color: active ? "#0B41CD" : "primary.dark",
        fontSize: 12,
        "&:hover": { background: "rgba(11, 65, 205, 0.1)", border: "rgba(11, 65, 205, 0.05)" },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
