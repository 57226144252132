import { indigo, blue, grey } from "./colors";
import { createTheme } from "@mui/material/styles";

// Custom colors must be added to interface below:
declare module "@mui/material/styles" {
  interface Palette {
    label: Palette["primary"];
  }

  interface PaletteOptions {
    label?: PaletteOptions["primary"];
  }
}

let base = createTheme({
  palette: {
    primary: {
      main: indigo[700],
      dark: indigo[900],
      light: indigo[100],
    },
    secondary: {
      main: blue[500],
      dark: blue[900],
      light: blue[300],
    },
    background: {
      default: grey[50],
    },
    text: {
      primary: indigo[900],
      secondary: grey[700],
      disabled: grey[300],
    },
  },
});

base = createTheme(base, {
  // Custom colors with augmentColor go here
  palette: {
    label: base.palette.augmentColor({
      color: {
        main: "#13294B",
      },
      name: "label",
    }),
  },
});

export const theme = createTheme({
  palette: base.palette,
  typography: {
    fontFamily: "Gene-Sans-Regular",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontFace: "Gene-Sans-Regular",
        },
        "::-webkit-scrollbar": {
          WebkitAppearance: "none",
          width: "6px",
          margin: "8 0",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "5px",
          backgroundColor: grey[300],
          WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, .5)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          color: base.palette.text.primary,
          "& fieldset": {
            borderColor: grey[300],
            borderRadius: "8px !important",
          },
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          padding: "0px 14px 0px 8px !important",
          height: "40px",
          boxSizing: "border-box",
          backgroundColor: grey[200],
          borderRadius: "8px",
          borderBottom: "none",
          ":hover": {
            outline: `1px solid ${indigo[500]}`,
          },
          "& fieldset": {
            borderColor: grey[200],
            borderRadius: "8px !important",
          },
          "& .MuiSelect-select": {
            padding: "8.5px 16px",
          },
          "& .MuiInputAdornment-positionStart": {
            marginTop: "0px !important",
          },
          "& input.MuiInputBase-input": {
            paddingTop: "4px",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        contained: {
          borderRadius: "4px",
          boxSizing: "border-box",
          backgroundColor: indigo[50],
          color: base.palette.grey[300],
          border: "none",
          fontSize: "16px",
          textAlign: "left",
          boxShadow: "none",
          justifyContent: "flex-start",
          textTransform: "capitalize",
          "& .MuiButton-startIcon": {
            color: base.palette.grey[600],
            paddingLeft: "4px",
          },
          "&.Mui-disabled": {
            color: base.palette.grey[400],
          },
          ":hover": {
            boxShadow: "none",
            backgroundColor: "#E1E7F7", //This is the same as indigo[100], but it didn't like that one shade for some reason
          },
        },
        outlined: {
          borderRadius: "4px",
          boxSizing: "border-box",
          color: base.palette.grey[500],
          border: `1px solid ${base.palette.grey[300]}`,
          fontSize: "16px",
          textAlign: "left",
          boxShadow: "none",
          justifyContent: "flex-start",
          textTransform: "capitalize",
          "& .MuiButton-startIcon": {
            color: base.palette.grey[700],
            paddingLeft: "4px",
          },
          "&.Mui-disabled": {
            color: base.palette.grey[300],
          },
          ":hover": {
            border: "1px solid black",
            boxShadow: "none",
            backgroundColor: indigo[100],
          },
        },
        text: {
          borderRadius: "4px",
          boxSizing: "border-box",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          color: base.palette.text.secondary,
          fontSize: "16px",
          textAlign: "left",
          boxShadow: "none",
          textTransform: "none",
          "& .MuiButton-startIcon": {
            color: base.palette.grey[700],
            paddingLeft: "4px",
          },
          "&.Mui-disabled": {
            color: base.palette.grey[500],
          },
          ":hover": {
            backgroundColor: indigo[100],
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          backgroundColor: base.palette.grey[200],
          paddingTop: "3px",
          paddingLeft: "3px",
          height: "40px",
          width: "72px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "7.5px !important",
          border: "none",
          margin: "1px",
          width: "32px",
          height: "32px",
          "&.Mui-disabled": {
            border: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${base.palette.grey[500]}`,
          minHeight: "32px",
          "& .MuiTabs-indicator": {
            backgroundColor: `${base.palette.primary.dark}`,
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minWidth: "64px",
          height: "32px",
          textTransform: "none",
          padding: "10px 12px 8px 12px",
          minHeight: "32px",
          "&.Mui-selected": {
            color: "#000000",
            fontWeight: 600,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: "0px 8px",
            lineHeight: "16px",
            fontWeight: 600,
            fontSize: 10,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: "8px",
        },
      },
    },
  },
});
