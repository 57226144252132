import { useRef, useState } from "react";
import { Paper, PaperProps, Typography as T } from "@mui/material";
import { ChartCard } from "../../components";
import { KeywordsChart } from "../../components/charts";
import { KeywordChartMode } from "../../components/charts/KeywordsChart/KeywordsChart";
import { ChartMenu, FullScreen, DownloadButton } from "../../components/common";
import StyledButton from "../../components/common/StyledButton";
import { downloadAsPng } from "../../utils/element-export";

const KeywordCloudTooltipContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    <T fontWeight={900}>Keywords</T>
    This widget surfaces the top relevant keywords by document count. Keywords are significant words or phrases
    extracted directly from the unstructured text response. The reverse side of this widget displays the same keywords,
    but represented in a word cloud.
    <br />
    <br />
    <T fontWeight={900}>What does selecting a keyword do?</T> When a keyword is selected, the underlying dataset is
    narrowed to all text records that contain that keyword in the unstructured text. If you select multiple keywords,
    records that contain all of the selected words will surface.
  </Paper>
);

export default function KeywordsCloudCard(props: PaperProps) {
  const [mode, setMode] = useState<KeywordChartMode>("chips");

  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "keywords");
    }
  };

  return (
    <ChartCard
      {...props}
      title={`Keywords`}
      tooltipContent={<KeywordCloudTooltipContent />}
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      actions={
        <>
          <StyledButton
            onClick={() => {
              setMode((_mode) => (_mode == "cloud" ? "chips" : "cloud"));
            }}
          >
            Show {mode == "cloud" ? "Top Keywords" : "Cloud"}
          </StyledButton>
          <ChartMenu onDownload={triggerDownload} onFullScreen={() => setExpanded(true)} />
        </>
      }
    >
      <FullScreen
        title="Emerging Topics"
        expanded={expanded}
        handleClose={() => setExpanded(false)}
        p={"1em"}
        actions={<DownloadButton action={triggerDownload} />}
      >
        <KeywordsChart mode={mode} ref={ref} />
      </FullScreen>
    </ChartCard>
  );
}
