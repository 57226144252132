import { Box, Typography as T } from "@mui/material";
import { BarCanvas as Bar, type BarDatum, type BarTooltipProps } from "@nivo/bar";
import { forwardRef } from "react";
import { AutoSizer } from "react-virtualized";
import { DocumentUsage } from "../../models/datasource";
import ChartTooltip from "./ChartTooltip";

type DocumentUsageChartProps = {
  data: DocumentUsage[];
  isFullScreen: boolean;
};

const WINDOW_ITEMS = 10;
const BAR_HEIGHT = 17;
const BAR_GAP = 10;
const ITEM_HEIGHT = BAR_HEIGHT + BAR_GAP;

const CHART_MARGIN_LEFT = 300;
const CHART_MARGIN_RIGHT = 24;
const BAR_COLOR = "#8DC8E8";

const DocumentUsageChart = forwardRef<HTMLCanvasElement, DocumentUsageChartProps>(
  ({ data: rawData, isFullScreen }, ref) => {
    const sortedData = [...rawData].sort((a, b) => a.record_count - b.record_count);
    const scaleBase =
      sortedData.length > 0 && sortedData[sortedData.length - 1].record_count !== undefined
        ? sortedData[sortedData.length - 1].record_count
        : 0;

    const data = sortedData.map((item) => ({ ...item, transparent: scaleBase - item.record_count }));
    const dataTable = Object.fromEntries(rawData.map(({ name, record_count }) => [name, record_count]));
    const totalDocuments = rawData.reduce((a, c) => (a += c.record_count), 0);

    const barChartHeight = ITEM_HEIGHT * data?.length;

    const tooltip = ({ data: { name, record_count } }: BarTooltipProps<BarDatum>) => (
      <ChartTooltip
        title={name.toString()}
        id={""}
        value={record_count as number}
        color={BAR_COLOR}
        total={totalDocuments}
        toolTipWidth={180}
        defaultPos={{ top: 10 }}
        portal
      />
    );

    return (
      <Box
        sx={{
          ...(isFullScreen
            ? {}
            : {
                height: ITEM_HEIGHT * WINDOW_ITEMS,
                overflowY: "auto",
                overflowX: "hidden",
              }),
        }}
      >
        {data.length > 0 ? (
          <AutoSizer disableHeight>
            {({ width }) => (
              <Bar
                ref={ref}
                width={width}
                height={barChartHeight}
                margin={{ left: CHART_MARGIN_LEFT, right: CHART_MARGIN_RIGHT }}
                data={data}
                layout="horizontal"
                colors={(item) => (item.id === "transparent" ? "transparent" : BAR_COLOR)}
                indexBy="name"
                padding={BAR_GAP / ITEM_HEIGHT}
                enableLabel={false}
                keys={["record_count", "transparent"]}
                enableGridX={false}
                enableGridY={false}
                axisLeft={{
                  tickPadding: 16,
                  format: (name) =>
                    `${name.toString().length > 30 ? name.toString().slice(0, 30).trimEnd() + "…" : name}    ${
                      dataTable[name]
                    }`,
                }}
                tooltip={tooltip}
                // Nivo rounding accumulates causing unexpected top/bottom margins
                indexScale={{ type: "band", round: false }}
                // With canvas, a very tiny tick still appears even with tickSize: 0.
                // fixed with:
                theme={{ axis: { ticks: { line: { strokeWidth: 0 } } }, text: { fill: "#615E83" } }}
              />
            )}
          </AutoSizer>
        ) : (
          <T
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "2em",
              opacity: "0.4",
            }}
          >
            No records to show.
          </T>
        )}
      </Box>
    );
  },
);

export default DocumentUsageChart;
