import { Box, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";
import { BoxTypeMap } from "@mui/system";

export type TQuestionCard = {
  text: string;
  Icon: OverridableComponent<BoxTypeMap>;
  onClick: (text: string) => void;
};

const QuestionCard = ({ text, Icon, onClick }: TQuestionCard) => {
  return (
    <Box
      onClick={() => {
        onClick(text);
      }}
      display="grid"
      flex="1"
      height="14rem"
      padding="1.25rem"
      borderRadius="0.75rem"
      bgcolor="#eff0f0"
    >
      <Typography
        sx={{ "&:hover": { textDecorationLine: "underline", cursor: "pointer" } }}
        gridArea="1/1"
        fontSize="14px"
        color="#545859"
      >
        {text}
      </Typography>
      <Icon gridArea="1/1" sx={{ placeSelf: "end" }} />
    </Box>
  );
};

export default React.memo(QuestionCard);
