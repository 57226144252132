import { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { ChartKey, LIST_WIDTH, ListEvent } from "./common";
import ListItem from "./ListItem";

export default function ChartList({
  list,
  selected,
  onClick,
  title,
}: {
  list: ChartKey[];
  selected: Set<ChartKey>;
  onClick: (e: Set<ChartKey>) => void;
  title: string;
}) {
  const [scrollWidth, setScrollWidth] = useState(0);
  const scrollRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setScrollWidth((scrollRef.current?.offsetWidth ?? 0) - (scrollRef.current?.clientWidth ?? 0));
  });

  const handleClick = ({ merge, item }: ListEvent) => {
    if (!merge) {
      onClick(new Set([item]));
    } else {
      const newSet = new Set(selected);
      if (selected.has(item)) {
        newSet.delete(item);
      } else {
        newSet.add(item);
      }
      onClick(newSet);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography fontWeight="bold" color="rgba(0,0,0,0.8)">
        {title}
      </Typography>
      <Box display="grid" width={LIST_WIDTH} overflow="hidden" height="100%">
        <Box gridArea="1 / 1" minHeight="100%" border="1px solid #d9d9d9" width={LIST_WIDTH} borderRadius="4px"></Box>
        <Box
          ref={scrollRef}
          gridArea="1 / 1"
          overflow="auto"
          paddingTop="2px"
          paddingRight={scrollWidth}
          width={LIST_WIDTH + scrollWidth}
          boxSizing="content-box"
        >
          {list.map((item) => (
            <ListItem selected={selected.has(item)} onClick={handleClick} key={item} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
