import dayjs from "dayjs";
import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { getPortfolioSummary } from "../../api/uslt";
import useUserContext from "../../components/Authorization/useUserContext";
import useUsltData from "../../contexts/useUsltData";
import { TPortfolioSummary } from "../../components/Deepchat/models";

const SelectedQuarterAtom = atom<string>("");
const PortfolioSummaryAtom = atom<TPortfolioSummary>({ summary: "", action_id: "" });

export const useRetroStore = () => {
  const user = useUserContext();
  const USLT = useUsltData();

  // Quarter Summary Data
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [quarter, setQuarter] = useAtom(SelectedQuarterAtom);
  const [summary, setSummary] = useAtom(PortfolioSummaryAtom);

  const getSummary = async (quarter: string) => {
    let summaryText: TPortfolioSummary;

    setSummaryLoading(true);
    try {
      summaryText = await getPortfolioSummary(user, quarter);
      setSummary(summaryText);
    } finally {
      setSummaryLoading(false);
    }

    return summaryText;
  };

  const clearSummary = () => {
    setSummary({ summary: "", action_id: "" });
  };

  // Selected Quarter Data
  const [quarterOptions, setQuarterOptions] = useState<QuarterOption[]>([]);

  useEffect(() => {
    setQuarterOptions(generateDateFilters(USLT.PortfolioQuarters.data ?? []));
  }, [USLT.PortfolioQuarters.data]);

  return {
    loading: summaryLoading,
    summary,
    getSummary,
    clearSummary,
    quarterOptionsLoading: USLT.PortfolioQuarters.isLoading,
    quarterOptions,
    quarter,
    setQuarter,
  };
};

export interface QuarterOption {
  label: string;
  start: Date;
  end: Date;
}

const generateDateFilters = (quarterLabels: string[]) => {
  const options = quarterLabels.map((_label) => {
    const [year, quarterString] = _label.split(" ");

    const quarter = dayjs(`${year}-${1 + 3 * (Number(quarterString[1]) - 1)}-01`);

    return {
      label: _label,
      start: quarter.startOf("quarter").toDate(),
      end: quarter.endOf("quarter").toDate(),
    } as QuarterOption;
  });

  return options;
};
