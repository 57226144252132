import * as d3 from "d3";
import * as topojson from "topojson-client";
import { Topology } from "topojson-specification";
import { FeatureCollection, Feature, Geometry, GeoJsonProperties } from "geojson";
import { compactFormatter } from "../../../utils/numberFormatter";
import Legend from "./Legend";

export const parseTopologyFeatures = (
  topology: Topology,
  featureName: string,
): FeatureCollection<Geometry, GeoJsonProperties> => {
  return topojson.feature(topology, topology.objects[featureName]) as FeatureCollection;
};

export interface Datum {
  id: string;
  rate: number;
  selected?: boolean;
}

export type MapMouseEvent = <T extends Datum>(
  elm: d3.BaseType | SVGPathElement,
  event: MouseEvent,
  feature: Feature<Geometry, GeoJsonProperties>,
  data: T,
) => void;

export interface ChoroplethParams<T extends Datum> {
  featureCollection: FeatureCollection<Geometry, GeoJsonProperties>;
  // topology: Topology;
  // featureName: string;
  data: T[];
  width?: number | null;
  onClickGeo?: MapMouseEvent;
  onMouseEnterGeo?: MapMouseEvent;
  onMouseExitGeo?: MapMouseEvent;
}

export default function Choropleth<T extends Datum>({
  featureCollection,
  data,
  width,
  onClickGeo,
  onMouseEnterGeo,
  onMouseExitGeo,
}: ChoroplethParams<T>) {
  const MAX = Math.max(...data.map((d: Datum) => d.rate));

  const color = d3.scaleLinear([0, MAX], ["rgb(141, 200, 232)", "rgb(11, 65, 205)"]);

  const dataMap = new Map(data.map((d) => [d.id, d]));

  const ID = (d: Feature<Geometry, GeoJsonProperties>) => d?.properties?.description || "";

  const svgSize: [number, number] = [1280, 900];
  // Create SVG container for map
  const svg = d3
    .create("svg")
    .attr("viewBox", [0, 0, ...svgSize])
    .attr("style", "max-width: 100%; height: auto;");

  // Create the Legend for the map
  const LEGEND_WIDTH = 480;
  const fullScreenScale = svgSize[0] / LEGEND_WIDTH;
  svg
    .append("g")
    .attr(
      "transform",
      (width || 0) < 810 ? `translate(0,780)scale(${fullScreenScale})` : `translate(${svgSize[0] - LEGEND_WIDTH},720)`,
    )
    .append(() =>
      // @ts-expect-error Because making a typed parameter for Legend is not worth the headache
      Legend(color, {
        title: "Data Distribution",
        width: LEGEND_WIDTH,
        marginLeft: 10,
        marginRight: 10,
        tickFormat: (tick: number) => compactFormatter.format(Number(tick)),
      }),
    );

  // Map and projection
  const path = d3.geoPath();

  // Make the map look nice and tuck Alaska and Hawaii nicely on the bottom left of the map
  // https://d3js.org/d3-geo/conic#geoAlbersUsa
  const projection = d3.geoAlbersUsa();

  // This dynamically resizes the inbound feature geometry to match the width of our SVG ViewBox
  projection.fitWidth(svgSize[0], featureCollection);

  const map = svg.append("g");
  map
    .selectAll("path")
    .data(featureCollection.features)
    .join("path")
    .attr("fill", (d) => {
      const datum = dataMap.get(ID(d));
      return datum ? color(datum?.rate || 0) : "#dddddd";
    })
    .attr("class", (d) => {
      return ["ecosystem", ...(dataMap.get(ID(d))?.selected ? ["selected"] : [])].join(" ");
    })
    .on("click", function (event: MouseEvent, feature: Feature<Geometry, GeoJsonProperties>) {
      onMouseExitGeo && onMouseExitGeo(this, event, feature, dataMap.get(ID(feature)) as T);
      onClickGeo && onClickGeo(this, event, feature, dataMap.get(ID(feature)) as T);
    })
    .on("mouseenter", function (event: MouseEvent, feature: Feature<Geometry, GeoJsonProperties>) {
      onMouseEnterGeo && onMouseEnterGeo(this, event, feature, dataMap.get(ID(feature)) as T);
    })
    .on("mouseleave", function (event: MouseEvent, feature: Feature<Geometry, GeoJsonProperties>) {
      onMouseExitGeo && onMouseExitGeo(this, event, feature, dataMap.get(ID(feature)) as T);
    })
    .on("mousewheel", function (event: MouseEvent, feature: Feature<Geometry, GeoJsonProperties>) {
      onMouseExitGeo && onMouseExitGeo(this, event, feature, dataMap.get(ID(feature)) as T);
    })
    .attr("d", path.projection(projection));

  // Return the assembled map
  return svg.node();
}
