import { ParsedJwt } from "../../models/auth";

export const parseJWT = (token: string | null): ParsedJwt | null => {
  if (!token) return null;
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const isTokenExpired = (token: string | null): boolean => {
  const decodedJWT = parseJWT(token);
  return !decodedJWT || decodedJWT.exp * 1000 < Date.now();
};
