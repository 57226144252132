import { Box, List, Tooltip } from "@mui/material";
import { Serie } from "@nivo/line";
import CircleIcon from "@mui/icons-material/Circle";

import { StyledChip } from "../Common";

export interface ChartLegendProps {
  selectedId?: string;
  data: Serie[];
  handleChipClick?: (topic: string) => void;
  isFullScreen: boolean;
}

const ChartLegend = ({ selectedId, data, handleChipClick, isFullScreen }: ChartLegendProps) => {
  return (
    <List
      component={Box}
      dense
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        listStyle: "none",
        ...(isFullScreen
          ? {}
          : {
              margin: 0,
              padding: 2,
              paddingBottom: 4,
              backgroundColor: "background.paper",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderTop: "None",
              borderBottomLeftRadius: "8px",
            }),
      }}
    >
      {data.map(({ id, label, color }) => {
        const isSelected = selectedId == String(id);

        return (
          <Tooltip key={id} title={label || id}>
            <StyledChip
              variant={isSelected ? "filled" : "outlined"}
              size="small"
              label={`${label || id}`}
              onClick={() => handleChipClick && handleChipClick(String(id))}
              icon={<CircleIcon style={{ fontSize: "8px", color: color, marginRight: "0px" }} />}
              sx={{ marginTop: "8px", maxWidth: 300 }}
            />
          </Tooltip>
        );
      })}
    </List>
  );
};

export default ChartLegend;
